import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changedPhoneNumber'
})
export class ChangedPhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }

    const firstPart = value.slice(0, 3);
    const secondPart = value.slice(3, 6);
    const thirdPart = value.slice(6, 10);

    return `(${firstPart}) ${secondPart}-${thirdPart}`;
  }

}
