<nav class="layout-breadcrumb" style="margin-top: 1rem">
    <ol>
        <li>
            <a [routerLink]="['/']">
                <i class="pi pi-home"></i>
            </a>
        </li>
        <li class="layout-breadcrumb-chevron"> / </li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li><a [routerLink]="item.url">{{item.label}}</a></li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron"> / </li>
        </ng-template>
    </ol>
</nav>
