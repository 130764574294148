import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WebStorage } from 'src/app/demo/components/auth/storage/web.storage';
import { AddressDTO } from 'src/app/demo/model/AddressDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { SchoolDTO } from 'src/app/demo/model/SchoolDTO';
import { TransactionDTO } from 'src/app/demo/model/TransactionDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';
import { Common } from 'src/app/demo/utils/common';

@Component({
    selector: 'app-view-transaction-recipent',
    templateUrl: './view-transaction-recipent.component.html',
    styleUrls: ['./view-transaction-recipent.component.scss']
})
export class ViewTransactionRecipentComponent implements OnInit {
    invoiceData: DynamicDialogConfig<any>;
    transaction!: TransactionDTO;
    schoolDetails !: SchoolDTO;
    addressDetails !: AddressDTO;

    constructor(
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private lookupService: LookupService,
        private operationService: OperationService,
        private storage: WebStorage,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe
    ) {
        this.invoiceData = this.config;
        
        if (this.invoiceData.data.viewTransaction) {
            this.transaction = this.invoiceData.data.transaction;
            this.schoolDetails = this.invoiceData.data.schoolDetails;
            this.addressDetails = this.invoiceData.data.address;

        }
    }

    ngOnInit(): void {  }


    printReceipt() {
        // const selectors = ['.no-print', '.p-dialog-title', '.p-dialog-header-icons'];

        // selectors.forEach(selector => {
        //     const elements = document.querySelectorAll(selector);
        //     elements.forEach(element => {
        //         element.classList.add('hidden');
        //     });
        // });

        // window.print();

        // this.restoreRemovedElements();
        
        const documentFormat = 'pdf';
        const reportName = 'Payment';
        const searchQuote: any = {};
        searchQuote['format'] = documentFormat;
        searchQuote['name'] = reportName;
        searchQuote['payment_id'] = this.transaction.id;
        searchQuote['school_id'] = this.schoolDetails.id;
        Common.removeEmptyFields(searchQuote);
        this.lookupService
            .queryPaymentReports(searchQuote)
            .subscribe((res: HttpResponse<any>) => {
                // this.messageService.add({
                //     severity: 'success', life: 10000,
                //     summary: 'Success',
                //     detail: 'Invoice generated successfully.',
                // });
                // this.downloadFile(res, reportName, documentFormat);
                const response = res.body ? res.body : '';
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            (error) => {
                this.messageService.add({
                    severity: 'error', life: 10000,
                    summary: error.statusText,
                    detail: error.message
                });
            }
        );
    }

    restoreRemovedElements() {
        const selectors = ['.no-print', '.p-dialog-title', '.p-dialog-header-icons'];

        selectors.forEach(selector => {
            const elements = document.querySelectorAll(selector);
            elements.forEach(element => {
                element.classList.remove('hidden');
            });
        });
    }
}
