<div class="container-fluid" *ngIf="invoiceDetails && invoiceDetails.length > 0;else elseBlock">
  <div class="row">
    <!-- First Section -->
    <div class="col-md-12" #printContent>
      <div class="col-custom px-6 pt-5" style="flex-basis: 65%;">
        <div class="row ml-0 mb-1">
          <img alt="app logo" src="assets/layout/images/wise_owl_logoo.png" class="shadow-4 border-circle flex-shrink-0"
            style="width: 11% !important;">
          <div class="mx-3"></div>
          <span class="app-name mt-2 text-lg font-semibold">{{this.schoolDetails!.name}}<br><span
              class="font-normal text-base">{{this.schoolDetails!.description}}</span> <br><span
              class="font-normal text-base">{{this.addressDetails!.line}},{{this.addressDetails!.city}},{{this.addressDetails!.state}},{{this.addressDetails!.country}},{{this.addressDetails!.zipcode}}</span></span>
        </div>
        <div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between my-3 gap-3">
          <div class="flex align-items-center">
            <div class="ml-1 mr-2 ">
              <span class="text-lg font-medium block">Billed for :</span>
            </div>
            <img *ngIf="invoiceData.data.invoiceObj.profileimg !=undefined;else noImageFound"
              [src]="invoiceData.data.invoiceObj.profileimg" width="45" height="45" alt="profile"
              class="shadow-4 border-circle flex-shrink-0" />
            <ng-template #noImageFound>
              <a><img src="assets/layout/images/male.png" alt="Fallbackimage" width="45" height="45"
                  class="shadow-4 border-circle flex-shrink-0" />
              </a>
            </ng-template>
            <div class="ml-3">
              <span class="text-m font-light"><span class="font-normal">
                  <!-- <b>{{invoiceData.data.invoiceObj.fullName}}</b></span> -->
                  <b>{{studentDetails?.fullName}}</b></span>
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-2 existing">
          <!-- Header Section -->
          <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 27px">
            <div class="pl-2 text-700">
              Invoice Number :
              <span class="text-lg font-medium">
                {{ invoiceForm.invoiceNumber ? invoiceForm.invoiceNumber : '' }}
              </span>
            </div>
          </div>
          <br>
          <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 27px">
            <div class="pl-2 text-700">
              Due Date :
              <span class="text-lg font-medium">
                {{ invoiceForm.dueDate ? (invoiceForm.dueDate | momentDate) : '' }}
              </span>
            </div>
          </div>
          <br>
          <div class="col-12 flex p-2 surface-100 " style="max-height: 27px">
            <div class="pl-2 text-700" *ngIf=" invoiceForm.startDate != null">
              Invoice Period :
              <span class="text-lg font-medium">
                {{ invoiceForm.startDate ? (invoiceForm.startDate | momentDate) : '' }} to
                {{ invoiceForm.endDate ? (invoiceForm.endDate | momentDate) : '' }}

              </span>
            </div>
          </div>

          <div class="col-12 flex p-2 pt-1 surface-100" style="max-height: 48px">
            <div class="p-2 col-8 text-center md:text-left">
              <span class="text-700 block">Description</span>
            </div>
            <p-divider align="center" layout="vertical" styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
            <div class="p-2 pr-6 col-4 text-center md:text-right">
              <span class="text-700 block">Amount</span>
            </div>
          </div>
          <div class="col-12 flex justify-content-between border-bottom-1 surface-border py-0 px-2"></div>
          <!-- Data Rows -->
          <div *ngFor="let item of invoiceDetails" class="col-12 flex p-2 surface-100" style="max-height: 48px">
            <div class="p-2 col-8 text-center md:text-left">
              <span class="text-lg font-medium block">{{ item.description }}</span>
            </div>
            <p-divider align="center" layout="vertical" styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
            <div class="p-2  pr-6 col-4 text-center md:text-right">
              <span class="text-lg block">$ {{item.type == 'discount' || item.type == 'subsidy' ? '-' : ''}}{{
                item.amount }}</span>
            </div>
          </div>
          <!-- Add border after each row -->
          <div class="col-12 flex justify-content-between border-bottom-1 surface-border py-0 px-2"></div>
          <div class="col-12 flex p-2 surface-100 border-round-bottom"
            style="max-height: 48px; padding-right: 14px !important;">
            <div class="p-2 col-10 text-center md:text-right">
              <span class="text-xl">Total Amount</span>
            </div>
            <div class="p-2  col-2 text-center md:text-right">
              <span class="text-xl font-medium">$ {{invoiceForm.invoiceAmount }}</span>
            </div>
          </div>

          <div class="col pl-0 pb-0 no-print">
            <div class="d-flex justify-content-start">
              <p-button label="Print" (click)="printInvoice()"
                styleClass="p-button-secondary p-button-raised"></p-button>
            </div>
          </div>
          <div class="col pr-0 pb-0 no-print" *ngIf="this.invoiceData.data.isAccountList;else elseButton">
            <div class="d-flex justify-content-end">
              <p-button label="Cancel" (onClick)="ref.close()"
                styleClass="p-button-secondary p-button-raised"></p-button>
              <div class="mx-2"></div>
              <button pButton label="Send Invoice" [loading]="submitForm" (click)="sendInvoice()"
                class="p-button-success p-button-raised"></button>
            </div>
          </div>
          <ng-template #elseButton>
            <div class="col pr-0 pb-0 no-print">
              <div class="d-flex justify-content-end">
                <p-button label="Cancel" (onClick)="ref.close()"
                  styleClass="p-button-secondary p-button-raised"></p-button>
                <div class="mx-2"></div>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

  </div>
</div>
<ng-template #elseBlock>
  <div class="container-fluid">
    <div class="card-form" style="display: flex; align-items: center; justify-content: center;">
      <p-progressSpinner [style]="{width: '45px', height: '37px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".9s"></p-progressSpinner>
    </div>
  </div>
</ng-template>