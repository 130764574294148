import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FamilyDTO } from 'src/app/demo/model/FamilyDTO';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { TransactionDTO } from 'src/app/demo/model/TransactionDTO';
import { LocalOperationService } from 'src/app/demo/service/localOperation.service';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';

@Component({
    selector: 'app-add-credit',
    templateUrl: './add-credit.component.html',
    styleUrls: ['./add-credit.component.scss'],
})
export class AddCreditComponent implements OnInit {
    invoiceData: DynamicDialogConfig<any>;
    creditForm!: FormGroup;
    submitForm: boolean = false;
    creditObj!: TransactionDTO;
    selectedStudentList: StudentDTO[] = [];
    multiFamily : FamilyDTO[] = [];
    multiFamilyDataList : any;
    multifamilyChilds! : any;
    // selectedFamily!: FamilyDTO;
    selectedFamily: any = {};
    readOnlyFamily: boolean = false;
    private familySubscription!: Subscription;


    constructor(
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private lookupService: LookupService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private sanitizer: DomSanitizer,
        private operationService: OperationService,
        private datePipe: DatePipe,
        private localOperationService : LocalOperationService
    ) {
        this.invoiceData = this.config;
        if(!this.invoiceData.data.addCredit){
            this.creditObj = this.invoiceData.data.obj;
        
        }
        this.multiFamily = this.invoiceData.data.multiFamilyList;
        this.selectedStudentList = this.invoiceData.data.studentList;
      
    }

    ngOnInit(): void {
        this.creditForm = this.formBuilder.group({
            id: undefined,
            transactionType: ['Credit'],
            date: this.convertToTimeZone(
                this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00Z'
            ),
            endDate: undefined,
            amount: [undefined, [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
            receivedAmount: undefined,
            balance: undefined,
            familyId : undefined,
            processStatus: ['Success'],
            description: [undefined, [Validators.required]],
            paymentMode: [undefined],
            note: undefined,
            isLedgerTransaction: [false],
            isSendEmail: [false],
            refTable: ['Invoice'],
            refTableId: undefined,
            students: undefined,
            schoolId: this.invoiceData.data.studentList[0].schoolId,
            status: ['A'],
            selectedFamily : undefined,
        });

        if(!this.invoiceData.data.addCredit){
            this.creditForm.patchValue({
                id: this.creditObj.id,
                transactionType: this.creditObj.transactionType,
                date: this.convertToTimeZone(
                    this.datePipe.transform(new Date(), 'yyyy-MM-dd') +
                        'T00:00:00Z'
                ),
                endDate: this.creditObj.endDate,
                amount: this.creditObj.amount,
                receivedAmount: this.creditObj.recievedAmount,
                balance: this.creditObj.balance,
                processStatus: this.creditObj.processStatus,
                description: this.creditObj.description,
                paymentMode: this.creditObj.paymentMode,
                note: this.creditObj.note,
                isLedgerTransaction: this.creditObj.isLedgerTransaction,
                isSendEmail: this.creditObj.isSendEmail,
                refTable: this.creditObj.refTable,
                refTableId: this.creditObj.refTableId,
                students: undefined,
                schoolId: this.creditObj.schoolId,
                status: this.creditObj.status,
            }); 
        }
        this.multiFamilyData();
    }

    onSubmit() {
        const isUpdate = this.creditForm.value.id != undefined;
        const serviceMethod = isUpdate
            ? 'updateTransaction'
            : 'createTransaction';
        if (this.creditForm.valid) {
            this.submitForm = true;
            let obj: TransactionDTO = this.creditForm.value;
            let tempStdArray = this.selectedStudentList?.map(each => each?.id);
            let studentIds: any[] = [];
            tempStdArray?.map((res) => {
                studentIds.push({ 'id': res });
            });
            const temp: StudentDTO[] = [];
            // obj.students = [{ id: this.invoiceData.data?.studentList[0].id }]; 
            obj.students = studentIds ? studentIds : temp; 
            obj.familyId = this.creditForm.get('selectedFamily')?.value;
            
            this.operationService[serviceMethod](obj).subscribe(
                (res: HttpResponse<TransactionDTO>) => {
                    this.submitForm = false;
                    const message = {
                        severity: 'success', 
                        life: 10000,
                        summary: 'Success',
                        detail: `Amount ${
                            isUpdate ? 'Updated' : 'Credited'
                        } successfully.`,
                    };
                    this.ref.close(message);
                },
                (error) => {
                    this.submitForm = false;
                    this.messageService.add({
                        severity: 'error', 
                        life: 10000,
                        summary: error.error.title,
                        detail: error.error.detail,
                    });
                }
            );
        }
    }

    cancelInv(){
        this.ref.close();
        this.readOnlyFamily = false;
    }

    convertToTimeZone(originalDateString: string): string {
        const currentDate = new Date();
        const currentHours = currentDate.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDate
            .getMinutes()
            .toString()
            .padStart(2, '0');
        const currentSeconds = currentDate
            .getSeconds()
            .toString()
            .padStart(2, '0');

        return (
            this.datePipe.transform(originalDateString, 'yyyy-MM-dd') +
            `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
        );
    }


    
    multiFamilyData() {
        this.multiFamilyDataList = this.multiFamily;
        if (this.multiFamilyDataList.length > 0) {
          this.selectedFamily = this.multiFamilyDataList[0];
          this.creditForm.patchValue({ selectedFamily: this.selectedFamily.id });
          this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': this.selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
            this.selectedStudentList = res.body ? res.body : [];
          });
        }
        this.readOnlyFamily = false;
      }

    
      onFamilyChange(event: any) {
        const selectedFamilyId = event.value;
        const selectedFamily = this.multiFamilyDataList?.find((each : any) => each.id === selectedFamilyId);
        this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
          this.multifamilyChilds = res.body ? res.body : [];
          this.selectedStudentList = this.multifamilyChilds;
        });
      }

      ngAfterContentInit(): void { 

        this.familySubscription = this.localOperationService.multiFamilyObj.subscribe((family) => {
            
            // this.multiFamilyDataList = this.multiFamily; 
            if (family != null && Object.keys(family).length > 0) {
                // this.multiFamilyDataList = family;
                this.creditForm.patchValue({ selectedFamily: family?.id });
                this.selectedFamily = family.id;
                this.readOnlyFamily = true;
                this.getSelectedFamilyChild(family);
                
                
            } else {
                
                    this.selectedFamily = this.multiFamilyDataList[0];
                    this.creditForm.patchValue({ selectedFamily: this.selectedFamily?.id });
                    this.getSelectedFamilyChild(this.selectedFamily);
                    this.readOnlyFamily = false;
            
        }
          });
        
      }

      ngOnDestroy(): void {
        if (this.familySubscription) {
            this.localOperationService.multifamilyobj({});
            this.familySubscription.unsubscribe();
          }
      }



     
getSelectedFamilyChild(family: any) {
    if (family?.childIds && family?.childIds.length > 0) {
      this.lookupService.queryStudent({
        size: 30,
        sort: 'fullName,asc',
        page: 0,
        'id.in': family?.childIds
      }).subscribe((res: HttpResponse<StudentDTO[]>) => {
        this.multifamilyChilds = res.body ? res.body : [];
        this.selectedStudentList = this.multifamilyChilds;
      });
    }
}

    
}
