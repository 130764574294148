<p-toast key="editMessage"></p-toast>
<div class="md:mt-2">
    <div class="container" *ngIf="this.invoiceData.data.studentList.length > 0">
        <div class="row p-0">
            <div class="flex mt-1 block" *ngFor="let studentDetails of this.invoiceData.data.studentList">
                <img
                    *ngIf="studentDetails.profileimg != undefined; else noImageFound"
                    [src]="studentDetails.profileimg"
                    width="43"
                    height="43"
                    alt="profile"
                    class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                />
                <ng-template #noImageFound>
                    <a>
                        <img
                            src="assets/layout/images/male.png"
                            alt="Fallbackimage"
                            width="43"
                            height="43"
                            class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                        />
                    </a>
                </ng-template>
                <div class="ml-2 mt-3">
                    <div class="text-base font-bold text-900 mb-0">
                        <a
                            class="student-name"
                        >{{ studentDetails.fullName }}</a
                        >
                    </div>
                </div>
            </div>
        </div>            
    </div>
    
    <div class="col-12">
        <form [formGroup]="invoiceForm">
            <div class="row">
                <div class="col-4">
                    <div class="field col-md-12 col-sm-12 pr-md-2">
                        <label htmlFor="dueDate" [appAsterisk]="true">Invoice Due Date</label>
                        <input
                            type="date"
                            class="form-control"
                            formControlName="dueDate"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <div class="field col-md-12 col-sm-12 pr-md-2">
                        <label for="startDate" [appAsterisk]="optionalDateControl">Date of Service (Optional)</label>
                        <div class="d-flex">
                            <input
                                type="date"
                                class="form-control"
                                formControlName="startDate"/>
                            <span class="mx-4">to</span>
                            <input
                                type="date"
                                class="form-control"
                                formControlName="endDate"/>
                        </div>
                    </div>
                </div>
            </div>
            <li
                class="flex justify-content-between border-top-2 surface-border py-3"
            ></li>
            <form [formGroup]="invoiceItemForm">
                <div formArrayName="dynamicArray">
                    <div
                        class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                        <!-- Header Row -->
                        <div
                            class="col-12 flex p-2 surface-100 border-round-top"
                            style="max-height: 48px">
                            <div class="p-2 col-2 text-center md:text-left">
                                <span class="text-700 block">Type</span>
                            </div>
                            <p-divider
                                align="center"
                                layout="vertical"
                                styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                            <div class="p-2 col-6 text-center md:text-left">
                                <span class="text-700 block" [appAsterisk]="true">Item Description</span>
                            </div>
                            <p-divider
                                align="center"
                                layout="vertical"
                                styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                            <div class="p-2 col-1 text-center md:text-left">
                                <span class="text-700 block" [appAsterisk]="true">Amount</span>
                            </div>
                            <p-divider
                                align="center"
                                layout="vertical"
                                styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                            <div class="p-2 col text-center md:text-center">
                                <span class="text-700 block ml-3">Action</span>
                            </div>
                        </div>

                        <!-- Dynamic Rows -->
                        <div
                            class="col-12"
                            *ngFor="
                                let invoiceItem of dynamicArray.controls;
                                let i = index" [formGroupName]="i">
                            <div
                                class="p-2 my-2 flex flex-row justify-content-between align-items-center">
                                <!-- Type Dropdown -->
                                <div
                                    class="col-2 text-center md:text-left px-2">
                                    <select
                                        class="form-control"
                                        formControlName="type"
                                        (change)="onTypeChange($event, i)">
                                        <option value="newItem">New Item</option>
                                        <option value="presetItem">Preset Item</option>
                                        <option value="discount">Discount</option>
                                        <option value="subsidy">Subsidy</option>
                                    </select>
                                </div>

                                <!-- Item Description Input -->
                                <div class="col-6 text-center md:text-left p-1">
                                    <ng-container
                                        [ngSwitch]="
                                            dynamicArray.controls[i].get('type')?.value">
                                        <input
                                            *ngSwitchCase="'newItem'"
                                            type="text"
                                            class="form-control"
                                            placeholder="Add Invoice Description"
                                            appCapitalizeFirstLetter
                                            formControlName="description"/>

                                        
                                        <div   *ngSwitchCase="'presetItem'">
                                            <p-dropdown class="dropPreset" [options]="presetItemList" formControlName="description" 
                                            appendTo="body" 
                                            placeholder="Add Description"  [showClear]="true"
                                            optionLabel="name"
                                            optionValue="name"
                                            (onChange)="changePresetItem(i,$event)"
                                            (onClear)="changePresetItem(i,$event)"
                                            >
                                                <ng-template pTemplate="item" let-option>
                                                  <div>{{ option.name }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                  <div class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                                                    <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 38px">
                                                      <div class="text-center md:text-left">
                                                        <span class=" text-cyan-300 text-700 block font-semibold hover-pointer" (click)="onItemSetting('Manage Presets')"><i class="pi pi-cog"></i>&nbsp;&nbsp; Manage Presets</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </ng-template>
                                            </p-dropdown>                                                  

                                        </div>

                                        <div
                                            *ngSwitchCase="'discount'"
                                            class="form-row">
                                            <div class="col-2">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="0"
                                                    pKeyFilter="money"
                                                    (input)="onDeductionAmtChange(i,$event)"
                                                    formControlName="deductionAmt"/>
                                            </div>
                                            <div class="col-2">
                                                <select class="form-control" formControlName="currencySign" (change)="onCurrencyChange(i,'discount',$event)">
                                                    <option value="%">%</option>
                                                    <option value="$">$</option>
                                                </select>
                                            </div>
                                            <div
                                                class="col-3"
                                                *ngIf="
                                                    dynamicArray.controls[i].get('currencySign')?.value === '%'">
                                                <div class="row">
                                                    <div class="col-sm-2">
                                                        <p class="of-label">Of</p></div>
                                                        <div class="col-sm-8">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="0"
                                                            pKeyFilter="money"
                                                            formControlName="percentageAmt"
                                                            (input)="onPercentageAmtChange(i,$event)"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div [ngClass]=" dynamicArray.controls[i].get('currencySign')?.value === '%'? 'col-5' : 'col' ">
                                                    <p-dropdown class="dropPreset" [options]="discountItemList"
                                                     placeholder="Add Description" formControlName="description" appendTo="body"  
                                                     [showClear]="true" optionValue="name" optionLabel="name"
                                                     >
                                                        <ng-template pTemplate="item" let-option>
                                                          <div>{{ option.name }}</div>
                                                        </ng-template>
                                                        <ng-template pTemplate="footer">
                                                          <div class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                                                            <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 38px">
                                                              <div class="text-center md:text-left">
                                                                <span class=" text-cyan-300 text-700 block font-semibold hover-pointer" (click)="onItemSetting('Manage Discount')"><i class="pi pi-cog"></i>&nbsp;Manage Discount</span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </ng-template>
                                                    </p-dropdown>                                                  
                                            </div>
                                        </div>

                                        <div
                                        *ngSwitchCase="'subsidy'"
                                        class="form-row">
                                        <div class="col-2">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="0"
                                               pKeyFilter="money"
                                               (input)="onDeductionAmtChange(i,$event)"
                                                formControlName="deductionAmt"/>
                                        </div>
                                        <div class="col-2">
                                            <select class="form-control" formControlName="currencySign" (change)="onCurrencyChange(i,'discount',$event)">
                                                <option value="%">%</option>
                                                <option value="$">$</option>
                                            </select>
                                        </div>
                                        <div
                                            class="col-3"
                                            *ngIf="
                                                dynamicArray.controls[i].get('currencySign')?.value === '%'">
                                            <div class="row">
                                                <div class="col-sm-1">
                                                    <p class="of-label">Of</p></div>
                                                    <div class="col-sm-8">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="0"
                                                       pKeyFilter="money"
                                                        formControlName="percentageAmt" (input)="onPercentageAmtChange(i,$event)"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div [ngClass]=" dynamicArray.controls[i].get('currencySign')?.value === '%'? 'col-5' : 'col' ">
                                            <p-dropdown class="dropPreset" [options]="subsidyItemList" 
                                            placeholder="Add Description" formControlName="description" appendTo="body" 
                                            optionLabel="name" optionValue="name" >
                                               <ng-template pTemplate="item" let-option>
                                                 <div>{{ option.name }}</div>
                                               </ng-template>
                                           </p-dropdown>                                                   
                                        </div>
                                    </div>

                                    </ng-container>
                                </div>

                                <!-- Amount Input -->
                                <div
                                    class="col-2 text-center md:text-left pr-2"
                                >
                                    <ng-container
                                        [ngSwitch]="dynamicArray.controls[i].get('type')?.value">
                                        <input
                                            *ngSwitchCase="'newItem'"
                                            placeholder="0"
                                            class="form-control"
                                           pKeyFilter="money"
                                            formControlName="amount"/>
                                        <input
                                            *ngSwitchCase="'presetItem'"
                                            placeholder="0"
                                            class="form-control"
                                           pKeyFilter="money"
                                            formControlName="amount"/>
                                        <span *ngSwitchCase="'discount'" class="font-medium text-red-600">$ {{(dynamicArray.controls[i].get("amount")?.value != undefined && dynamicArray.controls[i].get("amount")?.value != 0) ? '-' : ''}}
                                            {{dynamicArray.controls[i].get("amount")?.value}}</span>
                                        <span *ngSwitchCase="'subsidy'" class="font-medium text-red-600">$ {{(dynamicArray.controls[i].get("amount")?.value != undefined && dynamicArray.controls[i].get("amount")?.value != 0)? '-' : ''}}
                                            {{dynamicArray.controls[i].get("amount")?.value}}</span>
                                    </ng-container>
                                </div>

                                <div  class="col-1 text-center md:text-left pr-2">
                                    <p-button
                                        icon="pi pi-trash"
                                        styleClass="p-button-rounded p-button-danger p-button-text"
                                        [disabled]="i === 0"
                                        (onClick)="deleteItem($event,invoiceItem, i)"></p-button>
                                </div>
                                <p-confirmPopup key="confirm1"></p-confirmPopup>
                            </div>

                            <li
                                class="flex justify-content-between border-top-1 surface-border py-1"
                            ></li>
                        </div>

                        <div class="col-12 flex">
                            <div class="col-4">
                                <span
                                    class="text-2xl font-semibold text-cyan-300"
                                    style="cursor: pointer"
                                    [ngClass]="{ 'disabled': !invoiceItemForm.valid }"
                                    (click)="addMoreFormGroup()">
                                    Add Invoice Item
                                </span>
                                <br />
                                <p class="text-sm">
                                    Preset Charges, Discounts & Subsidy
                                </p>
                            </div>
                            <div class="col-5"></div>
                           
                            <div class="col-3 text-2xl font-semibold">
                                <span>Total : $ {{getTotalAmount()}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
                <span class="p-float-label mt-6">
                    <textarea
                        formControlName="note"
                        id="note"
                        [rows]="1" 
                        class="full-width-textarea"
                        pInputTextarea></textarea>
                    <label for="float-input">Add optional internal note</label>
                </span>
  

        </form>
    </div>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-end">
                <p-button
                label="Cancel"
                (onClick)="ref.close()"
                styleClass="p-button-secondary p-button-raised"
                ></p-button>
            <div class="mx-2"></div>
                <button pButton
                    label="Save"
                    (click)="onSubmit('save')"
                    [disabled]="!invoiceForm.valid || !invoiceItemForm.valid"
                    [loading]="!submitButton && buttonType == 'save'"
                    class="p-button-success p-button-raised"
                ></button>
                <div class="mx-2"></div>
                <button pButton
                    label="Save and Send"
                    [disabled]="!invoiceForm.valid || !invoiceItemForm.valid"
                    (click)="onSubmit('send')"
                    [loading]="!submitButton && buttonType == 'send'"
                    class="p-button-success p-button-raised"
                ></button>
            </div>
        </div>
   
    </div>
</div>