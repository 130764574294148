<p-toast key="item"></p-toast>
<p-confirmDialog [closable]="true"></p-confirmDialog>
<div *ngIf="settingData.header === 'Manage Presets';else elseBlock">
    <div class="flex align-items-end justify-content-end  my-2">
        <button pButton pRipple type="button" label="Add Preset" class="p-button-raised" (click)="addPreset();onRowEditInit();isAddClicked = true"></button>
    </div>
    <p-table [value]="presetItemList" dataKey="id" editMode="row" [tableStyle]="{'min-width': '40rem'}" >
        <ng-template pTemplate="header">
            <tr>
                <th>Description</th>
                <th >Rate</th>
                <th class="flex align-items-center justify-content-center gap-2">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="product">
                <td *ngIf="!editing && ri==0 && isAddClicked;else block">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <ng-template #block>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{product.name}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-template>
                <td *ngIf="!editing && ri == 0 && isAddClicked;else nextblock">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText pKeyFilter="money" type="text" [ngModel]="product.rate" (ngModelChange)="product.rate = $event" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <input pInputText pKeyFilter="money" type="text" [ngModel]="product.rate" (ngModelChange)="product.rate = $event" required>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <ng-template #nextblock>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText pKeyFilter="money" type="text" [ngModel]="product.rate" (ngModelChange)="product.rate = $event" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                ${{product.rate ?? 0}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-template>
                <td *ngIf="!editing && ri == 0 && isAddClicked;else actionblock">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <button *ngIf="!editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                        <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                    </div>
                </td>
                <ng-template #actionblock>
                    <td >
                        <div class="flex align-items-center justify-content-center gap-2">
                            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit()" class="p-button-rounded p-button-text"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-trash" (click)="onRowEditDelete(product, ri,$event)" class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                    </td>
                </ng-template>
            </tr>
        </ng-template>
    </p-table>
</div>

<ng-template #elseBlock>
    <div class="flex align-items-end justify-content-end  my-2">
        <button pButton pRipple type="button" label="Add Discount" class="p-button-raised" (click)="addDiscount();onRowEditInit();isAddClicked = true"></button>
    </div>
    <p-table [value]="presetItemList" dataKey="id" editMode="row" [tableStyle]="{'min-width': '40rem'}">
        <ng-template pTemplate="header">
            <tr>
                <th>Description</th>
                <th class="flex align-items-center justify-content-center gap-2">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="product">
                <td *ngIf="!editing && ri==0 && isAddClicked;else block">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <ng-template #block>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.name" required appCapitalizeFirstLetter>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{product.name}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-template>



                <td *ngIf="!editing && ri == 0 && isAddClicked;else actionblock">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <button *ngIf="!editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                        <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                    </div>
                </td>
                <ng-template #actionblock>
                    <td >
                        <div class="flex align-items-center justify-content-center gap-2">
                            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit()" class="p-button-rounded p-button-text"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-trash" (click)="onRowEditDelete(product, ri,$event)" class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                    </td>
                </ng-template>
            </tr>
        </ng-template>
    </p-table>
</ng-template>
