import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalOperationService {
  private healthSource = new BehaviorSubject(false);
  healthMessage = this.healthSource.asObservable();

  private schoolSource = new BehaviorSubject(false);
  schoolMessage = this.schoolSource.asObservable();

  private personalSource = new BehaviorSubject(false);
  PersonalMessage = this.personalSource.asObservable();

  private parentSource = new BehaviorSubject(false);
  ParentMassage = this.parentSource.asObservable();

  private staffPersonalSource = new BehaviorSubject(false);
  StaffPersonalMessage = this.staffPersonalSource.asObservable();

  private studentScheduleSource = new BehaviorSubject(false);
  StudentScheduleMessage = this.studentScheduleSource.asObservable();

  private studentProfile = new BehaviorSubject(false);
  studentProfileReload = this.studentProfile.asObservable();

  private siblingSource = new BehaviorSubject(false);
  siblingMessage = this.siblingSource.asObservable();

  private physicalExaminationSource = new BehaviorSubject(false);
  physicalExaminationMessage = this.physicalExaminationSource.asObservable();

  message: any = {};
  private signInMessage = new BehaviorSubject(this.message);
  signInError = this.signInMessage.asObservable();

  private staffProfilePictreSource = new BehaviorSubject(this.message);
  staffProfilePictureMessage = this.staffProfilePictreSource.asObservable();
  
  private isSignIn = new BehaviorSubject(false);
  studentSignedInMessage = this.isSignIn.asObservable();


  private studentSummarySource = new BehaviorSubject(this.message);
  studentSummaryMessage = this.studentSummarySource.asObservable();


  private multiFamily = new BehaviorSubject(this.message);
  multiFamilyObj = this.multiFamily.asObservable();


  private MultiFamilyBalanceSource = new BehaviorSubject(undefined);
  Balance = this.MultiFamilyBalanceSource.asObservable();

  constructor() { }

  healthUpdate(message: boolean) {
    this.healthSource.next(message)
  }

  studentSummaryUpdate(message: boolean) {
    this.studentSummarySource.next(message)
  }

  personalDataUpdate(meessage: boolean) {
    this.personalSource.next(meessage)
  }

  schoolUpdate(massage: boolean) {
    this.schoolSource.next(massage)
  }

  parentUpdate(message: boolean) {
    this.parentSource.next(message)
  }

  staffPersonalDataUpdate(message: boolean) {
    this.staffPersonalSource.next(message)
  }

  studentSchedule(message: boolean) {
    this.studentScheduleSource.next(message)
  }

  siblingData(message: boolean) {
    this.siblingSource.next(message)
  }

  setReaload(message: boolean) {
    this.studentProfile.next(message)
  }

  signInMessageObj(Error: Error | undefined) {
    this.signInMessage.next(Error);
  }

  staffProfilePictureUpdate(message: boolean) {
    this.staffProfilePictreSource.next(message);
  }
  physicalExaminationUpdate(message: boolean) {
    this.physicalExaminationSource.next(message);
  }
  isStudentSignedIn(message: boolean) {
    this.isSignIn.next(message);
  }

  multifamilyobj(obj: any) {
    this.multiFamily.next(obj);
  }

  multiFamilyBalance(res: any) {
    this.MultiFamilyBalanceSource.next(res);
  }

}
