import { Component, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LookupService } from '../demo/service/lookup.service';

interface Breadcrumb {
    label: string;
    url?: string;
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnInit {
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private lookupService: LookupService
    ) { }

    ngOnInit() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.setupBreadcrumbs();
            });

        this.activatedRoute.url.subscribe(() => {
            this.setupBreadcrumbs();
        });
    }

    private setupBreadcrumbs() {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        this._breadcrumbs$.next(breadcrumbs);
    }

    private async addBreadcrumb(
        route: ActivatedRouteSnapshot,
        parentUrl: string[],
        breadcrumbs: Breadcrumb[]
    ) {
        const routeUrl = parentUrl.concat(route.url.map((url) => url.path))
        const breadcrumb = route.data['breadcrumb'];
        const parentBreadcrumb =
            route.parent && route.parent.data
                ? route.parent.data['breadcrumb']
                : null;
        if (breadcrumb && breadcrumb != parentBreadcrumb) {

            // Include module and ID in the label                                                
            if (breadcrumb == 'Family Transaction') {
                const accounting = {
                    label: 'Family Account',
                    url: '/billing/accounting/family'
                }
                breadcrumbs.push(accounting);
                const label = `${(await this.getName(breadcrumb, route.params['id'])) + ' ' + route.data['breadcrumb']}`;
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });

            } else if (breadcrumb == 'Profile view' || breadcrumb == 'Staff Attendance Profile' || breadcrumb == 'Student Activity Profile' || breadcrumb == 'Classrooms' || breadcrumb == 'Shift Profile' || breadcrumb == 'Schedule Profile') {
                const label = `${route.data['breadcrumb']} ${route.params['id'] ? ' / ' + (await this.getName(breadcrumb, route.params['id'])) : ''}`;
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });
            } else if (breadcrumb == 'Staff-Profile') {
                const label = `${route.params['id'] ? (await this.getName(breadcrumb, route.params['id'])) : ''}`
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });
            }
            else if (breadcrumb == 'Draft Letter') {
                const label = `${route.data['breadcrumb']} ${route.params['id'] ? ' / ' + (await this.getName(breadcrumb, route.params['id'])) : ''}`;
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });
            }
            else if (breadcrumb == 'Program') {
                const baseRoute = 'myschool/programs/programProfile';
                const idSegment = route.params['id'] ? `/${route.params['id']}` : '';
                const programDetailsSegment = '/programDetails';

                const label = `${route.data['breadcrumb']} ${route.params['id'] ? ' / ' + (await this.getName(breadcrumb, route.params['id'])) : ''}`;
                const generatedUrl = `/${baseRoute}${idSegment}${programDetailsSegment}`;

                breadcrumbs.push({
                    label: label,
                    url: generatedUrl,
                });
            }
            else if (breadcrumb == 'Subsidy Profile') {
                const label = `${route.params['id'] ? (await this.getName(breadcrumb, route.params['id'])) : ''}`
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });
            }
            else if (breadcrumb == 'Ledger Transaction') {
                const label = `${route.params['id'] ? (await this.getName(breadcrumb, route.params['id'])) : ''}`
                breadcrumbs.push({
                    label: label,
                    url: '/' + routeUrl.join('/'),
                });
            }
            else {
                if (parentBreadcrumb != undefined) {
                    const label = `${route.data['breadcrumb']} ${route.params['id'] ? ' ' + (await this.getName(parentBreadcrumb, route.params['id'])) : ''}`;
                    breadcrumbs.push({
                        label: label,
                        url: '/' + routeUrl.join('/'),
                    });
                } else {
                    const label = `${route.data['breadcrumb']}`;
                    breadcrumbs.push({
                        label: label,
                        url: '/' + routeUrl.join('/'),
                    });
                }

            }


        }

        if (route.firstChild) {
            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }

    async getName(name: any, id: number) {
        let profileName;
        if ((name === 'Classrooms' || name === 'Shift Profile') && id !== undefined) {
            try {
                const req = await this.lookupService
                    .getRoomById(id)
                    .toPromise();
                profileName = req?.body?.name;
            } catch (error) {
                console.error('Error occurred while fetching room by ID:');
            }
        } else if ((name === 'Students' || name === 'Student Activity Profile') && id !== undefined) {
            try {
                const req = await this.lookupService
                    .getStudentById(id)
                    .toPromise();
                profileName = req?.body?.fullName;
            } catch (error) {
                console.error('Error occurred while fetching student by ID:');
            }
        } else if (name === 'Program' && id !== undefined) {
            try {
                const req = await this.lookupService
                    .getProgramById(id)
                    .toPromise();
                profileName = req?.body?.name;
            } catch (error) {
                console.error('Error occurred while fetching program by ID:');
            }
        } else if ((name == 'Staff-Profile' || name === 'Schedule Profile') && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStaffById(id)
                    .toPromise();
                profileName = req?.body?.name;
            } catch (error) {
                console.error('Error occurred while fetching staff by ID:');
            }
        } else if (name == 'Staff-Profile' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStaffById(id)
                    .toPromise();
                profileName = req?.body?.name;
            } catch (error) {
                console.error('Error occurred while fetching staff by ID:');
            }
        }
        else if (name == 'Staff Attendance Profile' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStaffById(id)
                    .toPromise();
                profileName = req?.body?.name;
            } catch (error) {
                console.error('Error occurred while fetching staff by ID:');
            }
        }
        else if (name == 'Profile view' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStudentById(id)
                    .toPromise();
                profileName = req?.body?.fullName;
            } catch (error) {
                console.error('Error occurred while fetching staff by ID:');
            }
        }
        else if (name == 'Family Transaction' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStudentById(id)
                    .toPromise();
                let fullName = req?.body?.fullName ?? "";
                let nameArray = fullName.split(" ");
                profileName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : '';
            } catch (error) {
                console.error('Error occurred while fetching student by ID:');
            }
        }
        else if (name == 'State Subsidy' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getStudentById(id)
                    .toPromise();
                let fullName = req?.body?.fullName ?? "";
                let nameArray = fullName.split(" ");
                profileName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : '';
            } catch (error) {
                console.error('Error occurred while fetching student by ID:');
            }
        }
        else if (name == 'Subsidy Profile' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getLedgerAccountById(id)
                    .toPromise();
                let name = req?.body?.name ?? "";
                // let nameArray = name.split(" ");
                profileName = name.length > 1 ? name : '';
            } catch (error) {
                console.error('Error occurred while fetching student by ID:');
            }
        }
        else if (name == 'Ledger Transaction' && id != undefined) {
            try {
                const req = await this.lookupService
                    .getLedgerAccountById(id)
                    .toPromise();
                let name = req?.body?.name ?? "";
                // let nameArray = name.split(" ");
                profileName = name.length > 1 ? name : '';
            } catch (error) {
                console.error('Error occurred while fetching student by ID:');
            }
        }

        return profileName;
    }
}
