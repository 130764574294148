// import { RouterModule, Routes } from '@angular/router';
// import { NgModule } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthenticationGuard } from './demo/components/auth/authentication/authentication.guard';

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./demo/components/dashboards/dashboards.module').then(m => m.DashboardsModule), canActivate: [AuthenticationGuard] },
            { path: 'uikit', data: { breadcrumb: 'UI Kit' }, loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
            { path: 'utilities', data: { breadcrumb: 'Utilities' }, loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
            { path: 'pages', data: { breadcrumb: 'Pages' }, loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },
            { path: 'profile', data: { breadcrumb: 'User Management' }, loadChildren: () => import('./demo/components/profile/profile.module').then(m => m.ProfileModule) },
            { path: 'documentation', data: { breadcrumb: 'Documentation' }, loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
            { path: 'blocks', data: { breadcrumb: 'Prime Blocks' }, loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
            { path: 'ecommerce', data: { breadcrumb: 'E-Commerce' }, loadChildren: () => import('./demo/components/ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
            { path: 'apps', data: { breadcrumb: 'Apps' }, loadChildren: () => import('./demo/components/apps/apps.module').then(m => m.AppsModule) },
            { path: 'myschool',loadChildren: () => import('./demo/components/myschool/myschool.module').then(m => m.MyschoolModule), canActivate: [AuthenticationGuard] },
            { path: 'billing', loadChildren: () => import('./demo/components/billing/billing.module').then(m => m.BillingModule), canActivate: [AuthenticationGuard] },
            { path: 'staff', data: { breadcrumb: 'Staff' },loadChildren: () => import('./demo/components/staff-management/staff-management.module').then(m => m.StaffManagementModule), canActivate: [AuthenticationGuard] },
            { path: 'student-Sign-In', loadChildren: () => import('./demo/components/student-sign-in/student-sign-in.module').then(m => m.StudentSignInModule), canActivate: [AuthenticationGuard] },
            { path: 'settings', loadChildren: () => import('./demo/components/school-setting/school-setting.module').then(m => m.SchoolSettingModule) },
            { path: 'staffSignin', loadChildren: () => import('./demo/components/staff-management/staff-signin/staff-signin.module').then(m => m.StaffSigninModule) },
            { path: 'gen-settings', loadChildren: () => import('./demo/components/settings/settings.module').then(m => m.SettingsModule) },
            { path: 'rolePermission', data: { breadcrumb: 'Roles & Permissions' }, loadChildren: () => import('./demo/components/settings/role-permission/role-permission.module').then(m => m.RolePermissionModule) },
            // { path: 'rooms', data: { breadcrumb: 'Room' }, loadChildren: () => import('./demo/components/myschool/rooms/rooms.module').then(m => m.RoomsModule) },
            { path: 'parentConnection', loadChildren: () => import('./demo/components/parent-connection/parent-connection.module').then(m => m.ParentConnectionModule) },
            // { path: 'parentConnection', data: { breadcrumb: 'Parent Connection' }, loadChildren: () => import('./demo/components/parent-connection/parent-connection.module').then(m => m.ParentConnectionModule) },
            { path: 'calendar-event', data: { breadcrumb: 'Calendar' }, loadChildren: () => import('./demo/components/calendar-event/calendar-event.module').then(m => m.CalendarEventModule) },
            { path: 'staff-message', loadChildren: () => import('./demo/components/staff-management/staff-message/staff-message.module').then(m => m.StaffMessageModule) },
            { path: 'reports', loadChildren: () => import('./demo/components/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'future-enrollment', loadChildren: () => import('./demo/components/future-enrollment/future-enrollment.module').then(m => m.FutureEnrollmentModule) },
            { path: 'future-availability', loadChildren: () => import('./demo/components/future-availability/future-availability.module').then(m => m.FutureAvailabilityModule) },
            { path: 'room-movement', loadChildren: () => import('./demo/components/room-movement/room-movement.module').then(m => m.RoomMovementModule) },
        ]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: 'studentSignIn', loadChildren: () => import('./demo/components/student-sign-in/student-sign-in.module').then(m => m.StudentSignInModule) },
    { path: 'my-contacts', loadChildren: () => import('./demo/components/myschool/student/student-profile/my-contacts/my-contacts.module').then(m => m.MyContactsModule) },
    { path: 'comingsoon', loadChildren: () => import('./demo/components/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
