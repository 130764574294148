import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monthDay',
})
export class MonthDayPipe implements PipeTransform {
    transform(value: string): string {
        const value1 = Number(value);
        if (value1 === 0) {
            return 'Last day';
        } else if (value1 == 1) {
            return '1st day';
        } else if (value1 === 2) {
            return '2nd day';
        } else if (value1 === 3) {
            return '3rd day';
        } else if (value1 > 3) {
            return value1 + 'th day';
        } else if (value1 === -1) {
            return '2nd last day';
        } else if (value1 === -2) {
            return '3rd last day';
        } else if (value1 === -3) {
            return '4th last day';
        } else if (value1 === -4) {
            return '5th last day';
        } else if (value1 === -5) {
            return '6th last day';
        } else if (value1 === -6) {
            return '7th last day';
        } else if (value1 === -7) {
            return '8th last day';
        } else if (value1 === -8) {
            return '9th last day';
        } else if (value1 === -9) {
            return '10th last day';
        } else {
            return '';
        }
    }
}
