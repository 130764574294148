import { state } from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { WebStorage } from '../demo/components/auth/storage/web.storage';
import { DomSanitizer } from '@angular/platform-browser';
import { LookupService } from '../demo/service/lookup.service';
import { HttpResponse } from '@angular/common/http';
import { GuardianDTO } from '../demo/model/GuardianDTO';
import { StaffDTO } from '../demo/model/StaffDTO';
import { StudentDTO } from '../demo/model/StudentDTO';
import { RoomDTO } from '../demo/model/RoomDTO';
import { SchoolDTO } from '../demo/model/SchoolDTO';
import { LocalOperationService } from '../demo/service/localOperation.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [
        `
            .enlarge-on-hover {
                transition: transform 0.3s;
            }

            .enlarge-on-hover:hover {
                transform: scale(1.5);
                cursor: pointer;
            }
        `,
    ],
})
export class AppTopBarComponent implements OnInit {
    menu: MenuItem[] = [];
    @ViewChild('searchinput') searchInput!: ElementRef;
    @ViewChild('menubutton') menuButton!: ElementRef;
    searchActive: boolean = false;
    name: string = '';
    userdata: any = {};
    logo: undefined;
    schooldetails: any = {};
    search: any = {};
    picture: any = undefined;
    isImageLoading!: boolean;
    imageToShow!: string | ArrayBuffer | null;
    studentRelation: string | undefined;
    userRole: string | undefined = '';
    refTable: string = 'Staff';
    isAdditionalAccount: boolean = false;
    constructor(
        public layoutService: LayoutService,
        protected readonly keycloak: KeycloakService,
        private lookupService: LookupService,
        private sanitizer: DomSanitizer,
        private storage: WebStorage,
        private cdr: ChangeDetectorRef,
        private localOperationService: LocalOperationService,
        private router: Router

    ) { }

    ngOnInit() {

        this.userdata = this.storage.getUserData();
        this.schooldetails = {
            description: undefined,
            id: undefined,
            name: undefined,
            schoolId: this.userdata.schoolId,
            status: 'A',
            logo: undefined,
        };
        this.queryData();
        if (this.userdata != null) {
            let queryParams;
            if (this.userdata.refTable == 'Staff') {
                this.refTable = 'Guardian';
                queryParams = {
                    'docType.equals': 'PROFILE',
                    'refTable.equals': 'Staff',
                    'refTableId.equals': this.userdata.refTableId,
                };
                this.queryUserRoleRelation();
            } else if (this.userdata.refTable == 'Guardian') {
                this.refTable = 'Staff';
                queryParams = {
                    'docType.equals': 'PROFILE',
                    'refTable.equals': 'Guardian',
                    'refTableId.equals': this.userdata.refTableId,
                };

                this.queryGuardian();
            }

            this.lookupService
                .queryAllDocuments(queryParams)
                .subscribe((req: Blob) => {
                    if (req.size != 0) {
                        this.createImageFromBlob(req);
                        this.isImageLoading = false;
                    } else {
                        this.picture = undefined;
                    }
                });

            this.lookupService.queryAccountCount({ 'ssoKey.equals': this.userdata.ssoKey }).subscribe((req: HttpResponse<any>) => {
                this.isAdditionalAccount = req.body == 2 ? true : false;
            })
        }
    }

    ngAfterViewInit() {
        this.localOperationService.schoolMessage.subscribe(massage => {
            if (massage == true) {
                this.queryData()
            }
        })
    }
    queryData() {
        this.lookupService
            .getSchoolById(this.userdata.schoolId)
            .subscribe(async (res: HttpResponse<SchoolDTO>) => {
                this.schooldetails = res.body ?? {};
                if (res.body) {
                    this.fetchSchoolImg(res.body);
                }

                const queryParam = {
                    'schoolId.equals': this.userdata.schoolId,
                    'refTableId.equals': this.userdata.schoolId,
                    'refTable.equals': 'School',
                };

            });
    }

    fetchSchoolImg(school: SchoolDTO) {
        if (school.logo) {
            this.lookupService
                .queryDocumentByPhotoUid(school.logo)
                .subscribe((response: HttpResponse<Blob>) => {
                    const body = response?.body;
                    if (body) {
                        const url = this.createImgFromBuffer(body);
                        school.logoUrl = url;
                    }
                });
        }
    }

    createImgFromBuffer(blob: Blob) {
        const url = URL.createObjectURL(blob);
        return url;
    }


    activeGuardianAllStudents: StudentDTO[] = [];
    activeStudent: StudentDTO = {};
    withoutActiveStudent: StudentDTO[] = [];
    async queryGuardian() {
        this.search = {};
        this.search['id.equals'] = this.userdata.refTableId;
        this.lookupService
            .queryGuardian(this.search)
            .subscribe((res: HttpResponse<GuardianDTO[]>) => {
                // if (this.isAdditionalAccount) {
                const temp: GuardianDTO[] = [];
                const activeGuardian: GuardianDTO[] = res.body ? res.body : temp;
                const tempStudent: StudentDTO[] = [];
                this.activeGuardianAllStudents = activeGuardian[0].students ? activeGuardian[0]?.students : tempStudent;
                const activeStudent = localStorage.getItem('activeStudent');
                this.activeStudent = activeStudent ? JSON.parse(activeStudent) : this.activeGuardianAllStudents ? this.activeGuardianAllStudents[0] : {};
                localStorage.setItem('activeStudent', JSON.stringify(this.activeStudent));
                // this.activeStudent = this.activeGuardianAllStudents ? this.activeGuardianAllStudents[0] : {};
                this.withoutActiveStudent = this.activeGuardianAllStudents ? this.activeGuardianAllStudents.filter(each => each.id !== this.activeStudent?.id) : tempStudent;
                this.withoutActiveStudent.forEach(async (student) => {
                    const temp: any = {};
                    const students: HttpResponse<StudentDTO> = student.id ? await this.lookupService.getStudentById(student.id).toPromise() : temp;
                    student.roomId = students.body?.roomId;
                    const roomDto: HttpResponse<RoomDTO> = student.roomId ? await this.lookupService.getRoomById(student?.roomId).toPromise() : temp;
                    student.roomName = roomDto.body ? roomDto.body.name : '';
                    this.lookupService
                        .queryAllDocuments({
                            'docType.equals': 'PROFILE',
                            'refTableId.equals': student.id,
                            'refTable.equals': 'Student',
                        })
                        .subscribe((req: Blob) => {
                            let reader = new FileReader();
                            reader.addEventListener(
                                'load',
                                () => {
                                    let imageToShow:
                                        | string
                                        | ArrayBuffer;
                                    if (reader.result) {
                                        imageToShow = reader.result;
                                        student.profileimg =
                                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                                '' + imageToShow
                                            );
                                    } else {
                                        // Handle the case where reader.result is null (if needed)
                                        // You can assign a default value or handle it differently.
                                    }
                                },
                                false
                            );

                            if (req.size != 0) {
                                reader.readAsDataURL(req);
                            } else {
                                student.profileimg = undefined;
                            }
                        });
                });
                // }

                this.studentRelation = res.body
                    ? res.body[0]?.relation
                    : undefined;
                this.studentRelation = this.studentRelation ? `${this.studentRelation}` : '';
            });
    }

    queryUserRoleRelation() {
        this.search = {};
        this.search['id.equals'] = this.userdata.refTableId;
        this.lookupService
            .queryStaff(this.search)
            .subscribe((res: HttpResponse<StaffDTO[]>) => {
                this.userRole = res.body
                    ? res.body[0]?.designation
                    : undefined;
                this.userRole = this.userRole ? `${this.userRole}` : '';
            });
    }

    // queryUserRoleRelation(user: any) {
    //     this.lookupService.queryUserRole({ 'userId': user.id }).subscribe((res: HttpResponse<any>) => {
    //       const userRelation = res.body;                
    //       if (userRelation) {
    //         switch (true) {
    //           case userRelation.roles.some((role: any) => role.name.includes("ROLE_ADMIN")):
    //             this.userRole = "Admin";
    //             break;
    //           case userRelation.roles.some((role: any) => role.name.includes("ROLE_STAFF")):
    //             this.userRole = "Staff";
    //             break;
    //           case userRelation.roles.some((role: any) => role.name.includes("ROLE_DIRECTOR")):
    //             this.userRole = "Director";
    //             break;
    //           default:
    //             this.userRole = "Staff";
    //             return;
    //         }      
    //       }
    //     });
    // }




    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.imageToShow = reader.result;
                this.picture = this.sanitizer.bypassSecurityTrustResourceUrl(
                    '' + this.imageToShow
                );
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config.layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }

    // get logo(): string {
    //     const path = 'assets/layout/images/wise_owl_logo';
    //     // const logo = (this.layoutTheme === 'primaryColor'  && !(this.layoutService.config.theme  == "yellow")) ? 'light.png' : (this.colorScheme === 'light' ? 'dark.png' : 'light.png');
    //     return path;
    // }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    // Logout() {
    //     this.storage.logout();
    //     setTimeout(() => {
    //         this.keycloak
    //             .logout(window.location.origin + environment.path)
    //             .then((data) => {});
    //     }, 2000);
    // }

    async Logout() {
        localStorage.removeItem('logQueryParam');
        localStorage.removeItem('activeStudent');
        this.storage.logout();
        try {
            await this.keycloak.logout(
                window.location.origin + environment.path
            );

            await new Promise((resolve) => setTimeout(resolve, 100));

            this.userdata = this.storage.getUserData();
            this.cdr.detectChanges();
        } catch (error) {
            console.error('Logout error:', error);
        }
    }


    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onAccountChange(refTable: string) {
        localStorage.removeItem('logQueryParam');
        localStorage.setItem('logQueryParam', refTable);
        localStorage.removeItem('activeStudent');
        // window.location.reload();
        window.location.href = ''
    }

    studentProfileChange(student: StudentDTO) {
        this.activeStudent = student;
        localStorage.removeItem('activeStudent');
        localStorage.setItem('activeStudent', JSON.stringify(student));

        const tempStudent: StudentDTO[] = [];
        this.withoutActiveStudent = this.activeGuardianAllStudents ? this.activeGuardianAllStudents.filter(each => each.id !== this.activeStudent?.id) : tempStudent;
        this.withoutActiveStudent.forEach(async (student) => {
            this.lookupService
                .queryAllDocuments({
                    'docType.equals': 'PROFILE',
                    'refTableId.equals': student.id,
                    'refTable.equals': 'Student',
                })
                .subscribe((req: Blob) => {
                    let reader = new FileReader();
                    reader.addEventListener(
                        'load',
                        () => {
                            let imageToShow:
                                | string
                                | ArrayBuffer;
                            if (reader.result) {
                                imageToShow = reader.result;
                                student.profileimg =
                                    this.sanitizer.bypassSecurityTrustResourceUrl(
                                        '' + imageToShow
                                    );
                            } else {
                                // Handle the case where reader.result is null (if needed)
                                // You can assign a default value or handle it differently.
                            }
                        },
                        false
                    );

                    if (req.size != 0) {
                        reader.readAsDataURL(req);
                    } else {
                        student.profileimg = undefined;
                    }
                });
        });
        window.location.href = '';
        this.router.navigateByUrl('');

    }
}
