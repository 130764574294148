import { Component, HostListener, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { WebStorage } from '../demo/components/auth/storage/web.storage';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: MenuItem[] = [];
    isMobile!: boolean;
    hasDocumentAuthorityLastPhysician = this.storage.hasAnyAuthority([
        'Staff Profile (Full Access)',
        'Staff Profile (Read)',
    ]);

    constructor(
        public layoutService: LayoutService,
        private storage: WebStorage
    ) {}

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.isMobile = event.target.innerWidth < 1000;
        this.updateMenu();
    }

    ngOnInit() {
        this.isMobile = window.innerWidth < 1000;
        this.updateMenu();
    }

    updateMenu() {
        this.model = [
            {
                label: 'Home',
                icon: 'pi pi-home',
                routerLink: ['/'],
                items: [
                    {
                        label: 'Dashboard',
                        icon: '',
                        routerLink: ['/'],
                    },
                ],
            },
            {
                label: 'Classrooms',
                icon: 'pi pi-microsoft',
                routerLink: ['/myschool/rooms'],
                visible: this.storage.hasAnyAuthority([
                    'Room Profile (Read)',
                    'Room Profile (Full Access)',
                ]),
                items: [
                    {
                        label: 'Overview',
                        icon: 'pi pi-chart-bar',
                        routerLink: ['/myschool/rooms'],
                        visible: this.storage.hasAnyAuthority([
                            'Room Profile (Read)',
                            'Room Profile (Full Access)',
                        ]),
                    },
                    // {
                    //     label: 'Ratio Monitoring',
                    //     icon: 'pi pi-chart-bar',
                    //     routerLink: ['/comingsoon']
                    // },
                    // {
                    //     label: 'Room Movement',
                    //     icon: 'pi pi-chart-bar',
                    //     routerLink: ['/comingsoon']
                    // },
                ],
            },
            {
                label: 'Students',
                icon: 'pi pi-user',
                routerLink: ['/myschool'],
                // visible: this.storage.hasAnyAuthority(['Room Profile (Read)','Room Profile (Full Access)']),
                items: [
                    // {
                    //     label: 'Rooms',
                    //     icon: 'pi pi-chart-bar',
                    //     routerLink: ['/myschool/rooms'],
                    //     visible: this.storage.hasAnyAuthority(['Room Profile (Read)','Room Profile (Full Access)'])
                    // },
                    {
                        label: 'Overview',
                        icon: 'pi pi-user',
                        routerLink: ['/myschool/student'],
                        visible: this.storage.hasAnyAuthority([
                            'Student Profile (Full Access)',
                            'Student Profile (Read)',
                            'Student (Full Access)',
                            'Student (Read)',
                        ]),
                    },
                    {
                        label: 'Attendance',
                        icon: 'pi pi-calculator',
                        // routerLink: ['/student-Sign-In/dailyAttendance'],
                        visible: this.storage.hasAnyAuthority([
                            'Attendance Daily (Full Access)',
                            'Attendance Daily (Read)',
                            'Attendance Monthly (Read)',
                            'Attendance Absent (Full Access)',
                            'Attendance Absent (Read)',
                        ]),
                        items: [
                            {
                                label: 'Sign-In',
                                icon: 'pi pi-fw pi-sign-in',
                                routerLink: [
                                    '/student-Sign-In/markAttendance/signinStudent',
                                ],
                            },
                            {
                                label: 'Sign-Out',
                                icon: 'pi pi-fw pi-sign-out',
                                routerLink: [
                                    '/student-Sign-In/markAttendance/signoutStudent',
                                ],
                            },
                            {
                                label: 'Transfer',
                                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                                routerLink: [
                                    '/student-Sign-In/markAttendance/transferStudent',
                                ],
                                visible: this.storage.hasAnyAuthority([
                                    'Attendance Daily (Full Access)',
                                ]),
                            },
                            {
                                label: 'View',
                                icon: 'pi pi-fw pi-book',
                                routerLink: [
                                    '/student-Sign-In/viewStudentAttendance/dailyViewAttendance',
                                ],
                            },
                            {
                                label: 'Absent',
                                icon: 'pi pi-fw pi-sign-out',
                                routerLink: ['/student-Sign-In/absentStudent'],
                            },
                        ],
                    },
                    {
                        label: 'Daily Activity',
                        icon: 'pi pi-id-card',
                        routerLink: ['parentConnection/activities/all'],
                        visible: this.storage.hasAnyAuthority([
                            'Parent Connection Newsletter (Full Access)',
                            'Parent Connection Newsletter (Read)',
                            'Parent Connection Daily Activity (Read)',
                            'Parent Connection Daily Activity (Full Access)',
                            'Parent Connection Daily Activity Feed (Read)',
                            'Parent Connection Daily Activity Feed (Full Access)',
                        ]),
                    },
                    {
                        label: 'Tags',
                        icon: 'pi pi-book',
                        routerLink: ['/myschool/tags'],
                        visible: this.storage.hasAnyAuthority([
                            'Tag (Full Access)',
                            'Tag (Read)',
                        ]),
                    },
                    // {
                    //     label: 'Immunization',
                    //     icon: 'pi pi-calculator',
                    //     routerLink: ['/settings/immunizations'],
                    // },
                    // {
                    //     label: 'Programs',
                    //     icon: 'pi pi-chart-bar',
                    //     routerLink: ['/myschool/programs/programList'],
                    //     visible: this.storage.hasAnyAuthority([
                    //         'Student Program (Full Access)',
                    //         'Student Program (Read)',
                    //         'Room Program (Full Access)',
                    //         'Room Program (Read)',
                    //         'Staff Program (Full Access)',
                    //         'Staff Program (Read)',
                    //         'Program (Full Access)',
                    //         'Program (Read)',
                    //     ]),
                    // },
                ],
            },
            {
                label: 'Staff',
                icon: 'pi pi-users',
                routerLink: ['/staff'],
                visible: this.storage.hasAnyAuthority([
                    'Staff Profile (Full Access)',
                    'Staff Profile (Read)',
                    'Staff Attendance (Full Access)',
                    'Staff Attendance (Full Access)',
                    'Staff Message (Full Access)',
                ]),
                items: [
                    {
                        label: 'Overview',
                        icon: 'pi pi-users',
                        routerLink: ['/staff/staff-List'],
                        visible: this.storage.hasAnyAuthority([
                            'Staff Profile (Full Access)',
                            'Staff Profile (Read)',
                        ]),
                    },
                    {
                        label: 'Attendance',
                        icon: 'pi pi-calculator',
                        // routerLink: ['/staffSignin/staff-daily-Signin'],
                        visible: this.storage.hasAnyAuthority([
                            'Staff Attendance (Full Access)',
                            'Staff Attendance (Read)',
                        ]),
                        items: [
                            {
                                label: 'Sign-In',
                                icon: 'pi pi-fw pi-sign-in',
                                routerLink: ['/staff/attendance/sign-in'],
                            },
                            {
                                label: 'Sign-Out',
                                icon: 'pi pi-fw pi-sign-out',
                                routerLink: ['/staff/attendance/sign-out'],
                            },
                            {
                                label: 'Transfer',
                                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                                routerLink: ['/staff/attendance/transfer'],
                            },
                            {
                                label: 'View',
                                icon: 'pi pi-fw pi-book',
                                routerLink: [
                                    '/staff/viewAttendance/daily-view',
                                ],
                            },
                            {
                                label: 'Absent',
                                icon: 'pi pi-fw pi-sign-out',
                                routerLink: ['/staff/absentStaff'],
                                visible: this.storage.hasAnyAuthority([
                                    'Staff Profile (Full Access)',
                                    'Staff Profile (Read)',
                                ]),
                            },
                        ],
                    },
                    {
                        label: 'Staff Message',
                        icon: 'pi pi-fw pi-comments',
                        routerLink: ['/staff-message'],
                        visible: this.storage.hasAnyAuthority([
                            'Staff Message (Full Access)',
                            'Staff Message (Read)',
                        ]),
                    },
                    // {
                    //     label: 'Training',
                    //     icon: 'pi pi-fw pi-comments',
                    //     routerLink: ['/comingsoon']
                    // },
                    // {
                    //         label: 'Time Management',
                    //         icon: 'pi pi-fw pi-comment',
                    //         items: [
                    //         {
                    //             label: 'Schedule',
                    //             icon: 'pi pi-fw pi-list',
                    //             routerLink: ['/staff/time-management/time-schedule'],
                    //         },
                    //         {
                    //             label: 'Shift',
                    //             icon: 'pi pi-fw pi-image',
                    //             routerLink: ['/staff/time-management/time-line'],
                    //         }

                    //     ],
                    // },
                ],
            },
            {
                label: 'Scheduling',
                icon: 'pi pi-clock',
                visible: this.storage.hasAnyAuthority([
                    'Attendance Daily (Full Access)',
                ]),
                items: [
                    {
                        label: 'Staff Schedule',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/staff/time-management/time-schedule'],
                    },
                    {
                        label: 'Classroom Shift',
                        icon: 'pi pi-fw pi-image',
                        routerLink: ['/staff/time-management/time-line'],
                    },
                ],
            },
            // {
            //     label: 'Staff',
            //     icon: 'pi pi-user',
            //     routerLink: ['/staff'],
            //     visible: this.storage.hasAnyAuthority([
            //         'Staff Profile (Full Access)',
            //         'Staff Profile (Read)',
            //         'Staff Attendance (Full Access)',
            //         'Staff Attendance (Full Access)',
            //         'Staff Message (Full Access)',
            //         'Staff Message (Read)',
            //     ]),
            //     items: [
            //         {
            //             label: 'Staff',
            //             icon: 'pi pi-users',
            //             routerLink: ['/staff/staff-List'],
            //             visible: this.storage.hasAnyAuthority([
            //                 'Staff Profile (Full Access)',
            //                 'Staff Profile (Read)',
            //             ]),
            //         },
            //         {
            //             label: 'Staff Attendance / Room Transfers',
            //             icon: 'pi pi-id-card',
            //             routerLink: ['/staffSignin/staff-daily-Signin'],
            //             visible: this.storage.hasAnyAuthority([
            //                 'Staff Attendance (Full Access)',
            //                 'Staff Attendance (Read)',
            //             ]),
            //         },
            // {
            //     label: 'Daily Activity',
            //     icon: 'pi pi-id-card',
            //     routerLink: ['parentConnection/activities/all'],
            //     visible:this.storage.hasAnyAuthority(['Parent Connection Newsletter (Full Access)','Parent Connection Newsletter (Read)','Parent Connection Daily Activity (Read)'
            //     ,'Parent Connection Daily Activity (Full Access)','Parent Connection Daily Activity Feed (Read)','Parent Connection Daily Activity Feed (Full Access)']),
            // },
            //     ],
            // },
            {
                label: 'Communication',
                icon: 'pi pi-fw pi-comment',
                routerLink: ['/parentConnection'],
                visible: this.storage.hasAnyAuthority([
                    'Parent Connection Newsletter (Full Access)',
                    'Parent Connection Newsletter (Read)',
                    'Parent Connection Message (Full Access)',
                    'Prent Connection Message (Read)',
                    'Parent Connection Daily Activity (Read)',
                    'Parent Connection Daily Activity (Full Access)',
                    'Parent Connection Daily Activity (Read)',
                    'Parent Connection Daily Activity Feed (Full Access)',
                ]),
                items: [
                    {
                        label: 'Messaging',
                        icon: 'pi pi-comments',
                        routerLink: ['parentConnection/communication'],
                        visible: this.storage.hasAnyAuthority([
                            'Parent Connection Message (Full Access)',
                            'Prent Connection Message (Read)',
                            'Parent Connection Newsletter (Full Access)',
                        ]),
                    },
                    {
                        label: 'Newsletter',
                        icon: 'pi pi-id-card',
                        routerLink: ['parentConnection/newsletter/sentLetter'],
                        visible: this.storage.hasAnyAuthority([
                            'Parent Connection Newsletter (Full Access)',
                            'Parent Connection Newsletter (Read)',
                        ]),
                    },
                    // {
                    //     label: 'Daily Activity',
                    //     icon: 'pi pi-id-card',
                    //     routerLink: ['parentConnection/activities/all'],
                    //     visible:this.storage.hasAnyAuthority(['Parent Connection Newsletter (Full Access)','Parent Connection Newsletter (Read)','Parent Connection Daily Activity (Read)'
                    //     ,'Parent Connection Daily Activity (Full Access)','Parent Connection Daily Activity Feed (Read)','Parent Connection Daily Activity Feed (Full Access)']),
                    // },
                ],
            },
            {
                label: 'Billing',
                icon: 'pi pi-dollar',
                routerLink: ['/billing'],
                visible: this.storage.hasAnyAuthority([
                    'Billing Accounting (Full Access)',
                    '"Billing Accounting (Read)"',
                ]),
                items: [
                    {
                        label: 'Accounting',
                        icon: 'pi pi-calculator',
                        routerLink: ['/billing/accounting/family'],
                        visible: this.storage.hasAnyAuthority([
                            'Billing Accounting (Full Access)',
                            '"Billing Accounting (Read)"',
                        ]),
                    },
                    {
                        label: 'Recurring Plans',
                        icon: 'pi pi-history',
                        routerLink: ['/billing/plans'],
                        visible: this.storage.hasAnyAuthority([
                            'Billing Accounting (Full Access)',
                            '"Billing Accounting (Read)"',
                        ]),
                    },
                    {
                        label: 'State Subsidy',
                        icon: 'pi pi-money-bill',
                        routerLink: ['/billing/accounting/subsidies'],
                        visible: this.storage.hasAnyAuthority([
                            'Billing Accounting (Full Access)',
                            '"Billing Accounting (Read)"',
                        ]),
                    },
                    {
                        label: 'All Transactions',
                        icon: 'pi pi-dollar',
                        routerLink: ['/billing/alltransaction'],
                        visible: this.storage.hasAnyAuthority([
                            'Billing Accounting (Full Access)',
                            '"Billing Accounting (Read)"',
                        ]),
                    },
                ],
            },
            {
                label: 'Future Enrollment',
                icon: 'pi pi-file-edit',
                visible: this.storage.hasAnyAuthority([
                    'Staff (Full Access)',
                ]),
                items: [    
                            {
                                label: 'Classroom Movement',
                                icon: 'pi pi-file-edit',
                                routerLink: './room-movement',
                                // visible: false,
                            },
                            {
                                label: 'Enrollment',
                                icon: 'pi pi-id-card',
                                routerLink: ['/future-enrollment'],

                            },
                            {
                                label: 'Availability',
                                icon: 'pi pi-user',
                                routerLink: ['/future-availability'],
                            },
                        ],
            },



            // {
            //     label: 'Enrolment',
            //     icon: 'pi pi-fw pi-calendar',
            //     items: [
            //         {
            //             label: 'Current Availability',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Future Availability',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //     ],
            // },
            // {
            //     label: 'Curriculum',
            //     icon: 'pi pi-fw pi-calendar',
            //     items: [
            //         {
            //             label: 'Lesson Plan',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Observations',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Progress Report',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //     ],
            // },
            // {
            //     label: 'To Do',
            //     icon: 'pi pi-fw pi-calendar',
            //     items: [
            //         {
            //             label: 'Tasks',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Event Scheduling',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //     ],
            // },
            // {
            //     label: 'Licensing',
            //     icon: 'pi pi-fw pi-calendar',
            //     items: [
            //         {
            //             label: 'Licensing Reports',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Checklist',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Alerts',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //     ],
            // },

            // {
            //     label: 'Calendar',
            //     icon: 'pi pi-fw pi-calendar',
            //     routerLink: ['/calendar-event/calendar'],
            //     visible: this.storage.hasAnyAuthority([
            //         'Calendar (Full Access)',
            //         'Calendar (Read)',
            //     ]),
            //     items: [
            //         {
            //             label: 'Calendar',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/calendar-event/calendar'],
            //             visible: this.storage.hasAnyAuthority([
            //                 'Calendar (Full Access)',
            //                 'Calendar (Read)',
            //             ]),
            //         },
            //     ],
            // },
            // {
            //     label: 'Student Sign-In',
            //     icon: 'pi pi-book',
            //     routerLink: ['/student-Sign-In'],
            //     visible: this.storage.hasAnyAuthority(['Attendance Daily (Full Access)','Attendance Daily (Read)','Attendance Monthly (Read)',
            //     'Attendance Absent (Full Access)','Attendance Absent (Read)']),
            //     items: [
            //         {
            //             label: 'Attendance',
            //             icon: 'pi pi-users',
            //             routerLink: ['/student-Sign-In/dailyAttendance'],
            //             visible: this.storage.hasAnyAuthority(['Attendance Daily (Full Access)','Attendance Daily (Read)','Attendance Monthly (Read)',
            //             'Attendance Absent (Full Access)','Attendance Absent (Read)']),
            //         },
            //     ],
            // },
            // {
            //     label: 'Parent Connection',
            //     icon: 'pi pi-spin pi-cog',
            //     routerLink: ['/parentConnection'],
            //     visible:this.storage.hasAnyAuthority(['Parent Connection Newsletter (Full Access)','Parent Connection Newsletter (Read)',
            //     'Parent Connection Message (Full Access)','Prent Connection Message (Read)','Parent Connection Daily Activity (Read)',
            //     'Parent Connection Daily Activity (Full Access)', 'Parent Connection Daily Activity (Read)',
            //     'Parent Connection Daily Activity Feed (Full Access)']),
            //     items: [
            //         {
            //             label: 'Newsletters',
            //             icon: 'pi pi-id-card',
            //             routerLink: ['parentConnection/newsletter/sentLetter'],
            //             visible:this.storage.hasAnyAuthority(['Parent Connection Newsletter (Full Access)','Parent Connection Newsletter (Read)']),
            //         },
            //         {
            //             label: 'Messages',
            //             icon: 'pi pi-comments',
            //             routerLink: ['parentConnection/communication'],
            //             visible:this.storage.hasAnyAuthority(['Parent Connection Message (Full Access)','Prent Connection Message (Read)']),
            //         },
            //         {
            //             label: 'Daily Activity',
            //             icon: 'pi pi-id-card',
            //             routerLink: ['parentConnection/activities/all'],
            //             visible:this.storage.hasAnyAuthority(['Parent Connection Newsletter (Full Access)','Parent Connection Newsletter (Read)','Parent Connection Daily Activity (Read)'
            //             ,'Parent Connection Daily Activity (Full Access)','Parent Connection Daily Activity Feed (Read)','Parent Connection Daily Activity Feed (Full Access)']),
            //         },
            //     ],
            // },
            // {
            //     label: 'Calendar',
            //     icon: 'pi pi-fw pi-calendar',
            //     routerLink: ['/calendar-event/calendar'],
            //     visible:this.storage.hasAnyAuthority(['Calendar (Full Access)','Calendar (Read)']),
            //     items: [
            //         {
            //             label: 'Calendar',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/calendar-event/calendar'],
            //             visible:this.storage.hasAnyAuthority(['Calendar (Full Access)','Calendar (Read)']),
            //         },
            //     ],
            // },







            {
                label: 'Report',
                icon: 'pi pi-fw pi-file',
                visible: this.storage.hasAnyAuthority([
                    'Reports (Full Access)',
                    'Reports (Read)',
                ]),
                items: [
                    {
                        label: 'Reports',
                        icon: 'pi pi-fw pi-file',
                        routerLink: ['/reports'],
                        visible: this.storage.hasAnyAuthority([
                            'Reports (Full Access)',
                            'Reports (Read)',
                        ]),
                    },
                ],
            },










            // {
            //     label: 'AI Helper',
            //     icon: 'pi pi-fw pi-calendar',
            //     items: [
            //         {
            //             label: 'Daily Activity Report',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Teachers Assistant',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //         {
            //             label: 'Early Detection',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/comingsoon']
            //         },
            //     ],
            // },
            {
                label: 'Settings',
                icon: 'pi pi-cog',
                routerLink: ['/settings'],
                visible: this.storage.hasAnyAuthority([
                    'School Settings General (Full Access)',
                    'School Settings General (Read)',
                    'School Settings Immunization (Full Access)',
                    'School Settings Immunization (Read)',
                    'School Settings Custom Fields (Full Access)',
                    'School Settings Custom Fields (Read)',
                ]),
                items: [
                    // {
                    //     label: 'School Profile',
                    //     icon: 'pi pi-home',
                    //     routerLink: ['/settings/school'],
                    // },
                    {
                        label: 'School Settings',
                        icon: 'pi pi-calculator',
                        routerLink: ['/settings/school'],
                    },
                    {
                        label: 'Users',
                        icon: 'pi pi-user',
                        routerLink: ['/gen-settings/users'],
                        visible: this.storage.hasAnyAuthority([
                            'Staff (Full Access)',
                        ]),
                    },
                    // {
                    //     label: 'Activity Type',
                    //     icon: 'pi pi-user',
                    //     routerLink: ['/gen-settings/activity-type'],
                    // },
                ],
            },
            // {
            //     label: 'Common Settings',
            //     icon: 'pi pi-spin pi-cog',
            //     routerLink: ['/gen-settings'],
            //     visible : this.storage.hasAnyAuthority(['Staff (Full Access)']),
            //     items: [
            //         // {
            //         //     label: 'Role & Permission',
            //         //     icon: 'pi pi-id-card',
            //         //     routerLink: ['rolePermission'],
            //         //     visible : this.storage.hasAnyAuthority(['Staff (Full Access)']),
            //         // },
            //         {
            //             label: 'User',
            //             icon: 'pi pi-user',
            //             routerLink: ['/gen-settings/users'],
            //             visible : this.storage.hasAnyAuthority(['Staff (Full Access)']),
            //         },
            //         // {
            //         //     label: 'Activity Type',
            //         //     icon: 'pi pi-user',
            //         //     routerLink: ['/gen-settings/activity-type'],
            //         // },
            //     ],
            // },
            // {
            //     label: 'UI Kit', icon: 'pi pi-star', routerLink: ['/uikit'],
            //     items: [
            //         { label: 'Form Layout', icon: 'pi pi-id-card', routerLink: ['/uikit/formlayout'] },
            //         { label: 'Input', icon: 'pi pi-check-square', routerLink: ['/uikit/input'] },
            //         { label: 'Float Label', icon: 'pi pi-bookmark', routerLink: ['/uikit/floatlabel'] },
            //         { label: 'Invalid State', icon: 'pi pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
            //         { label: 'Button', icon: 'pi pi-box', routerLink: ['/uikit/button'] },
            //         { label: 'Table', icon: 'pi pi-table', routerLink: ['/uikit/table'] },
            //         { label: 'List', icon: 'pi pi-list', routerLink: ['/uikit/list'] },
            //         { label: 'Tree', icon: 'pi pi-share-alt', routerLink: ['/uikit/tree'] },
            //         { label: 'Panel', icon: 'pi pi-tablet', routerLink: ['/uikit/panel'] },
            //         { label: 'Overlay', icon: 'pi pi-clone', routerLink: ['/uikit/overlay'] },
            //         { label: 'Media', icon: 'pi pi-image', routerLink: ['/uikit/media'] },
            //         { label: 'Menu', icon: 'pi pi-bars', routerLink: ['/uikit/menu'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }},
            //         { label: 'Message', icon: 'pi pi-comment', routerLink: ['/uikit/message'] },
            //         { label: 'File', icon: 'pi pi-file', routerLink: ['/uikit/file'] },
            //         { label: 'Chart', icon: 'pi pi-chart-bar', routerLink: ['/uikit/charts'] },
            //         { label: 'Misc', icon: 'pi pi-circle-off', routerLink: ['/uikit/misc'] }
            //     ]
            // },
            // {
            //     label: 'Apps',
            //     icon: 'pi pi-th-large',
            //     items: [
            //         {
            //             label: 'Blog',
            //             icon: 'pi pi-fw pi-comment',
            //             items: [
            //                 {
            //                     label: 'List',
            //                     icon: 'pi pi-fw pi-image',
            //                     routerLink: ['/apps/blog/list']
            //                 },
            //                 {
            //                     label: 'Detail',
            //                     icon: 'pi pi-fw pi-list',
            //                     routerLink: ['/apps/blog/detail']
            //                 },
            //                 {
            //                     label: 'Edit',
            //                     icon: 'pi pi-fw pi-pencil',
            //                     routerLink: ['/apps/blog/edit']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Calendar',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/apps/calendar']
            //         },
            //         {
            //             label: 'Chat',
            //             icon: 'pi pi-fw pi-comments',
            //             routerLink: ['/apps/chat']
            //         },
            //         {
            //             label: 'Files',
            //             icon: 'pi pi-fw pi-folder',
            //             routerLink: ['/apps/files']
            //         },
            //         {
            //             label: 'Kanban',
            //             icon: 'pi pi-fw pi-sliders-v',
            //             routerLink: ['/apps/kanban']
            //         },
            //         {
            //             label: 'Mail',
            //             icon: 'pi pi-fw pi-envelope',
            //             items: [
            //                 {
            //                     label: 'Inbox',
            //                     icon: 'pi pi-fw pi-inbox',
            //                     routerLink: ['/apps/mail/inbox']
            //                 },
            //                 {
            //                     label: 'Compose',
            //                     icon: 'pi pi-fw pi-pencil',
            //                     routerLink: ['/apps/mail/compose']
            //                 },
            //                 {
            //                     label: 'Detail',
            //                     icon: 'pi pi-fw pi-comment',
            //                     routerLink: ['/apps/mail/detail/1000']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Task List',
            //             icon: 'pi pi-fw pi-check-square',
            //             routerLink: ['/apps/tasklist']
            //         }
            //     ]
            // },
            // {
            //     label: 'Prime Blocks', icon: 'pi pi-fw pi-prime', routerLink: ['/blocks'],
            //     items: [
            //         { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'] },
            //         { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-ng', target: '_blank' },
            //     ]
            // },
            // {
            //     label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/utilities'],
            //     items: [
            //         { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons'] },
            //         { label: 'Colors', icon: 'pi pi-fw pi-palette', routerLink: ['utilities/colors'] },
            //         { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank' },
            //         { label: 'Figma', icon: 'pi pi-fw pi-pencil', url: 'https://www.figma.com/file/PgQXX4HXMPeCkT74tGajod/Preview-%7C-Verona-2022?node-id=1303%3A750', target: '_blank' }
            //     ]
            // },
            // {
            //     label: 'Pages',
            //     icon: 'pi pi-fw pi-briefcase',
            //     items: [
            //         {
            //             label: 'Landing',
            //             icon: 'pi pi-fw pi-globe',
            //             routerLink: ['/landing'],
            //             data: {'fullPage': true}
            //         },
            //         {
            //             label: 'Auth',
            //             icon: 'pi pi-fw pi-user',
            //             items: [
            //                 {
            //                     label: 'Login',
            //                     icon: 'pi pi-fw pi-sign-in',
            //                     routerLink: ['/auth/login'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Error',
            //                     icon: 'pi pi-fw pi-times-circle',
            //                     routerLink: ['/auth/error'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Access Denied',
            //                     icon: 'pi pi-fw pi-lock',
            //                     routerLink: ['/auth/access'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Register',
            //                     icon: 'pi pi-fw pi-user-plus',
            //                     routerLink: ['/auth/register'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Forgot Password',
            //                     icon: 'pi pi-fw pi-question',
            //                     routerLink: ['/auth/forgotpassword'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'New Password',
            //                     icon: 'pi pi-fw pi-cog',
            //                     routerLink: ['/auth/newpassword'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Verification',
            //                     icon: 'pi pi-fw pi-envelope',
            //                     routerLink: ['/auth/verification'],
            //                     data: {'fullPage': true}
            //                 },
            //                 {
            //                     label: 'Lock Screen',
            //                     icon: 'pi pi-fw pi-eye-slash',
            //                     routerLink: ['/auth/lockscreen'],
            //                     data: {'fullPage': true}
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Crud',
            //             icon: 'pi pi-fw pi-pencil',
            //             routerLink: ['/pages/crud']
            //         },
            // {
            //     label: 'Timeline',
            //     icon: 'pi pi-fw pi-calendar',
            //     routerLink: ['/pages/timeline']
            // },
            //         {
            //             label: 'Invoice',
            //             icon: 'pi pi-fw pi-dollar',
            //             routerLink: ['/pages/invoice']
            //         },
            //         {
            //             label: 'About Us',
            //             icon: 'pi pi-fw pi-user',
            //             routerLink: ['/pages/aboutus']
            //         },
            //         {
            //             label: 'Help',
            //             icon: 'pi pi-fw pi-question-circle',
            //             routerLink: ['/pages/help']
            //         },
            //         {
            //             label: 'Not Found',
            //             icon: 'pi pi-fw pi-exclamation-circle',
            //             routerLink: ['/pages/notfound']
            //         },
            //         {
            //             label: 'Empty',
            //             icon: 'pi pi-fw pi-circle-off',
            //             routerLink: ['/pages/empty']
            //         },
            //         {
            //             label: 'FAQ',
            //             icon: 'pi pi-fw pi-question',
            //             routerLink: ['/pages/faq']
            //         },
            //         {
            //             label: 'Contact Us',
            //             icon: 'pi pi-fw pi-phone',
            //             routerLink: ['/pages/contact']
            //         }
            //     ]
            // },
            // {
            //     label: 'E-Commerce',
            //     icon: 'pi pi-fw pi-wallet',
            //     items: [
            //         {
            //             label: 'Product Overview',
            //             icon: 'pi pi-fw pi-image',
            //             routerLink: ['ecommerce/product-overview']
            //         },
            //         {
            //             label: 'Product List',
            //             icon: 'pi pi-fw pi-list',
            //             routerLink: ['ecommerce/product-list']
            //         },
            //         {
            //             label: 'New Product',
            //             icon: 'pi pi-fw pi-plus',
            //             routerLink: ['ecommerce/new-product']
            //         },
            //         {
            //             label: 'Shopping Cart',
            //             icon: 'pi pi-fw pi-shopping-cart',
            //             routerLink: ['ecommerce/shopping-cart']
            //         },
            //         {
            //             label: 'Checkout Form',
            //             icon: 'pi pi-fw pi-check-square',
            //             routerLink: ['ecommerce/checkout-form']
            //         },
            //         {
            //             label: 'Order History',
            //             icon: 'pi pi-fw pi-history',
            //             routerLink: ['ecommerce/order-history']
            //         },
            //         {
            //             label: 'Order Summary',
            //             icon: 'pi pi-fw pi-file',
            //             routerLink: ['ecommerce/order-summary']
            //         }
            //     ]
            // },
            // {
            //     label: 'User Management',
            //     icon: 'pi pi-fw pi-user',
            //     items: [
            //         {
            //             label: 'List',
            //             icon: 'pi pi-fw pi-list',
            //             routerLink: ['profile/list']
            //         },
            //         {
            //             label: 'Create',
            //             icon: 'pi pi-fw pi-plus',
            //             routerLink: ['profile/create']
            //         }
            //     ]
            // },
            // {
            //     label: 'Hierarchy', icon: 'pi pi-align-left',
            //     items: [
            //         {
            //             label: 'Submenu 1', icon: 'pi pi-align-left',
            //             items: [
            //                 {
            //                     label: 'Submenu 1.1', icon: 'pi pi-align-left',
            //                     items: [
            //                         { label: 'Submenu 1.1.1', icon: 'pi pi-align-left' },
            //                         { label: 'Submenu 1.1.2', icon: 'pi pi-align-left' },
            //                         { label: 'Submenu 1.1.3', icon: 'pi pi-align-left' },
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 1.2', icon: 'pi pi-align-left',
            //                     items: [
            //                         { label: 'Submenu 1.2.1', icon: 'pi pi-align-left' }
            //                     ]
            //                 },
            //             ]
            //         },
            //         {
            //             label: 'Submenu 2', icon: 'pi pi-align-left',
            //             items: [
            //                 {
            //                     label: 'Submenu 2.1', icon: 'pi pi-align-left',
            //                     items: [
            //                         { label: 'Submenu 2.1.1', icon: 'pi pi-align-left' },
            //                         { label: 'Submenu 2.1.2', icon: 'pi pi-align-left' },
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 2.2', icon: 'pi pi-align-left',
            //                     items: [
            //                         { label: 'Submenu 2.2.1', icon: 'pi pi-align-left' },
            //                     ]
            //                 },
            //             ]
            //         }
            //     ]
            // },
            // {
            //     label: 'Start', icon: 'pi pi-download',
            //     items: [
            //         {
            //             label: 'Buy Now', icon: 'pi pi-shopping-cart', url: 'https://www.primefaces.org/store'
            //         },
            //         {
            //             label: 'Documentation', icon: 'pi pi-info-circle', routerLink: ['/documentation']
            //         }
            //     ]
            // }
        ];

        // if (this.isMobile) {
        //     this.model.unshift({
        //         items: [
        //             {
        //                 label: 'Home',
        //                 icon: 'pi pi-microsoft',
        //                 routerLink: ['/'],
        //             },
        //             {
        //                 label: 'Classrooms',
        //                 icon: 'pi pi-home',
        //                 routerLink: ['/myschool/rooms'],
        //                 visible: this.storage.hasAnyAuthority([
        //                     'Room Profile (Read)',
        //                     'Room Profile (Full Access)',
        //                 ]),
        //             }
        //         ],
        //     });
        // } else {
        //     let array = [
        //         {
        //             label: 'Overview',
        //             icon: 'pi pi-home',
        //             routerLink: ['/myschool/rooms'],
        //             visible: this.storage.hasAnyAuthority([
        //                 'Room Profile (Read)',
        //                 'Room Profile (Full Access)',
        //             ]),
        //         },{
        //         label: 'Home',
        //         icon: 'pi pi-microsoft',
        //         routerLink: ['/'],
        //     }];

        //     array.forEach((item)=>{
        //         this.model.unshift(item);
        //     });
        // }
    }
}
