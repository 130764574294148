import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCapitalizeFirstLetter]'
})
export class CapitalizeFirstLetterDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    if (value) {
      inputElement.value = this.capitalizeWords(value);
      this.updateCursorPosition(inputElement, start, end);
    }
  }

  private capitalizeWords(value: string): string {
    return value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  private updateCursorPosition(inputElement: HTMLInputElement, start: number | null, end: number | null): void {
    // Adjust the cursor position based on the capitalization changes
    if (start !== null && end !== null) {
      const capitalizedBeforeCursor = this.capitalizeWords(inputElement.value.slice(0, start));
      const adjustedStart = capitalizedBeforeCursor.length;
      const capitalizedSelection = this.capitalizeWords(inputElement.value.slice(start, end));
      const adjustedEnd = adjustedStart + capitalizedSelection.length;

      inputElement.setSelectionRange(adjustedStart, adjustedEnd);
    }
  }
}

// import { Directive, ElementRef, HostListener } from '@angular/core';

// @Directive({
//   selector: '[appCapitalizeFirstLetter]'
// })
// export class CapitalizeFirstLetterDirective {

//   private isCapsLockOn: boolean = false;

//   constructor(private el: ElementRef) { }

//   @HostListener('input', ['$event'])
//   onInput(event: Event) {
//     const inputElement = event.target as HTMLInputElement;
//     const value = inputElement.value;
//     const start = inputElement.selectionStart;
//     const end = inputElement.selectionEnd;

//     if (value) {
//       inputElement.value = this.capitalizeFirstAlphabet(value);
//       this.updateCursorPosition(inputElement, start, end);
//     }
//   }

//   @HostListener('keydown', ['$event'])
//   onKeyDown(event: KeyboardEvent) {
//     this.isCapsLockOn = event.getModifierState && event.getModifierState('CapsLock');
//   }

//   private capitalizeFirstAlphabet(value: string): string {
//     if (this.isCapsLockOn) {
//       return value;
//     }

//     let foundAlphabet = false;
//     const capitalized = value.split('').map((char, index) => {
//       if (!foundAlphabet && /[a-zA-Z]/.test(char)) {
//         foundAlphabet = true;
//         return char.toUpperCase();
//       }
//       return char;
//     }).join('');

//     return capitalized;
//   }

//   private updateCursorPosition(inputElement: HTMLInputElement, start: number | null, end: number | null): void {
//     if (start !== null && end !== null) {
//       const capitalizedBeforeCursor = this.capitalizeFirstAlphabet(inputElement.value.slice(0, start));
//       const adjustedStart = capitalizedBeforeCursor.length;
//       const capitalizedSelection = this.capitalizeFirstAlphabet(inputElement.value.slice(start, end));
//       const adjustedEnd = adjustedStart + capitalizedSelection.length;

//       inputElement.setSelectionRange(adjustedStart, adjustedEnd);
//     }
//   }
// }
