import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appFirstLetterCapital]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FirstLetterCapitalDirective),
      multi: true
    }
  ]
})
export class FirstLetterCapitalDirective implements ControlValueAccessor {
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    this.capitalizeAndUpdateValue();
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    setTimeout(() => {
      this.capitalizeAndUpdateValue();
    }, 0);
  }

  writeValue(value: any): void {
    if (typeof value === 'string' && value !== null && value !== undefined) {
      const capitalizedValue = this.capitalizeFirstLetter(value);
      this.renderer.setProperty(this.el.nativeElement, 'value', capitalizedValue);
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  private capitalizeAndUpdateValue(): void {
    const inputValue: string = this.el.nativeElement.value;

    if (inputValue) {
      const capitalizedValue: string = this.capitalizeFirstLetter(inputValue);
      this.renderer.setProperty(this.el.nativeElement, 'value', capitalizedValue);
      this.onChange(capitalizedValue);
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      this.onChange('');
    }
  }

  private capitalizeFirstLetter(value: string): string {
    return typeof value === 'string' && value.length > 0
      ? value.charAt(0).toUpperCase() + value.slice(1)
      : value;
  }
}
