import {Component} from '@angular/core';
import {LayoutService} from "./service/app.layout.service";
import { SchoolDTO } from '../demo/model/SchoolDTO';
import { LookupService } from '../demo/service/lookup.service';
import { HttpResponse } from '@angular/common/http';
import { WebStorage } from '../demo/components/auth/storage/web.storage';
import { LocalOperationService } from '../demo/service/localOperation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  name: string = '';
  userdata: any = {};
  logo: undefined; 
  schooldetails: any = {};
    constructor(public layoutService: LayoutService,
      private lookupService: LookupService,
      private storage: WebStorage,
      private localOperationService:LocalOperationService
    ) {}
    
    ngOnInit() {
      
      this.userdata = this.storage.getUserData();
      this.schooldetails = {
          description: undefined,
          id: undefined,
          name: undefined,
          schoolId: this.userdata.schoolId,
          status: 'A',
          logo: undefined,
      };
      this.queryData();


    // get colorScheme(): string {
    //     return this.layoutService.config.colorScheme;
    // }
    
}
ngAfterViewInit(){
  this.localOperationService.schoolMessage.subscribe(massage=>{
      if (massage==true) {
       this.queryData()   
      }
  })
}
queryData() {
  this.lookupService
      .getSchoolById(this.userdata.schoolId)
      .subscribe(async (res: HttpResponse<SchoolDTO>) => {
          this.schooldetails = res.body ?? {};
          if (res.body) {
              this.fetchSchoolImg(res.body);
          }
          
          const queryParam = {
              'schoolId.equals': this.userdata.schoolId,
              'refTableId.equals': this.userdata.schoolId,
              'refTable.equals': 'School',
          };
         
      });
  }

  fetchSchoolImg(school: SchoolDTO) {
      if (school.logo) {
          this.lookupService
              .queryDocumentByPhotoUid(school.logo)
              .subscribe((response: HttpResponse<Blob>) => {
                  const body = response?.body;
                  if (body) {
                      const url = this.createImgFromBuffer(body);
                      school.logoUrl = url;
                  }
              });
      }
  }

  createImgFromBuffer(blob: Blob) {
      const url = URL.createObjectURL(blob);
      return url;
  }
}
