import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { FamilyDTO } from 'src/app/demo/model/FamilyDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { MasterLookupDTO } from 'src/app/demo/model/MasterLookupDTO';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { TransactionDTO } from 'src/app/demo/model/TransactionDTO';
import { LocalOperationService } from 'src/app/demo/service/localOperation.service';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';

@Component({
    selector: 'app-receive-payment',
    templateUrl: './receive-payment.component.html',
    styleUrls: ['./receive-payment.component.scss'],
})
export class ReceivePaymentComponent implements OnInit {
    invoiceData: DynamicDialogConfig<any>;
    invoiceForm: InvoiceDTO;
    receivePmtFrom!: FormGroup;
    submitForm: boolean = false;
    paymentModeList: MasterLookupDTO[] = [];
    transaction!: TransactionDTO;
    optionalDateControl: boolean = false;
    openBalanceAmount: number = 0;
    selectedStudentList: StudentDTO[] = [];
    multiFamily: FamilyDTO[] = [];
    multiFamilyDataList: any;
    multifamilyChilds!: any;
    // selectedFamily!: FamilyDTO;
    selectedFamily: any = {};
    private familySubscription!: Subscription;
    invoices: any;
    studentId: any;
    studentDetails: any;
    balanceAmount !: number;
    allFamPaymentObj: any;
    viewType: any;
    isMultiFmaily: boolean = false;
    multiFamilyStudentList: StudentDTO[] = [];
    constructor(
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private lookupService: LookupService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private sanitizer: DomSanitizer,
        private operationService: OperationService,
        private datePipe: DatePipe,
        private localOperationService: LocalOperationService
    ) {
        this.invoiceData = this.config;
        this.invoices = this.config.data.invoiceObj;
        this.viewType = this.config.data.view;
        this.balanceAmount = this.invoiceData.data.balance;
        this.allFamPaymentObj = this.invoiceData.data.paymentObj;
        if (this.viewType == 'AllFamily') {
            this.invoices = this.config.data.invoiceObj?.[0];
        }
        else {
            // this.invoices = this.config.data.invoiceObj;
        }
        this.openBalanceAmount = this.invoiceData.data.openBalanceAmount;
        if (this.invoiceData.data.isCreate) {
            this.transaction = {};
        } else {
            this.transaction =
                this.invoiceData.data?.invoiceObj?.transactions[0] || {};
        }

        this.invoiceForm = this.config.data.invoiceObj[0]?.invoice[0] || {};
        this.studentId = this.invoiceForm.studentId;
        if (!this.studentId) {
            this.studentId = this.invoiceData.data.invoiceObj.id;
        }
   

        this.multiFamily = this.invoiceData.data.multiFamilyList;

        this.isMultiFmaily = this.invoiceData.data.isMultiFmaily;
        if (this.isMultiFmaily) {
            this.transaction = this.invoiceData.data?.paymentObj;
        }
    }

    ngOnInit(): void {
        this.queryPaymentMode();
        this.receivePmtFrom = this.formBuilder.group({
            id: undefined,
            transactionType: ['Payment'],
            date: this.convertToTimeZone(
                this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00Z'
            ),
            endDate: undefined,
            amount: [undefined, [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
            receivedAmount: undefined,
            balance: undefined,
            processStatus: ['Success'],
            description: [undefined, [Validators.required]],
            paymentMode: [undefined, [Validators.required]],
            chequeNumber: [undefined],
            note: undefined,
            selectedFamily: undefined,
            isLedgerTransaction: [false],
            isSendEmail: [true],
            refTable: ['Invoice'],
            refTableId: this.invoiceForm.id,
            students: undefined,
            schoolId: this.invoiceForm.schoolId,
            status: ['A'],
        });

        if (this.transaction?.id !== undefined) {
            this.receivePmtFrom.patchValue({
                id: this.transaction.id,
                transactionType: this.transaction.transactionType,
                date: this.convertToTimeZone(
                    this.datePipe.transform(new Date(), 'yyyy-MM-dd') +
                    'T00:00:00Z'
                ),
                endDate: this.transaction.endDate,
                amount: this.transaction.amount,
                receivedAmount: this.transaction.recievedAmount,
                balance: this.transaction.balance,
                processStatus: this.transaction.processStatus,
                description: this.transaction.description,
                paymentMode: this.transaction.paymentMode,
                chequeNumber: this.transaction.chequeNumber,
                note: this.transaction.note,
                isLedgerTransaction: this.transaction.isLedgerTransaction,
                isSendEmail: this.transaction.isSendEmail,
                refTable: this.transaction.refTable,
                refTableId: this.transaction.refTableId,
                students: undefined,
                schoolId: this.transaction.schoolId,
                status: this.transaction.status,
            });

            if (this.receivePmtFrom.get('paymentMode')?.value == 'Cheque') {
                this.optionalDateControl = true;
                this.receivePmtFrom.patchValue({
                    chequeNumber: undefined,
                    description: undefined
                })

            } else {
                this.optionalDateControl = false;
            }
        }

        if (this.config.data.isRefund) {
            this.receivePmtFrom.get('amount')?.disable();

        }

        if (this.transaction?.id == undefined || this.config.data.isRefund) {
            this.receivePmtFrom
                .get('paymentMode')
                ?.valueChanges.subscribe((paymentMode) => {
                    const chequeNumberControl = this.receivePmtFrom.get('chequeNumbe');
                    chequeNumberControl?.clearValidators();
                    if (paymentMode == 'Cheque') {
                        chequeNumberControl?.setValidators([Validators.required]);
                        this.optionalDateControl = true;
                    } else {
                        this.optionalDateControl = false;
                        chequeNumberControl?.reset();
                    }
                    chequeNumberControl?.updateValueAndValidity();
                });
        } else if (!this.config.data.isRefund) {
            this.receivePmtFrom.get('paymentMode')?.disable();
        }
        if (this.isMultiFmaily) {
            this.multiFamilyData();
        }

        this.lookupService
            .getStudentById(this.studentId)
            .subscribe((res: HttpResponse<StudentDTO>) => {
                this.studentDetails = res.body ?? {};
            });

    }
    queryPaymentMode() {
        const statusSearchParams = {
            sort: 'name,asc',
            'type.equals': 'Payment Mode',
        };
        this.lookupService
            .queryMasterLookups(statusSearchParams)
            .subscribe((res: HttpResponse<MasterLookupDTO[]>) => {
                let temp: MasterLookupDTO[] = [];
                this.paymentModeList = res.body ? res.body : temp;
            });
    }
    onSubmit() {
        const isUpdate = this.receivePmtFrom.value.id != undefined;

        const serviceMethod = isUpdate
            ? 'updateTransaction'
            : 'createTransaction';

        if (this.receivePmtFrom.valid) {
            this.submitForm = true;
            let obj: TransactionDTO = this.receivePmtFrom.value;
            obj.paymentMode = this.receivePmtFrom.controls['paymentMode'].status != 'DISABLED' ? this.receivePmtFrom.get('paymentMode')?.value : this.receivePmtFrom.controls['paymentMode'].value;
            let studentIds: any[] = [];
            this.invoiceData.data.invoiceObj.map((each: any) => {
                const keyValue = { id: each.id };
                studentIds.push(keyValue);
            });

            obj.refTableId = this.invoiceForm.id;
            obj.students = [...studentIds];
            obj.familyId = this.receivePmtFrom.get('selectedFamily')?.value;
            this.operationService[serviceMethod](obj).subscribe(
                (res: HttpResponse<TransactionDTO>) => {
                    this.submitForm = false;
                    const message = {
                        severity: 'success',
                        life: 10000,
                        summary: 'Success',
                        detail: `Payment ${isUpdate ? 'Updated' : 'Received'
                            } successfully.`,
                    };
                    this.ref.close(message);
                },
                (error: HttpErrorResponse) => {

                    this.submitForm = false;
                    this.messageService.add({
                        severity: 'error',
                        life: 10000,
                        summary: error.error?.title,
                        detail: error.error?.detail,
                    });
                }
            );
        }
    }

    onMakeRefund() {
        this.submitForm = true;
        let studentIds: any[] = [];
        if (this.isMultiFmaily) {
            this.invoiceData.data?.paymentObj.students.map((each: any) => {
                const keyValue = { id: each.id };
                studentIds.push(keyValue);
            });
        }
        let obj: TransactionDTO = this.receivePmtFrom.value;
        obj.amount = this.receivePmtFrom.controls['amount'].status != 'DISABLED' ? this.receivePmtFrom.get('amount')?.value : this.receivePmtFrom.controls['amount'].value;
        obj.students = this.isMultiFmaily ? studentIds : [{ id: this.invoiceData.data.invoiceObj[0].id }];
        obj.familyId = this.invoiceData.data.paymentObj.familyId;
        obj.processStatus = 'Refund';
        this.operationService.cancelTransaction(obj).subscribe(
            (res: HttpResponse<TransactionDTO>) => {
                this.submitForm = false;
                const message = {
                    severity: 'success',
                    life: 10000,
                    summary: 'Success',
                    detail: `Refund successfully credited.`,
                };
                this.ref.close(message);
            },
            (error) => {
                this.submitForm = false;
                this.messageService.add({
                    severity: 'error',
                    life: 10000,
                    summary: error.error.title,
                    detail: error.error.detail,
                });
            }
        );
    }
    updatePayment() {
        let payobj = this.allFamPaymentObj;
        const transactionId = payobj.id;
        payobj.isRefund = true;


        this.subscribeToSaveResponse(this.operationService.updateTransaction(payobj));
    }
    protected subscribeToSaveResponse(
        result: Observable<HttpResponse<any>>
    ): void {
        result.subscribe(
            () => this.onSaveSuccess(),
            (error: HttpErrorResponse) => this.onSaveError(error)
        );
    }

    protected onSaveSuccess(): void {
        // this.messageService.add({
        //     severity: 'success',
        //     life: 10000,
        //     summary: 'Success',
        //     detail: `Payment Refunded successfully.`,
        // });
    }
    private onSaveError(error: HttpErrorResponse) {

    }
    convertToTimeZone(originalDateString: string): string {
        const currentDate = new Date();
        const currentHours = currentDate.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDate
            .getMinutes()
            .toString()
            .padStart(2, '0');
        const currentSeconds = currentDate
            .getSeconds()
            .toString()
            .padStart(2, '0');

        return (
            this.datePipe.transform(originalDateString, 'yyyy-MM-dd') +
            `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
        );
    }


    multiFamilyData() {
        this.multiFamilyDataList = this.multiFamily;

        if (this.multiFamilyDataList?.length > 0) {
            this.selectedFamily = this.multiFamilyDataList[0];
            this.receivePmtFrom.patchValue({ selectedFamily: this.selectedFamily.id });
            this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': this.selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
                this.invoiceData.data.invoiceObj = res.body ? res.body : [];
                this.multiFamilyStudentList = res.body ?? [];
            });
        }
    }


    onFamilyChange(event: any) {
        const selectedFamilyId = event.value;
        const selectedFamily = this.multiFamilyDataList?.find((each: any) => each.id === selectedFamilyId);
        this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
            this.invoiceData.data.invoiceObj = res.body ? res.body : [];
            this.selectedStudentList = this.invoiceData.data.invoiceObj;
            this.multiFamilyStudentList = res.body ?? [];
        });
    }

    ngAfterContentInit(): void {

        this.familySubscription = this.localOperationService.multiFamilyObj.subscribe((family) => {

            // this.multiFamilyDataList = this.multiFamily;
            if (family != null && Object.keys(family).length > 0) {
                // this.multiFamilyDataList = family;
                this.receivePmtFrom.patchValue({ selectedFamily: family?.id });
                this.selectedFamily = family?.id;
                this.getSelectedFamilyChild(family);

            } else {
                if (this.multiFamilyDataList?.length > 0) {

                    this.selectedFamily = this.multiFamilyDataList[0];
                    this.receivePmtFrom.patchValue({ selectedFamily: this.selectedFamily?.id });
                    this.getSelectedFamilyChild(this.selectedFamily);
                }

            }
        });

    }

    ngOnDestroy(): void {
        if (this.familySubscription) {

            this.localOperationService.multifamilyobj(null);
            this.familySubscription.unsubscribe();
            this.localOperationService.multiFamilyBalance(undefined);
        }
    }




    getSelectedFamilyChild(family: any) {
        if (family?.childIds && family?.childIds.length > 0) {
            this.lookupService.queryStudent({
                size: 30,
                sort: 'fullName,asc',
                page: 0,
                'id.in': family?.childIds
            }).subscribe((res: HttpResponse<StudentDTO[]>) => {
                this.invoiceData.data.invoiceObj = res.body ? res.body : [];
                // this.selectedStudentList = this.invoiceData.data.invoiceObj;

            });
        }
    }


}
