<p-toast></p-toast>
<div class="container-fluid" *ngIf=" invoiceForm;else elseBlock">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="this.viewType == 'AllFamily'">
                <div class="flex mt-1 align-items-center">
                    <img *ngIf="invoiceData.data.invoiceObj.profileimg !=undefined;else noImageFound"
                        [src]="invoiceData.data.invoiceObj.profileimg" width="42" height="42" alt="profile"
                        class="border-circle border-2 border-primary flex-shrink-0" />
                    <ng-template #noImageFound>
                        <a>
                            <img src="assets/layout/images/male.png" alt="Fallbackimage" width="42" height="42"
                                class="border-circle border-2 border-primary flex-shrink-0" />
                        </a>
                    </ng-template>
                    <div class="ml-2">
                        <div class="text-base  font-bold text-900 mb-0">
                            <span class="student-name">{{ this.invoices?.fullName }}</span>
                        </div>
                    </div>
                </div>
            </div>
                <div *ngIf="this.viewType == 'MultiFamily'">
                    <div class="flex mt-1 align-items-center" *ngFor="let student of   this.invoiceData.data.invoiceObj" >

                        <img *ngIf="student.profileimg !=undefined;else noImageFound"
                            [src]="student.profileimg" width="42" height="42" alt="profile"
                            class="border-circle border-2 border-primary flex-shrink-0" />
                        <ng-template #noImageFound>
                            <a>
                                <img src="assets/layout/images/male.png" alt="Fallbackimage" width="42" height="42"
                                    class="border-circle border-2 border-primary flex-shrink-0" />
                            </a>
                        </ng-template>
                        <div class="ml-2">
                            <div class="text-base  font-bold text-900 mb-0">
                                <span class="student-name">{{ student?.fullName }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            
            <div class="flex mt-1 align-items-center">
                <div class="text-base  font-bold text-900 mb-0" *ngIf="!this.invoiceData.data.isRefund">
                    <span class="student-name"> Account Balance : $ {{ balanceAmount || 0}}</span>
                </div>
            </div>
            <div class="border-top-2 border-primary-400 my-2"></div>

            <form [formGroup]="receivePmtFrom">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 p-0 m-0">
                        <div class="col-12" *ngIf="multiFamilyDataList?.length > 0">
                            <label [appAsterisk]="true">Select Family</label>
                            <p-dropdown [options]="multiFamilyDataList" formControlName="selectedFamily"
                                optionLabel="name" optionValue="id" (onChange)="onFamilyChange($event)"
                                placeholder="Select a Family">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="field col-12 md:col-6 mb-0">
                        <label htmlFor="amount" [appAsterisk]="true">Amount</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber id="amount" type="text" formControlName="amount" inputId="minmaxfraction"
                                mode="decimal" [placeholder]="(balanceAmount || 0).toString()" pKeyFilter="money"
                                [minFractionDigits]="2" [maxFractionDigits]="2"> </p-inputNumber>
                        </div>
                        <app-form-field-validation
                            [control]="receivePmtFrom.get('amount')!"></app-form-field-validation>
                    </div>
                    <div class="field col-12 md:col-6 mb-0">
                        <label htmlFor="lastname2" [appAsterisk]="true">Payment Type</label>
                        <p-dropdown formControlName="paymentMode" [options]="paymentModeList" optionValue="value"
                            placeholder="Select payment type" optionLabel="value" appendTo="body">
                        </p-dropdown>
                        <app-form-field-validation
                            [control]="receivePmtFrom.get('paymentMode')!"></app-form-field-validation>
                    </div>
                    <div class="field col-12 mb-0" *ngIf="optionalDateControl">
                        <label htmlFor="lastname2" [appAsterisk]="optionalDateControl">Cheque Number</label>
                        <input pInputText id="note" type="text" formControlName="chequeNumber" />
                        <app-form-field-validation
                            [control]="receivePmtFrom.get('chequeNumber')!"></app-form-field-validation>
                    </div>
                    <div class="field col-12 mb-0">
                        <span class="p-float-label">
                            <textarea id="description" [rows]="1" class="full-width-textarea"
                                formControlName="description" pInputTextarea appCapitalizeFirstLetter></textarea>
                            <label for="float-input" [appAsterisk]="true">Add Optional Internal Note</label>
                        </span>
                        <app-form-field-validation
                            [control]="receivePmtFrom.get('description')!"></app-form-field-validation>
                    </div>
                    <div class=" col-12 mb-0" *ngIf="!this.config.data.isRefund">
                        <input type="checkbox" name="isSendEmail" id="isSendEmail" formControlName="isSendEmail" />
                        &nbsp;
                        <label for="note" class="mb-0">Send Email Notification To Parents On Payment</label>
                    </div>
                    <div class="field col-12 mb-0">
                        <label htmlFor="lastname2">Staff Only Note</label>
                        <input pInputText id="note" type="text" formControlName="note" appCapitalizeFirstLetter />
                    </div>
                </div>
                <div class="row" *ngIf="!this.invoiceData.data.isRefund;else refundBlock">
                    <div class="col">
                        <div class="d-flex justify-content-end">
                            <p-button icon="pi pi-times" label="Cancel" (onClick)="ref.close()"
                                styleClass="p-button-secondary p-button-raised"></p-button>
                            <div class="mx-2"></div>
                            <button pButton icon="pi pi-check" (click)="onSubmit()"
                                [disabled]="!this.receivePmtFrom.valid" [loading]="submitForm" label="Save"
                                class="p-button-success p-button-raised"></button>
                        </div>
                    </div>
                </div>
                <ng-template #refundBlock>
                    <div class="row"></div>
                    <div class="col">
                        <div class="d-flex justify-content-end">
                            <p-button icon="pi pi-times" label="Cancel" (onClick)="ref.close()"
                                styleClass="p-button-secondary p-button-raised"></p-button>
                            <div class="mx-2"></div>
                            <button pButton icon="pi pi-check" (click)="onMakeRefund()" [loading]="submitForm"
                                [disabled]="!this.receivePmtFrom.valid" label="Make Refund"
                                class="p-button-success p-button-raised"></button>
                        </div>
                    </div>
                </ng-template>
            </form>
        </div>

    </div>
</div>
<ng-template #elseBlock>
    <div class="container-fluid">
        <div class="card-form" style="display: flex; align-items: center; justify-content: center;">
            <p-progressSpinner [style]="{width: '45px', height: '37px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".9s"></p-progressSpinner>
        </div>
    </div>
</ng-template>