import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { createRequestOptionAllRecords, createRequestOptionAllRecordsTransaction } from '../utils/request-util';
import { RoomDTO } from '../model/RoomDTO';
import { StaffDTO } from '../model/StaffDTO';
import { StudentDTO } from '../model/StudentDTO';
import { GuardianDTO } from '../model/GuardianDTO';
import { FormValidatorDTO } from '../model/FormValidatorDTO';
import { AuthorizedPickupDTO } from '../model/AuthorizedPickupDTO';
import { TagDTO } from '../model/TagDTO';
import { AttendanceDTO } from '../model/AttendanceDTO';
import { AddressDTO } from '../model/AddressDTO';
import { PhysiciansDTO } from '../model/PhysiciansDTO';
import { DocumentDTO } from '../model/DocumentDTO';
import { AbsentDTO } from '../model/AbsentDTO';
import { VaccineDTO } from '../model/VaccineDTO';
import { VaccineDoseDTO } from '../model/VaccineDoseDTO';
import { ScheduleDTO } from '../model/ScheduleDTO';
import { ImmunizationDTO } from '../model/ImmunizationDTO';
import { ScheduleDoseDTO } from '../model/ScheduleDoseDTO';
import { RoleDTO } from '../model/RoleDTO';
import { PermissionDTO } from '../model/PermissionDTO';
import { UserDTO } from '../model/UserDTO';
import { ActivityTypeDTO } from '../model/ActivityTypeDTO';
import { NewsletterDTO } from '../model/NewsletterDTO';
import { MessageDTO } from '../model/MessageDTO';
import { EventDTO } from '../model/EventDTO';
import { ActivityDTO } from '../model/ActivityDTO';
import { MasterLookupDTO } from '../model/MasterLookupDTO';
import { TimeslotDTO } from '../model/TimeslotDTO';
import { StaffShiftsDTO } from '../model/StaffShiftsDTO';
import { ProgramDTO } from '../model/ProgramDTO';
import { LedgerAccountDTO } from '../model/LedgerAccountDTO';
import { DocumentDayDTO } from '../model/DocumentDayDTO';
import { BillingItemSettingsDTO } from '../model/BillingItemSettingsDTO';
import { PhysicalTrackerDTO } from '../model/PhysicalTrackerDTO';
import { TemplateDTO } from '../model/TemplateDTO';
import { TemplateComponentDTO } from '../model/TemplateComponentDTO';
import { InvoiceDetailsDTO } from '../model/InvoiceDetailsDTO';
import { StaffScheduleDTO } from '../model/StaffScheduleDTO';
import { AllInvoiceCount } from '../model/AllInvoiceCount';
import { SchoolDTO } from '../model/SchoolDTO';
import { DashboardSchoolDTO } from '../model/DashboardSchoolDTO';
import { DashboarStaffdDTO } from '../model/StaffDashboardDTO';
import { RecurringPlansDTO } from '../model/RecurringPlansDTO';
import { ActivityFeedDTO } from '../model/ActivityFeedDTO';
import { environment } from 'src/environments/environment';
import { FamilyDTO } from '../model/FamilyDTO';
import { RoomMoveDTO } from '../model/RoomMoveDTO';
import { FutureEnrollmentDTO } from '../model/FutureEnrollmentDTO';

@Injectable({
    providedIn: 'root',
})
export class LookupService {

    private SERVER_API_URL = environment.serviceUrl;

    checkParameterType(req: any) {
        return createRequestOptionAllRecords(req);
    }
    checkTransactionType(req: any) {
        return createRequestOptionAllRecordsTransaction(req);
    }

    constructor(private http: HttpClient) { }

    queryAccount(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/account", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryAccountCount(req?: any): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.SERVER_API_URL + "/user/count", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryFormValidator(req?: any): Observable<HttpResponse<FormValidatorDTO[]>> {
        return this.http.get<FormValidatorDTO[]>(this.SERVER_API_URL + '/form-validators', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryStudent(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + '/students', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryPresentStudent(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + '/attendance/students', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    getStudentById(id: number): Observable<HttpResponse<StudentDTO>> {
        return this.http.get<StudentDTO>(this.SERVER_API_URL + '/students/' + id, {
            observe: 'response',
        });
    }
    getAttendanceById(id: number): Observable<HttpResponse<AttendanceDTO>> {
        return this.http.get<AttendanceDTO>(this.SERVER_API_URL + '/attendances/' + id, {
            observe: 'response',
        });
    }
    getStaffById(id: number): Observable<HttpResponse<StaffDTO>> {
        return this.http.get<StaffDTO>(this.SERVER_API_URL + '/staff/' + id, {
            observe: 'response',
        });
    }
    getGuardianById(id: number): Observable<HttpResponse<GuardianDTO>> {
        return this.http.get<GuardianDTO>(this.SERVER_API_URL + '/guardians/' + id, {
            observe: 'response',
        });
    }
    getNewsletterById(id: number): Observable<HttpResponse<NewsletterDTO>> {
        return this.http.get<NewsletterDTO>(this.SERVER_API_URL + '/newsletters/' + id, {
            observe: 'response',
        });
    }

    // getNewsletterWithRoomTag(id: number): Observable<HttpResponse<NewsletterDTO>> {
    //     return this.http.get<NewsletterDTO>(this.SERVER_API_URL + '/newsletters-with-room-tag-ids/' + id, {
    //         observe: 'response',
    //     });
    // }

    getActivityById(id: number): Observable<HttpResponse<ActivityDTO>> {
        return this.http.get<ActivityDTO>(this.SERVER_API_URL + '/activities/' + id, {
            observe: 'response',
        });
    }
    getProgramById(id: number): Observable<HttpResponse<ProgramDTO>> {
        return this.http.get<ProgramDTO>(this.SERVER_API_URL + '/programs/' + id, {
            observe: 'response',
        });
    }
    getLedgerAccountById(id: number): Observable<HttpResponse<LedgerAccountDTO>> {
        return this.http.get<LedgerAccountDTO>(this.SERVER_API_URL + '/ledger-accounts/' + id, {
            observe: 'response',
        });
    }
    getSchoolById(id: number): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.SERVER_API_URL + '/schools/' + id, {
            observe: 'response',
        });
    }
    queryRooms(req?: any): Observable<HttpResponse<RoomDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/rooms', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    getRoomById(id: number): Observable<HttpResponse<RoomDTO>> {
        return this.http.get<RoomDTO>(this.SERVER_API_URL + '/rooms/' + id, {
            observe: 'response',
        });
    }
    queryStaff(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/staff', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryStaffActivity(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/staff-present', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryGuardian(req?: any): Observable<HttpResponse<GuardianDTO[]>> {
        return this.http.get<GuardianDTO[]>(this.SERVER_API_URL + '/guardians', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    getQueryMessage(req?: any): Observable<HttpResponse<MessageDTO[]>> {
        return this.http.get<MessageDTO[]>(this.SERVER_API_URL + '/messages', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryAuthorizedPickup(req?: any): Observable<HttpResponse<AuthorizedPickupDTO[]>> {
        return this.http.get<AuthorizedPickupDTO[]>(this.SERVER_API_URL + '/authorized-pickups', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryPhysician(req?: any): Observable<HttpResponse<PhysiciansDTO[]>> {
        return this.http.get<PhysiciansDTO[]>(this.SERVER_API_URL + '/physicians', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }


    queryTag(req?: any): Observable<HttpResponse<TagDTO[]>> {
        return this.http.get<TagDTO[]>(this.SERVER_API_URL + '/tags', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryAttendance(req?: any): Observable<HttpResponse<AttendanceDTO[]>> {
        return this.http.get<AttendanceDTO[]>(this.SERVER_API_URL + '/attendances', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryAttendanceForStaffOnly(staffId: number,req?:any): Observable<HttpResponse<AttendanceDTO[]>> {
        return this.http.get<AttendanceDTO[]>(this.SERVER_API_URL + '/attendance/marked-by-staff/' + staffId, {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryAddress(req?: any): Observable<HttpResponse<AddressDTO[]>> {
        return this.http.get<AddressDTO[]>(this.SERVER_API_URL + '/addresses', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryAddressById(id: any) {
        return this.http.get<AddressDTO>(this.SERVER_API_URL + '/addresses/' + id, {
            observe: 'response',
        })
    }

    getStudentRelation(req?: any): Observable<HttpResponse<EventDTO[]>> {
        return this.http.get<EventDTO[]>(this.SERVER_API_URL + '/events', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryTotalAttendance(req?: any): Observable<HttpResponse<AttendanceDTO[]>> {
        return this.http.get<AttendanceDTO[]>(this.SERVER_API_URL + '/attendances/total-attended-hours', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryDocuments(req?: any): Observable<HttpResponse<DocumentDTO[]>> {
        return this.http.get<DocumentDTO[]>(this.SERVER_API_URL + '/documents', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryDocumentsByDay(req?: any): Observable<HttpResponse<DocumentDayDTO[]>> {
        return this.http.get<DocumentDayDTO[]>(this.SERVER_API_URL + '/documents-download/day', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryDocumentByFileUid(req?: any): Observable<HttpResponse<Blob>> {
        return this.http.get(this.SERVER_API_URL + '/documents-download', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryDocumentByPhotoUid(req?: any): Observable<HttpResponse<Blob>> {
        return this.http.get(this.SERVER_API_URL + '/documents-download/' + req,  {
            observe: 'response',
            responseType: 'blob'
        });
    }


    queryAllDocuments(req?: any): Observable<Blob> {
        const params = req ? { params: req } : {};
        return this.http.get(this.SERVER_API_URL + "/documents-download", {
            responseType: 'blob',
            ...params,
        }).pipe(
            map((res: Blob) => {
                return res;
            })
        );
    }
    queryLogoDocuments(req?: any): Observable<Blob> {
        const params = req ? { params: req } : {};
        return this.http.get(this.SERVER_API_URL + "/documents-upload-logo", {
            responseType: 'blob',
            ...params,
        }).pipe(
            map((res: Blob) => {
                return res;
            })
        );
    }

    // new photo id method--------------------------------------------------------------
    queryDocumentByPhotoUUid(req?: any): Observable<HttpResponse<Blob>> {
        // const params = req ? { params: req } : {};
        return this.http.get(this.SERVER_API_URL + "/documents-download/" + req, {
            responseType: 'blob',
            params: req,
            observe: 'response', // Specify to observe the full response
        }).pipe(
            map((res: HttpResponse<Blob>) => {
                return res;
            })
        );
    }

    queryAbsents(req?: any): Observable<HttpResponse<AbsentDTO[]>> {
        return this.http.get<AbsentDTO[]>(this.SERVER_API_URL + '/absents-all', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryStudentStaffAbsents(req?: any): Observable<HttpResponse<AbsentDTO[]>> {
        return this.http.get<AbsentDTO[]>(this.SERVER_API_URL + '/absents', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryAbsentsDashboard(req?: any): Observable<HttpResponse<AbsentDTO[]>> {
        return this.http.get<AbsentDTO[]>(this.SERVER_API_URL + '/absents-all', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryStudentAbsent(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + '/students-absent', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryVaccine(req?: any): Observable<HttpResponse<VaccineDTO[]>> {
        return this.http.get<VaccineDTO[]>(this.SERVER_API_URL + '/vaccines', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryVaccineDose(req?: any): Observable<HttpResponse<VaccineDoseDTO[]>> {
        return this.http.get<VaccineDoseDTO[]>(this.SERVER_API_URL + '/vaccine-doses', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    querySchedules(req?: any): Observable<HttpResponse<ScheduleDTO[]>> {
        return this.http.get<ScheduleDTO[]>(this.SERVER_API_URL + '/schedules', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryStaffAbsent(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<StaffDTO[]>(this.SERVER_API_URL + '/absent-staff', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryAbsentStaff(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<StaffDTO[]>(this.SERVER_API_URL + '/not-present-staffs', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryImmunizations(req?: any): Observable<HttpResponse<ImmunizationDTO[]>> {
        return this.http.get<ImmunizationDTO[]>(this.SERVER_API_URL + '/immunizations', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryScheduleDoses(req?: any): Observable<HttpResponse<ScheduleDoseDTO[]>> {
        return this.http.get<ScheduleDoseDTO[]>(this.SERVER_API_URL + '/schedule-doses', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryTechVgRoles(req?: any): Observable<HttpResponse<RoleDTO[]>> {
        return this.http.get<RoleDTO[]>(this.SERVER_API_URL + '/techvg-roles', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryTechVgPermissions(req?: any): Observable<HttpResponse<PermissionDTO[]>> {
        return this.http.get<PermissionDTO[]>(this.SERVER_API_URL + '/techvg-permissions', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryUserList(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/list/user-details", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryActivityTypeList(req?: any): Observable<HttpResponse<ActivityTypeDTO[]>> {
        return this.http.get<ActivityTypeDTO[]>(this.SERVER_API_URL + "/activity-types", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryActivityTypeById(id: number) {
        return this.http.get<ActivityTypeDTO>(this.SERVER_API_URL + '/activity-types/' + id, {
            observe: 'response',
        })
    }

    // queryNewsletter(req?: any): Observable<HttpResponse<any[]>> {
    //     return this.http.get<any[]>(this.SERVER_API_URL + "/newsletters", {
    //         params: this.checkParameterType(req),
    //         observe: "response",
    //     });
    // }
    queryNewsletter(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/newsletters-with-room-tag-ids", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }


    queryEvent(req?: any): Observable<HttpResponse<EventDTO[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/events", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryUserRole(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/user", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryActivities(req?: any): Observable<HttpResponse<ActivityDTO[]>> {
        return this.http.get<ActivityDTO[]>(this.SERVER_API_URL + "/activities", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryActivitiesList(req?: any): Observable<HttpResponse<ActivityDTO[]>> {
        const params = req ? { params: req } : {};
        return this.http.get<ActivityDTO[]>(this.SERVER_API_URL + "/activity-feed/list", {
            observe: "response",
            ...params
        });
    }

    queryMasterLookups(req?: any): Observable<HttpResponse<MasterLookupDTO[]>> {
        return this.http.get<MasterLookupDTO[]>(this.SERVER_API_URL + "/master-lookups", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryStudentActivities(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/student-activities", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryActivityDocuments(req?: any): Observable<Blob> {
        return this.http.get(this.SERVER_API_URL + "/documents-download/day", {
            responseType: 'blob',
            params: this.checkParameterType(req),
        }).pipe(
            map((res: Blob) => {
                return res;
            })
        );
    }
    queryActDocuments(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + '/documents-download/day', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryTimeslot(req?: any): Observable<HttpResponse<TimeslotDTO[]>> {
        return this.http.get<TimeslotDTO[]>(this.SERVER_API_URL + '/time-slots', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryStaffShift(req?: any): Observable<HttpResponse<StaffShiftsDTO[]>> {
        return this.http.get<StaffShiftsDTO[]>(this.SERVER_API_URL + '/staff-shifts', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryStaffSchedule(req?: any): Observable<HttpResponse<StaffScheduleDTO[]>> {
        return this.http.get<StaffScheduleDTO[]>(this.SERVER_API_URL + '/staff-schedules', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
}

    queryStaffReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/staff-data', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryPrograms(req?: any): Observable<HttpResponse<ProgramDTO[]>> {
        return this.http.get<ProgramDTO[]>(this.SERVER_API_URL + "/programs", {
            params: this.checkParameterType(req),
            observe: "response",
        });

    }

    queryStudentAttendanceReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/student-attendence-reports', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryStudentDailyAttendanceReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/student-daily-attendance', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryStudentReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/student-data', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryImmunizationReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/immunization', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryStaffAttendanceReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/staff-attendence-reports', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryStaffEmergencyCardReport(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/staff-emergency-roll-call', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryStudentEmergencyCardReport(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/parents-emergency-roll-call', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryAbsentStudentsReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/student-absence', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryAbsentStaffsReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/staff-absence', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryStaffsScheduleReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/staff-schedule', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryStudentStateImmunizationReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/vaccine-compliance', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryStudentPrograms(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/student-program", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryStudentProgramsssss(
        req?: any,
        params?: any
    ): Observable<HttpResponse<any[]>> {
        let queryParams = this.checkParameterType(req);
        if (params) {
            // Append additional parameters to the queryParams object
            Object.keys(params).forEach((key) => {
                queryParams = queryParams.append(key, params[key]);
            });
        }

        return this.http.get<any[]>(this.SERVER_API_URL + '/student-program', {
            params: queryParams,
            observe: 'response',
        });
    }

    queryLedgerAccount(req?: any): Observable<HttpResponse<LedgerAccountDTO[]>> {
        return this.http.get<LedgerAccountDTO[]>(this.SERVER_API_URL + "/ledger-accounts", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryStaffPrograms(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/staff-program", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryAllTransaction(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/transactions", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryAllTransactionWithIds(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/transactions/transactions-with-ids", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryAllStudentTransaction(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + "/transactions/subsidy-transactions/all-students", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryBillingItemSetting(req?: any): Observable<HttpResponse<BillingItemSettingsDTO[]>> {
        return this.http.get<BillingItemSettingsDTO[]>(this.SERVER_API_URL + "/billing-item-settings", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryPhysicalTrackerDate(req: any): Observable<HttpResponse<PhysicalTrackerDTO[]>> {
        return this.http.get<PhysicalTrackerDTO[]>(this.SERVER_API_URL + '/physical-trackers', {
            params: this.checkParameterType(req),
            observe: 'response',
        })
    }
    querySinglePhysicianNyId(id: number) {
        return this.http.get<PhysiciansDTO>(this.SERVER_API_URL + '/physicians/' + id, {
            observe: 'response',
        })
    }
    queryAllInvoice(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + "/invoices/all-students-invoices", {
            params: this.checkTransactionType(req),
            observe: "response",
        });
    }
    queryAllInvoiceCount(req?: any): Observable<HttpResponse<AllInvoiceCount>> {
        return this.http.get<AllInvoiceCount>(this.SERVER_API_URL + "/transactions/total-transactions", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryInvoiceDetails(req?: any): Observable<HttpResponse<InvoiceDetailsDTO[]>> {
        return this.http.get<InvoiceDetailsDTO[]>(this.SERVER_API_URL + "/invoice-details", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryInvoiceDetailsById(id: number) {
        return this.http.get<InvoiceDetailsDTO>(this.SERVER_API_URL + '/invoice-details/' + id, {
            observe: 'response',
        })
    }
    queryInvoiceById(id: number) {
        return this.http.get<PhysiciansDTO>(this.SERVER_API_URL + '/invoices/' + id, {
            observe: 'response',
        })
    }
    queryTemplate(req?: any): Observable<HttpResponse<TemplateDTO[]>> {
        return this.http.get<TemplateDTO[]>(this.SERVER_API_URL + "/templates", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }

    queryTemplateComponent(req?: any): Observable<HttpResponse<TemplateComponentDTO[]>> {
        return this.http.get<TemplateComponentDTO[]>(this.SERVER_API_URL + "/template-components", {
            params: this.checkParameterType(req),
            observe: "response",
        });
    }
    queryRoomDataCount(req: any): Observable<HttpResponse<RoomDTO[]>> {
        return this.http.get<RoomDTO[]>(this.SERVER_API_URL + '/rooms-data', {
            params: this.checkParameterType(req),
            observe: "response",
        })
    }
    queryDashboardRoomCount(req: any): Observable<HttpResponse<RoomDTO[]>> {
        return this.http.get<RoomDTO[]>(this.SERVER_API_URL + '/dashboard/count-rooms', {
            params: this.checkParameterType(req),
            observe: "response",
        })
    }
    querySchoolProfile(req: any): Observable<HttpResponse<SchoolDTO[]>> {
        return this.http.get<SchoolDTO[]>(this.SERVER_API_URL + '/schools', {
            params: this.checkParameterType(req),
            observe: "response",
        })
    }
    queryAllTransactionsReport(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/all-transactions',{
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryTransactionsReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/Transactions-report', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryFamilyBalenceReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/family-balance', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryAdminDashboard(schoolId : any) :Observable <HttpResponse<DashboardSchoolDTO>>{
        return this.http.get(this.SERVER_API_URL + '/schools/school-data/'+schoolId,{
            observe: 'response',
        });
    }
    queryStaffDashboard(staffId : any) :Observable <HttpResponse<DashboarStaffdDTO>>{
        return this.http.get(this.SERVER_API_URL + '/schools/school-data-staff/'+staffId,{
            observe: 'response',
        });
    }
    queryRecurringPlans(req?: any): Observable<HttpResponse<RecurringPlansDTO[]>> {
        return this.http.get<RecurringPlansDTO[]>(this.SERVER_API_URL + '/recurring-plans', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryOtherStaffs(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/others-all', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryExpectedStaff(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/expected-staff', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryOtherStudents(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/others-all-students', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryExpectedStudents(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/expected-students', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryActivityFeed(req?: any): Observable<HttpResponse<ActivityFeedDTO[]>> {
        return this.http.get<ActivityFeedDTO[]>(this.SERVER_API_URL + '/activities-feed', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryMultipleRecurringPlans(req?: any, isTemplate?: boolean): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + `/recurring-plans/students-multiple-recurring-plans?isTemplate=${isTemplate }`, {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryRecurringPlanStudents(req?: any, isplanAssign?: boolean): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + `/recurring-plans/student-list?isplanAssign=${isplanAssign}`, {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryRecurringPlanMultifamilyStudents(req?: any, isplanAssign?: boolean): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + `/recurring-plans/custom-families-list?isplanAssign=${isplanAssign}`, {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    queryCreateInvoiceMultifamilyStudents(req?: any, isplanAssign?: boolean): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + `/invoices/families-list?isplanAssign=${isplanAssign}`, {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
    getFamilyById(studentId?:number):Observable<HttpResponse<FamilyDTO[]>> {
        return this.http.get<FamilyDTO[]>(this.SERVER_API_URL + `/families-details/${studentId}`, {
            observe: 'response',
        });
    }

    queryRoomMove(req?: any): Observable<HttpResponse<RoomMoveDTO[]>> {
        return this.http.get<RoomMoveDTO[]>(this.SERVER_API_URL + '/room-moves', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryFutureEnrollment(req?: any): Observable<HttpResponse<FutureEnrollmentDTO[]>> {
        return this.http.get<FutureEnrollmentDTO[]>(this.SERVER_API_URL + '/future-enrollments', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryFutureEnrollmentAvailability(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.SERVER_API_URL + '/future-enrollments/available-count', {
            params: req,
            observe: 'response',
        });
    }

    queryBillingReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/billing-plan', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryParentDashboardCount(req: any): Observable<HttpResponse<RoomDTO[]>> {
        return this.http.get<RoomDTO[]>(this.SERVER_API_URL + '/parent-dashboard/count', {
            params: this.checkParameterType(req),
            observe: "response",
        })
    }

    queryInvoiceReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/invoice-receipt', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }
    queryPaymentReports(req?: any): Observable<HttpResponse<any>> {
        return this.http.get(this.SERVER_API_URL + '/reports/payment-receipt', {
            params: this.checkParameterType(req),
            observe: 'response',
            responseType: 'blob'
        });
    }

    queryNotMultiFamilyStudents(req?: any): Observable<HttpResponse<StudentDTO[]>> {
        return this.http.get<StudentDTO[]>(this.SERVER_API_URL + '/invoices/students-list', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }

    queryWithoutScheduleStaff(req?: any): Observable<HttpResponse<StaffDTO[]>> {
        return this.http.get<[]>(this.SERVER_API_URL + '/staff-without-schedule', {
            params: this.checkParameterType(req),
            observe: 'response',
        });
    }
}
