import { HttpParams } from "@angular/common/http";

export const createRequestOptionAllRecords = (req: any): HttpParams => {  
    let options: HttpParams = new HttpParams();    
    if (req) {
      Object.keys(req).forEach((key) => {
        options = options.set(key, req[key]);
      });
      if (!req.sort) {
        options = options.set("sort", "id,desc");
      }
      if (req.page === undefined || req['fullName.contains'] || req['name.contains'] || req['firstName.contains'] || req['studentId.equals'] || req['staffId.equals'] || req['refTable.contains']) {                
        options = options.set("page", "0");
      }
      if (req.size === undefined) {
        options = options.set("size", "100");
      }
    } else {
      options = options.set("sort", "id,desc");
      options = options.set("page", "0");
      options = options.set("size", "100");
    }
    return options;
  };

  export const createRequestOptionAllRecordsTransaction = (req: any): HttpParams => {  
    let options: HttpParams = new HttpParams();    
    if (req) {
      Object.keys(req).forEach((key) => {
        options = options.set(key, req[key]);
      });
      // if (!req.sort) {
      //   options = options.set("sort", "id,desc");
      // }
      if (req.page === undefined || req['fullName.contains'] || req['name.contains'] || req['firstName.contains'] || req['studentId.equals'] || req['staffId.equals'] || req['refTable.contains']) {                
        options = options.set("page", "0");
      }
      if (req.size === undefined) {
        options = options.set("size", "100");
      }
    } else {
      // options = options.set("sort", "id,desc");
      options = options.set("page", "0");
      options = options.set("size", "100");
    }
    return options;
  };
