import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class WebStorage {
    constructor(private router: Router) {}

    // login(userProfile: any, account: any) {
    //     localStorage.setItem('sso', JSON.stringify(userProfile));
    //     localStorage.setItem('user-profile', JSON.stringify(account));
    // }

    login(account: any) {
        localStorage.setItem("user-profile", JSON.stringify(account));
    }
    // logout() {
    //     localStorage.removeItem("sso");
    //     localStorage.removeItem("user-profile");
    //     localStorage.clear();
    // }


    logout() {
        this.removeLocalStorageItem("user-profile")
          .then(() => {
            localStorage.clear();
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
      }
    
    
      private async removeLocalStorageItem(key: string): Promise<void> {
        try {
          localStorage.removeItem(key);
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }

      
    getUserData(): any {
        let data = localStorage.getItem('user-profile');
        if (data !== null) {
            return JSON.parse(data);
        }
        return null;
    }


    // getUserData(): any {
    //     let data = localStorage.getItem("user-profile");
    //     if(data != null){
    //         return JSON.parse(data);
    //     }else{
    //         window.location.reload();
    //     }
    // }


    getRoles() {
        return this.getUserData()?.authorities;
    }

    getUsername() {
        return this.getUserData()?.login;
    }

    getName() {
        return (
            this.getUserData()?.firstName + '' + this.getUserData()?.lastName
        );
    }

    getUserId() {
        return this.getUserData()?.id;
    }

    getCompanyId() {
        return this.getUserData()?.schoolId;
    }

    getEmployeeId() {
        return this.getUserData()?.refTableId;
    }

    hasAnyAuthority(requestedAuthorities: string[]): boolean {
        let user: any = this.getUserData();
        if (!user.authorities) {
            return false;
        }
        for (const item of requestedAuthorities) {
            if (user.authorities.includes(item)) {
                return true;
            }
        }
        return false;
    }
}
