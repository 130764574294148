import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appRatioValidation]',
 
})
export class RatioValidationDirective  {
 
  constructor() { }

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement) {
    const ratio = target.value;
    // Check if the condition is met
    if (ratio === '0' || parseInt(ratio, 10) < 0 || !/^\d{1,2}$/.test(ratio)) {
      target.value = ''; // Clear the input value
    }
  }

   
}
