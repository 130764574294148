import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { BillingItemSettingsDTO } from 'src/app/demo/model/BillingItemSettingsDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';

@Component({
    selector: 'app-bill-item-setting',
    templateUrl: './bill-item-setting.component.html',
    styleUrls: ['./bill-item-setting.component.scss'],
})
export class BillItemSettingComponent implements OnInit {
    presetItemList: BillingItemSettingsDTO[] = [];
    discountItemList: BillingItemSettingsDTO[] = [];
    settingData: any;
    isAddClicked:boolean=false;
    constructor(
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private operationService: OperationService,
        private lookupService: LookupService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {
        this.settingData = this.config;
    }

    ngOnInit() {
        
        if (this.settingData.header === 'Manage Presets') {
            this.queryData('Manage Presets');
        } else {
            this.queryData('Manage Discount');
        }
        this.presetItemList = [];
        this.discountItemList = [];
    }

    queryData(header: string) {
        this.lookupService
            .queryBillingItemSetting({
                'schoolId.equals': 1,
                'isPresetItem.equals':
                    header == 'Manage Presets' ? true : false,
                'status.equals' : 'A'
            })
            .subscribe((res: HttpResponse<BillingItemSettingsDTO[]>) => {
                this.presetItemList = res.body ?? [];
            });
    }

    onRowEditInit() {}

    onRowEditSave(item: any) {

        this.isAddClicked = false;
        const isUpdate = item.id !== undefined;
        const serviceMethod = isUpdate
            ? 'updateBillItemSetting'
            : 'createBillItemSetting';
        
        item.schoolId = 1;
        item.status = 'A';
        if(this.settingData.header === 'Manage Presets'){
            item.isPresetItem = true;
            item.isDiscount = false;
            if (item.name && item.rate) {
                this.operationService[serviceMethod](item).subscribe(
                    (res: HttpResponse<BillingItemSettingsDTO>) => {
                        this.messageService.add({
                            key: 'item',
                            severity: 'success', 
                            summary: 'Success',
                            detail: `Preset ${
                                isUpdate ? 'Updated' : 'Created'
                            } successfully.`,
                            life: 10000
                        });
                        this.queryData('Manage Presets');
                    },
                    (error) => {
                        this.messageService.add({
                            severity: 'error', 
                            life: 10000,
                            summary: error.error.title,
                            detail: error.error.detail,
                        });
                    }
                );
            }else{
                this.messageService.add({
                    key: 'item',
                    severity: 'warn',
                    summary: 'Warning',
                    detail: `Please fill all fields.`,
                    life: 10000,
                });
            }
        }else{
            if (item.name) {
                item.isPresetItem = false;
                item.isDiscount = true;
                this.operationService[serviceMethod](item).subscribe(
                    (res: HttpResponse<BillingItemSettingsDTO>) => {
                        this.messageService.add({
                            key: 'item',
                            severity: 'success', 
                            summary: 'Success',
                            detail: `Discount ${
                                isUpdate ? 'Updated' : 'Created'
                            } successfully.`,
                            life: 10000
                        });
                        this.queryData('Manage Discount');
                    },
                    (error) => {
                        this.messageService.add({
                            severity: 'error', 
                            life: 10000,
                            summary: error.error.title,
                            detail: error.error.detail,
                        });
                    }
                );
            }else{
                this.messageService.add({
                    key: 'item',
                    severity: 'warn', 
                    summary: 'Warning',
                    detail: `Please fill all fields.`,
                    life: 10000,
                });
            }
        }
       
    }
    onRowEditCancel(item: BillingItemSettingsDTO, index: number){
        this.isAddClicked = false;        
        if(item.id != undefined){
            this.queryData(`Manage ${item?.isPresetItem  == true ? 'Presets' : 'Discount'}`);
        }else{
            this.presetItemList.splice(index,1);
        }
    }
    onRowEditDelete(item: BillingItemSettingsDTO, index: number,event :Event) {
        this.isAddClicked = false;
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: ` Do you want to delete this ${this.settingData.header === 'Manage Presets' ? 'Preset' : 'Discount'} Item?`,
            header: `Delete ${this.settingData.header === 'Manage Presets' ? 'Preset' : 'Discount'} Item`,
            acceptLabel: 'Yes, Delete',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            acceptButtonStyleClass:'p-button-danger p-button-raised',
            rejectButtonStyleClass: 'p-button-secondary p-button-raised',
            defaultFocus: 'none',
            accept: () => this.deleteDose(item,index),
            reject: () => {},
        });

    }

    deleteDose(item:any,index:number){
        if(item.id != undefined){
            item.status = 'I';
            this.operationService.updateBillItemSetting(item).subscribe((res : HttpResponse<BillingItemSettingsDTO>)=>{
              this.messageService.add({
                key :'item',
                severity: 'success',
                summary: 'Success',
                detail: `${res.body?.isPresetItem  == true ? 'Preset' : 'Discount'} Deleted successfully.`,
                life: 10000,
            });
            this.queryData(`Manage ${res.body?.isPresetItem  == true ? 'Presets' : 'Discount'}`);
            },
            (error) => {
                this.messageService.add({
                    severity: 'error', 
                    life: 10000,
                    summary: error.error.title,
                    detail: error.error.detail,
                });
            })
    
          }else{
            this.presetItemList.splice(index,1);
          }
    }
    addPreset() {
        let obj: any = {};
        this.presetItemList.unshift(obj);
    }

   
    
    addDiscount() {
        let obj: any = {};
        this.presetItemList.unshift(obj);
    }
}
1;
