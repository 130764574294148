import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberParenthesesHyphenOnly]'
})
export class NumberParenthesesHyphenOnlyDirective {

  // private regex: RegExp = new RegExp(/^[0-9()-]*$/);

  // @HostListener('input', ['$event'])
  // onInput(event: KeyboardEvent) {
  //   const input = event.target as HTMLInputElement;
  //   const inputValue = input.value;
  //   if (!this.regex.test(inputValue)) {
  //     input.value = inputValue.slice(0, inputValue.length - 1);
  //   }
  // }

  private regex: RegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value.replace(/[^\d]/g, ''); // Remove non-digits

    if (inputValue.length === 1) {
      input.value = `(${inputValue}`;
    } else if (inputValue.length > 1 && inputValue.length <= 3) {
      input.value = `(${inputValue}`;
    } else if (inputValue.length <= 6) {
      input.value = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
    } else if (inputValue.length <= 10) {
      input.value = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6)}`;
    } else {
      // Truncate input if it exceeds the expected length
      inputValue = inputValue.slice(0, 10);
      input.value = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6)}`;
    }
  
    if (inputValue === '' || input.value === '') {
      // Clear formatting characters when the input is empty
      input.value = '';
    }
  }

  private formatInput(value: string): string {
    const match = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  }
}