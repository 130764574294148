import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Common } from '../../utils/common';

@Pipe({
  name: 'timeFormatTimer'
})
export class TimeFormatTimerPipe implements PipeTransform {
  transform(value: string, format: 'hr' | 'mm' | 'ss' | 'ampm'): string {
    if (!value) {
      return '';
    }
    const date = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    if (Common.timeZone) {
      date.tz(Common.timeZone);
    }
    let hours = date.hours();
    const minutes = date.minutes();
    const seconds = date.seconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    if (format === 'hr' || format === 'ampm') {
      hours = hours % 12;
      hours = hours ? hours : 12;
    }

    const strHours = hours < 10 ? '0' + hours : hours.toString();
    const strMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const strSeconds = seconds < 10 ? '0' + seconds : seconds.toString();

    switch (format) {
      case 'hr':
        return strHours;
      case 'mm':
        return strMinutes;
      case 'ss':
        return strSeconds;
      case 'ampm':
        return ampm;
      default:
        return '';
    }
  }
}
