import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { BillItemSettingComponent } from 'src/app/demo/components/billing/accounting/family/bill-item-setting/bill-item-setting.component';
import { BillingItemSettingsDTO } from 'src/app/demo/model/BillingItemSettingsDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { InvoiceDetailsDTO } from 'src/app/demo/model/InvoiceDetailsDTO';
import { LedgerAccountDTO } from 'src/app/demo/model/LedgerAccountDTO';
import { RoomDTO } from 'src/app/demo/model/RoomDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';

@Component({
    selector: 'app-edit-invoice',
    templateUrl: './edit-invoice.component.html',
    styleUrls: ['./edit-invoice.component.scss'],
})
export class EditInvoiceComponent implements OnInit {
    invoiceData: DynamicDialogConfig<any>;
    selectedStudentList: any;
    invoiceForm!: FormGroup;
    invoiceItemForm!: FormGroup;
    optionalDateControl: boolean = false;
    submitButton: boolean = true;
    presetItemList: BillingItemSettingsDTO[] = [];
    discountItemList: BillingItemSettingsDTO[] = [];
    search: any = {};
    pageContext = {
        page: 0,
        previousPage: 0,
        itemsPerPage: 100,
        totalItems: 0,
        sort: 'id,desc',
    };
    roomList!: RoomDTO[];
    subsidyItemList: LedgerAccountDTO[] = [];
    invoiceObj: any;
    buttonType: string = '';
    constructor(
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private lookupService: LookupService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private sanitizer: DomSanitizer,
        private operationService: OperationService,
        private datePipe: DatePipe
    ) {
        this.invoiceData = this.config;
        this.selectedStudentList = this.invoiceData.data.studentList;
        this.invoiceObj = this.invoiceData.data.invoiceObj;
    }

    ngOnInit() {
        this.invoiceForm = this.formBuilder.group({
            id: undefined,
            studentId: undefined,
            date: undefined,
            dueDate: [undefined, [Validators.required]],
            startDate: undefined,
            endDate: undefined,
            invoiceAmount: undefined,
            balanceAmount: undefined,
            invoiceStatus: undefined,
            description: undefined,
            note: undefined,
            status: 'A',
            lastModified: undefined,
            lastModifiedBy: undefined,
            schoolId: 1,
            recurringPlanId: undefined,
            invoiceNumber: undefined
        });

        this.invoiceForm
            .get('startDate')
            ?.valueChanges.subscribe((startDate) => {
                const endDateControl = this.invoiceForm.get('endDate');
                endDateControl?.clearValidators();
                if (startDate) {
                    endDateControl?.setValidators([Validators.required]);
                    this.optionalDateControl = true;
                } else {
                    this.optionalDateControl = false;
                    endDateControl?.reset();
                }
                endDateControl?.updateValueAndValidity();
            });

        this.invoiceItemForm = this.formBuilder.group({
            dynamicArray: this.formBuilder.array([]),
        });

        this.queryBillItemSetting();
        this.querySubsidy();
        this.queryRooms();

        if (this.invoiceData.data.invoiceObj.id != undefined) {
            this.invoiceForm.patchValue({
                id: this.invoiceObj.id,
                studentId: this.invoiceObj.studentId,
                date:
                    this.invoiceObj.date != undefined
                        ? this.formatDateForInput(this.invoiceObj.date)
                        : undefined,
                dueDate:
                    this.invoiceObj.dueDate != undefined
                        ? this.formatDateForInput(this.invoiceObj.dueDate)
                        : undefined,
                startDate:
                    this.invoiceObj.startDate != undefined
                        ? this.formatDateForInput(this.invoiceObj.startDate)
                        : undefined,
                endDate:
                    this.invoiceObj.endDate != undefined
                        ? this.formatDateForInput(this.invoiceObj.endDate)
                        : undefined,
                invoiceAmount: this.invoiceObj.invoiceAmount,
                balanceAmount: this.invoiceObj.balanceAmount,
                invoiceStatus: this.invoiceObj.invoiceStatus,
                description: this.invoiceObj.description,
                note: this.invoiceObj.note,
                status: this.invoiceObj.status,
                lastModified: undefined,
                lastModifiedBy: undefined,
                schoolId: this.invoiceObj.schoolId,
                recurringPlanId: this.invoiceObj.recurringPlanId,
                invoiceNumber: this.invoiceObj.invoiceNumber
            });

            // const dummyInvoiceItems = this.invoiceObj.dummyInvoiceItems.sort(
            //     (a: any, b: any) => a.id - b.id
            // );
            setTimeout(() => {
                this.initializeFormArray(this.invoiceObj.dummyInvoiceItems);
            }, 200);
        }
    }

    get dynamicArray(): FormArray {
        return this.invoiceItemForm.get('dynamicArray') as FormArray;
    }

    addMoreFormGroup() {
        const newFormGroup = this.formBuilder.group({
            id: undefined,
            type: 'newItem',
            description: [undefined, [Validators.required]],
            amount: [undefined, [Validators.required]],
            deductionAmt: null,
            currencySign: undefined,
            percentageAmt: undefined,
            refTable: 'Invoice',
            refTableId: undefined,
            schoolId: undefined,
            status: 'A',
        });
        this.dynamicArray.push(newFormGroup);
    }

    initializeFormArray(dummyInvoiceItems: InvoiceDetailsDTO[]) {
        const formArray = this.dynamicArray as FormArray;

        const observables = dummyInvoiceItems.map(
            (element: InvoiceDetailsDTO) =>
                this.formBuilder.group({
                    id: element?.id,
                    type: element.type,
                    description: [element.description, Validators.required],
                    amount: [element.amount, Validators.required],
                    deductionAmt: element.deductionAmt,
                    currencySign: element.currencySign,
                    percentageAmt: element.percentageAmt,
                    refTable: element.refTable,
                    refTableId: element.refTableId,
                    schoolId: element.schoolId,
                    status: element.status,
                    sequence: element.sequence
                })
        );

        observables.forEach((group) => {
            formArray.push(group);
        });
    }

    async onSubmit(type: string) {
        this.buttonType = type;
        this.submitButton = false;
        const invoiceItemDetailsObj: InvoiceDetailsDTO[] =
            this.invoiceItemForm.get('dynamicArray')?.value;
        const observables = this.selectedStudentList.map(async (stud: any) => {
            try {
                let invoiceObj: InvoiceDTO = { ...this.invoiceForm.value };
                invoiceObj.invoiceStatus = type === 'save' ? 'Created' : 'Sent';
                if (this.invoiceForm.get('invoiceStatus')?.value == 'Sent') {
                    invoiceObj.invoiceStatus = 'Sent';
                }
                invoiceObj.studentId = stud.id;
                invoiceObj.schoolId = stud.schoolId;
                invoiceObj.balanceAmount =
                    this.invoiceForm.get('invoiceAmount')?.value;
                invoiceObj.dueDate =
                    this.invoiceForm.get('dueDate')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('dueDate')?.value
                        )
                        : undefined;
                invoiceObj.sentDate =
                    invoiceObj.invoiceStatus == 'Sent'
                        ? this.convertToTimeZone(
                            this.datePipe.transform(
                                new Date(),
                                'yyyy-MM-dd'
                            ) + 'T00:00:00Z'
                        )
                        : undefined;
                invoiceObj.date =
                    this.invoiceForm.get('date')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('date')?.value
                        )
                        : this.convertToTimeZone(
                            this.datePipe.transform(
                                new Date(),
                                'yyyy-MM-dd'
                            ) + 'T00:00:00Z'
                        );
                invoiceObj.startDate =
                    this.invoiceForm.get('startDate')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('startDate')?.value
                        )
                        : undefined;
                invoiceObj.endDate =
                    this.invoiceForm.get('endDate')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('endDate')?.value
                        )
                        : undefined;

                const res: HttpResponse<InvoiceDTO> | undefined =
                    await this.operationService
                        .updateInvoice(invoiceObj)
                        .toPromise();

                if (res && res.body) {
                    const itemObservables = invoiceItemDetailsObj.map(
                        async (item, index: number) => {
                            if (item.id != undefined) {
                                // Update existing item
                                const itemRes:
                                    | HttpResponse<InvoiceDetailsDTO>
                                    | undefined = await this.operationService
                                        .updateInvoiceItemDetails(item)
                                        .toPromise();
                                return itemRes;
                            } else {
                                // Create new item
                                item.refTableId = res.body?.id;
                                item.sequence = item.sequence ? item.sequence : index.toString();
                                const itemRes:
                                    | HttpResponse<InvoiceDetailsDTO>
                                    | undefined = await this.operationService
                                        .createInvoiceItemDetails(item)
                                        .toPromise();
                                return itemRes;
                            }
                        }
                    );

                    await Promise.all(itemObservables); // Wait for all itemObservables to complete
                }
            } catch (error: any) {
                this.buttonType = '';
                this.messageService.add({
                    severity: 'error',
                    life: 10000,
                    summary: error.error?.title || 'Error',
                    detail: error.error?.detail || 'An error occurred',
                });
            }
        });

        await Promise.all(observables); // Wait for all observables to complete

        let message = {};

        if (this.invoiceData.data.editInvoice == true) {
            this.buttonType = '';
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `Invoice Updated Successfully.`,
                life: 10000,
            });
        } else {
            message = {
                severity: 'success',
                summary: 'Success',
                detail: `Invoice Updated Successfully.`,
                life: 10000,
            };
        }

        this.submitButton = true;

        setTimeout(() => {
            if (this.invoiceData.data.editInvoice == true) {
                this.ref.close();
            } else {
                this.ref.close(message);
            }

        }, 1000);
    }

    queryBillItemSetting() {
        this.lookupService
            .queryBillingItemSetting({
                'schoolId.equals': 1,
                'status.equals': 'A',
            })
            .subscribe((res: HttpResponse<BillingItemSettingsDTO[]>) => {
                const presetItemList: BillingItemSettingsDTO[] = [];
                const discountItemList: BillingItemSettingsDTO[] = [];

                (res.body || []).forEach((item) => {
                    if (item.isPresetItem === true) {
                        presetItemList.push(item);
                    } else {
                        discountItemList.push(item);
                    }
                });

                this.presetItemList = presetItemList;
                this.discountItemList = discountItemList;
            });
    }

    querySubsidy() {
        const queryParams = {
            'isSubsidy.equals': true,
            'status.equals': 'A',
        };
        this.lookupService
            .queryLedgerAccount(queryParams)
            .subscribe((res: HttpResponse<LedgerAccountDTO[]>) => {
                let temp: LedgerAccountDTO[] = [];
                this.subsidyItemList = res.body ? res.body : temp;
            });
    }

    queryRooms() {
        this.search.size = 100; //this.pageContext.itemsPerPage
        this.search.page = this.pageContext.page;
        this.search.sort = this.pageContext.sort;
        this.lookupService
            .queryRooms(this.search)
            .subscribe((res: HttpResponse<RoomDTO[]>) => {
                let temp: RoomDTO[] = [];
                let activeRooms: any = res.body?.filter(
                    (each) => each.status == 'A'
                );
                this.roomList = activeRooms ? activeRooms : temp;
            });
    }

    changePresetItem(index: number, e: any) {
        const selectedPreset = e.value;
        const selectedPresetObj = this.presetItemList.find(
            (item: BillingItemSettingsDTO) =>
                item.name === selectedPreset && item.isPresetItem == true
        );
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if (selectedPreset != undefined) {
            const rate = Number(selectedPresetObj?.rate);
            formGroup.get('amount')?.setValue(rate);
        } else if (selectedPreset == undefined) {
            formGroup.get('amount')?.setValue(null);
        }
    }
    onCurrencyChange(index: number, currency: string, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        const selectedValue = e.target.value;
        if (
            formGroup.value.deductionAmt != null &&
            formGroup.value.deductionAmt != ''
        ) {
            if (selectedValue === '$') {
                const rate = Number(formGroup.value.deductionAmt);
                formGroup.get('amount')?.setValue(rate);
            } else if (selectedValue === '%') {
                this.onPercentageAmtChange(index, e);
            }
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }
    }
    onPercentageAmtChange(index: number, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if ((formGroup.value.deductionAmt != null || formGroup.value.deductionAmt != '') && (formGroup.value.percentageAmt != null || formGroup.value.percentageAmt != '')) {
            var amount = parseFloat((formGroup.value.deductionAmt != null && formGroup.value.deductionAmt != '') ? formGroup.value.deductionAmt : 0);
            var subAmountPercentage = parseFloat((formGroup.value.percentageAmt != null && formGroup.value.percentageAmt != '') ? formGroup.value.percentageAmt : 0);
            var subAmount = subAmountPercentage / 100;
            var result = Number(amount * subAmount);
            formGroup.get('amount')?.setValue(result);
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }
    }
    onDeductionAmtChange(index: number, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if (formGroup.value.deductionAmt != null || formGroup.value.deductionAmt != '') {
            if (formGroup.value.currencySign === '$') {
                const rate = Number(formGroup.value.deductionAmt);
                formGroup.get('amount')?.setValue(rate);
            } else if (formGroup.value.currencySign === '%') {
                this.onPercentageAmtChange(index, e);
            } else {
                this.messageService.add({
                    severity: 'warn',
                    life: 10000,
                    summary: 'Warning',
                    detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
                });
            }
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }

    }

    onTypeChange(event: any, index: number) {
        const selectedType = event.target.value;
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        // Clear previous values
        formGroup.get('description')?.reset();
        formGroup.get('amount')?.reset();

        // Update controls based on the selected type
        if (selectedType === 'newItem') {
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
            formGroup.get('amount')?.setValue(null);
        } else if (selectedType === 'presetItem') {
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
            formGroup.get('amount')?.setValue(null);
        } else if (selectedType === 'discount') {
            formGroup.get('deductionAmt ')?.enable();
            formGroup.get('currencySign')?.enable();
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
        } else if (selectedType === 'subsidy') {
            formGroup.get('deductionAmt ')?.enable();
            formGroup.get('currencySign')?.enable();
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
        }
    }

    getTotalAmount() {
        const formGroup = this.dynamicArray as FormArray;
        const itemDetailsArray = formGroup.value;

        const addAmount = itemDetailsArray
            .filter((item: any) =>
                ['newItem', 'presetItem'].includes(item.type)
            )
            .reduce((total: any, item: any) => total + (Number(item.amount) || 0), 0);

        const minusAmount = itemDetailsArray
            .filter((item: any) => ['discount', 'subsidy'].includes(item.type))
            .reduce((total: any, item: any) => total + (Number(item.amount) || 0), 0);

        const totalAmount = addAmount - minusAmount;
        this.invoiceForm.get('invoiceAmount')?.setValue(totalAmount);
        return totalAmount;
    }

    onItemSetting(header: string) {
        let tempData: any = {};
        this.ref = this.dialogService.open(BillItemSettingComponent, {
            data: tempData,
            header: header,
            width: '40%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
        });
        this.ref.onClose.subscribe((res) => {
            this.queryBillItemSetting();
        });
    }

    deleteItem(event: Event, item: any, i: number) {
        this.confirmationService.confirm({
            key: 'confirm1',
            target: event.target as EventTarget,
            message: 'Do you want to Delete this Invoice Item?',
            accept: () => this.deleteInvoiceItem(item, i),
            reject: () => { },
        });
    }

    deleteInvoiceItem(item: any, index: number) {
        if (item.id != undefined) {
            this.operationService
                .deleteInvoiceItemDetails(item)
                .subscribe((res: HttpResponse<InvoiceDetailsDTO>) => {
                    const obj = res.body;
                });
        }
        this.dynamicArray.removeAt(index);
        this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Invoice Item Deleted successfully.`,
            life: 10000,
        });
    }

    formatDateForInput(date: any): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
    }

    convertToTimeZone(originalDateString: string): string {
        const currentDate = new Date();
        const currentHours = currentDate.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDate
            .getMinutes()
            .toString()
            .padStart(2, '0');
        const currentSeconds = currentDate
            .getSeconds()
            .toString()
            .padStart(2, '0');

        return (
            this.datePipe.transform(originalDateString, 'yyyy-MM-dd') +
            `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
        );
    }
}
