import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddressDTO } from 'src/app/demo/model/AddressDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { InvoiceDetailsDTO } from 'src/app/demo/model/InvoiceDetailsDTO';
import { SchoolDTO } from 'src/app/demo/model/SchoolDTO';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';
import { Common } from 'src/app/demo/utils/common';

@Component({
  selector: 'app-send-invoice',
  templateUrl: './send-invoice.component.html',
  styleUrls: ['./send-invoice.component.scss']
})
export class SendInvoiceComponent implements OnInit {
  @ViewChild('printContent') printContent!: ElementRef;
  invoiceData: DynamicDialogConfig<any>;
  invoiceForm!: InvoiceDTO;
  search: any = {};
  pageContext = {
    page: 0,
    previousPage: 0,
    itemsPerPage: 20,
    totalItems: 0,
    sort: 'id,desc',
  };
  invoiceDetails: InvoiceDetailsDTO[] = [];
  submitForm: boolean = false;
  schoolDetails !: SchoolDTO;
  addressDetails !: AddressDTO;
  studentId: any;
  studentDetails: any;
  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private lookupService: LookupService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer,
    private operationService: OperationService,
    private datePipe: DatePipe,
    private elementRef: ElementRef
  ) {
    this.invoiceData = this.config;
    this.invoiceForm = this.config.data.invoiceObj.invoice[0] || {};
    this.studentId = this.invoiceForm.studentId;
    this.schoolDetails = this.config.data.schoolDetails;
    this.addressDetails = this.config.data.address;
  }

  ngOnInit() {
    this.search = {};
    this.search['refTable.equals'] = 'Invoice';
    this.search['refTableId.equals'] = this.invoiceForm.id;
    this.lookupService.queryInvoiceDetails(this.search).subscribe((res: HttpResponse<InvoiceDetailsDTO[]>) => {
      this.invoiceDetails = res.body ?? [];
    })
    this.lookupService
      .getStudentById(this.studentId)
      .subscribe((res: HttpResponse<StudentDTO>) => {
        this.studentDetails = res.body ?? {};
      });
  }


  // printInvoice(): void {
  //   window.print();
  // }

  //   printInvoice(): void {
  //     // Remove elements with class 'no-print' before printing
  //     const elementsToHide = document.querySelectorAll('.no-print');
  //     elementsToHide.forEach(element => {
  //         element.remove();
  //     });

  //     // Hide PrimeNG Dialog header for printing
  //     const dialogHeader = document.querySelector('.p-dialog-header');

  //     if (dialogHeader) {
  //         dialogHeader.classList.add('no-print');
  //     }

  //     // Perform the printing
  //     window.print();

  //     // Reinsert the removed elements back into the DOM after a short delay
  //     setTimeout(() => {
  //         elementsToHide.forEach(element => {
  //             document.body.appendChild(element);
  //         });
  //         // Remove no-print class from PrimeNG Dialog header after printing
  //         if (dialogHeader) {
  //             dialogHeader.classList.remove('no-print');
  //         }
  //     }, 1000); // Adjust the delay as needed (in milliseconds)
  // }

  printInvoice() {
    // const selectors = ['.no-print', '.p-dialog-title', '.p-dialog-header-icons'];

    // selectors.forEach(selector => {
    //   const elements = document.querySelectorAll(selector);
    //   elements.forEach(element => {
    //     element.classList.add('hidden');
    //   });
    // });

    // window.print();

    // this.restoreRemovedElements();
    const documentFormat = 'pdf';
    const reportName = 'Invoice';
    const searchQuote: any = {};
    searchQuote['format'] = documentFormat;
    searchQuote['name'] = reportName;
    searchQuote['invoice_id'] = this.invoiceForm.id;
    searchQuote['school_id'] = this.schoolDetails.id;
    Common.removeEmptyFields(searchQuote);
    this.lookupService
        .queryInvoiceReports(searchQuote)
        .subscribe((res: HttpResponse<any>) => {
            // this.messageService.add({
            //     severity: 'success', life: 10000,
            //     summary: 'Success',
            //     detail: 'Invoice generated successfully.',
            // });
            // this.downloadFile(res, reportName, documentFormat);
            const response = res.body ? res.body : '';
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        },
        (error) => {
            this.messageService.add({
                severity: 'error', life: 10000,
                summary: error.statusText,
                detail: error.message
            });
        }
    );
  }

  downloadFile(res: any, fileName: string, format: string) {
    const a = document.createElement('a');
    let header: any =
        res.headers.get('Content-Type') != null
            ? res.headers.get('Content-Type')
            : '';
    a.href = URL.createObjectURL(new Blob([res.body], { type: header }));
    a.download =
        fileName + moment().format('YYYYMMDD_HHmmss') + `.${format}`;
    document.body.appendChild(a);
    a.click();
}

  restoreRemovedElements() {
    const selectors = ['.no-print', '.p-dialog-title', '.p-dialog-header-icons'];

    selectors.forEach(selector => {
      const elements = document.querySelectorAll(selector);
      elements.forEach(element => {
        element.classList.remove('hidden');
      });
    });
  }

  sendInvoice() {
    this.submitForm = true;
    const innerObj = this.invoiceData.data.invoiceObj.invoice[0];
    const obj: InvoiceDTO = {
      ...innerObj,
      sentDate: this.convertToTimeZone(this.datePipe.transform(new Date(), "yyyy-MM-dd") + "T00:00:00Z"),
      invoiceStatus: 'Sent',
    };
    this.operationService
      .updateInvoice(obj)
      .subscribe(
        async (res: HttpResponse<InvoiceDTO>) => {
          const tmep = res.body;
          if (tmep && tmep.invoiceStatus == 'Sent') {
            await this.operationService.sendInvoice(tmep).toPromise();
          }
          this.submitForm = false;
          const message = {
            severity: 'success',
            life: 10000,
            summary: 'Success',
            detail: 'Invoice sent successfully.',
          }
          this.ref.close(message);
        },
        (error) => {
          this.submitForm = false;
          this.messageService.add({
            severity: 'error',
            life: 10000,
            summary: error.error.title,
            detail: error.error.detail,
          });
        }
      );
  }

  onClose() {
    this.ref.close();
  }
  convertToTimeZone(originalDateString: string): string {
    const currentDate = new Date();
    const currentHours = currentDate.getHours().toString().padStart(2, '0');
    const currentMinutes = currentDate.getMinutes().toString().padStart(2, '0');
    const currentSeconds = currentDate.getSeconds().toString().padStart(2, '0');

    return (
      this.datePipe.transform(originalDateString, 'yyyy-MM-dd') + `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
    );
  }
}
