import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { WebStorage } from '../../components/auth/storage/web.storage';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  private timeZone: string = 'UTC'; 
  private format : string = 'MMM DD, YYYY';
  constructor(private storage : WebStorage) {
    const userdata = this.storage.getUserData();
    this.setTimeZone(userdata.schoolObj?.timeZone);

  }

  transform(value: Date | string | moment.Moment): string {    
    if (!value) return '';
    const date = moment.utc(value);

    // Apply the time zone if it's set
    if (this.timeZone) {
      date.tz(this.timeZone);
    }

    return date.format(this.format);
  }

  setTimeZone(timeZone: string) {
    this.timeZone = timeZone;    
  }
}
