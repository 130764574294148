import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard extends KeycloakAuthGuard {
    constructor(
        protected override router: Router,
        protected override keycloakAngular: KeycloakService
    ) {
        super(router, keycloakAngular);
    }

    override async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloakAngular.login();
        }

        // Get the roles required from the route.
        const requiredRoles = route.data['roles'];
        // console.log(this.roles);
        // Allow the user to proceed if no additional roles are required to access the route.
        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true;
        }

        // Allow the user to proceed if all the required roles are present.
        return requiredRoles.every((role) => this.roles.includes(role));
    }
}
