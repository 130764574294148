import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, letters: number): string {
    if (!value) return '';

    if (value.length <= letters) {
      return value;
    }

    const truncatedValue = value.substr(0, letters);
    const lastSpaceIndex = truncatedValue.lastIndexOf(' ');

    if (lastSpaceIndex !== -1) {
      return truncatedValue.substr(0, lastSpaceIndex) + '...';
    }

    return truncatedValue + '...';
  }
}
