<p-toast></p-toast>
<div class="container-fluid" >
    <div class="row">
        <div class="col-md-12" >
            
            <div class="flex mt-1 align-items-center" *ngFor="let student of selectedStudentList">
                <img
                    *ngIf="student.profileimg != undefined;else noImageFound"
                    [src]="student.profileimg"
                    width="42"
                    height="42"
                    alt="profile"
                    class="border-circle border-2 border-primary flex-shrink-0"
                />
                <ng-template #noImageFound>
                    <a>
                        <img
                            src="assets/layout/images/male.png"
                            alt="Fallbackimage"
                            width="42"
                            height="42"
                            class="border-circle border-2 border-primary flex-shrink-0"
                        />
                    </a>
                </ng-template>
                <div class="ml-2">
                    <div class="text-base  font-bold text-900 mb-0">
                        <span class="student-name">{{ student.fullName }}</span>
                    </div>
                </div>
            </div>
            <div class="flex mt-1 align-items-center" >
               
            </div>
            <div class="border-top-2 border-primary-400 my-2"></div>



            <form [formGroup]="creditForm">
                <div class="p-fluid p-formgrid grid">
                    <div class="col-12 field " *ngIf="multiFamilyDataList?.length > 0">
                        <label [appAsterisk]="true">Select Family</label>
                        <p-dropdown 
                        [options]="multiFamilyDataList" 
                        formControlName="selectedFamily"
                        optionLabel="name"
                        optionValue="id"
                        (onChange)="onFamilyChange($event)"
                        placeholder="Select a Family">
                      </p-dropdown>
                      </div>
                    <div class="field col-12 mb-0">
                        <label htmlFor="amount" [appAsterisk]="true">Amount</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                        <p-inputNumber id="amount" type="text"  formControlName="amount" inputId="minmaxfraction" mode="decimal"
                        [placeholder]="invoiceData.data.totalAmount"
                        pKeyFilter="money"
                        [minFractionDigits]="2" [maxFractionDigits]="5"> </p-inputNumber>
                        </div>
                        <app-form-field-validation
                        [control]="creditForm.get('amount')!"></app-form-field-validation>
                    </div>
                    <div class="field col-12 mb-0">
                        <span class="p-float-label">
                            <textarea
                                id="description"
                                [rows]="1" 
                                class="full-width-textarea"
                                formControlName="description"
                                pInputTextarea></textarea>
                            <label for="float-input" [appAsterisk]="true">Description</label>
                        </span>
                        <app-form-field-validation
                        [control]="creditForm.get('description')!"></app-form-field-validation>
                    </div>

                    <div class="field col-12 mb-0">
                        <label htmlFor="lastname2">Add Optional Internal Note</label>
                        <input pInputText id="note" type="text" formControlName="note" />
                    </div>

                </div>
                <div class="row" >
                    <div class="col">
                        <div class="d-flex justify-content-end">
                            <p-button
                            label="Cancel"
                            (onClick)="cancelInv()"
                            styleClass="p-button-secondary p-button-raised"
                            ></p-button>
                        <div class="mx-2"></div>
                            <button pButton
                            (click)="onSubmit()"
                            [disabled]=" !creditForm.valid"
                            [loading]="submitForm"
                            label="Save"
                            class="p-button-success p-button-raised"
                        ></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #elseBlock>
    <div class="container-fluid">
        <div class="card-form" style="display: flex; align-items: center; justify-content: center;">
            <p-progressSpinner [style]="{width: '45px', height: '37px'}"
                styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".9s"></p-progressSpinner>
        </div>    
    </div>
</ng-template>