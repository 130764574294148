// import { KeycloakService } from 'keycloak-angular';
import { ConfigInitService } from 'src/app/demo/service/config-init.service';
import { environment } from 'src/environments/environment';
// import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import * as moment from 'moment';

export function initializeKeycloak(
    keycloak: KeycloakService,
    configService: ConfigInitService
) {
    return () =>
      configService.getConfig()
        .pipe(
          switchMap<any, any>((config: any) => {

            return from(keycloak.init({
              config: {
                url: config['KEYCLOAK_URL'],
                realm: config['KEYCLOAK_REALM'],
                clientId: config['KEYCLOAK_CLIENT_ID'],
              },
              initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                  window.location.origin+environment.path+ '/assets/layout/silent-check-sso.html',
                checkLoginIframe:true,
                checkLoginIframeInterval:1000,
                enableLogging:true
              },
              enableBearerInterceptor: true,
              bearerPrefix: 'Bearer',
              bearerExcludedUrls: [
                  '/assets']
            }))

          })
        ).toPromise()
  }
