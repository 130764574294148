import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageDTO } from 'src/app/demo/model/MessageDTO';
import { Message } from 'src/app/demo/model/message';
import { User } from 'src/app/demo/model/user';

@Injectable()
export class ChatService {
    _activeUser: any = {};

    private activeUser = new BehaviorSubject<any>(this._activeUser);

    activeUser$ = this.activeUser.asObservable();


    private reloadSubject = new BehaviorSubject<boolean>(false);

    private reloadStaffSubject = new BehaviorSubject<boolean>(false);

    private toggelSubject = new BehaviorSubject<boolean>(false);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    
    constructor(private http: HttpClient) {
        
    }

    getChatData() {
        return this.http
            .get<any>('assets/demo/data/chat.json')
            .toPromise()
            .then((res) => res.data as any[])
            .then((data) => data);
    }

    changeActiveChat(user: any) {
        this._activeUser = user;
        this.activeUser.next(user);
        // console.log(this.activeUser.value);
    }

    sendMessage(message: MessageDTO) {
        this._activeUser.messages.push(message);
        this.activeUser.next(this._activeUser);
    }

    setReload(value: boolean) {
        this.reloadSubject.next(value);
    }

    getReload() {
        return this.reloadSubject.asObservable();
    }

    showToggel(value: boolean) {
        this.toggelSubject.next(value);
    }

    hideToggel() {        
        return this.toggelSubject.asObservable();
    }
    showLoading(value: boolean) {
        this.loadingSubject.next(value);
    }

    hideLoading() {        
        return this.loadingSubject.asObservable();
    }


    setStaffReload(value: boolean) {
        this.reloadStaffSubject.next(value);
    }

    getStaffReload() {
        return this.reloadStaffSubject.asObservable();
    }

}
