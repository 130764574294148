import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Check if the input is valid
    if (!value) {
      return '';
    }

    // Extract the time parts from the input string
    const timeParts = value.match(/(\d{2}):(\d{2}):(\d{2})\s*(AM|PM)/i);

    // If the input format is invalid, return the input as-is
    if (!timeParts) {
      return value;
    }

    // Extract hours, minutes, and period (AM/PM) from the input
    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const period = timeParts[4].toUpperCase();

    // Adjust hours for 12-hour format
    if (period === 'PM' && hours < 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format the time as HH:MM AM/PM
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
  }
}
