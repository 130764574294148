import { Injectable } from '@angular/core';
import { createRequestOptionAllRecords } from '../utils/request-util';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { RoomDTO } from '../model/RoomDTO';
import { StudentDTO } from '../model/StudentDTO';
import { StaffDTO } from '../model/StaffDTO';
import { GuardianDTO } from '../model/GuardianDTO';
import { AuthorizedPickupDTO } from '../model/AuthorizedPickupDTO';
import { TagDTO } from '../model/TagDTO';
import { AttendanceDTO } from '../model/AttendanceDTO';
import { AddressDTO } from '../model/AddressDTO';
import { PhysiciansDTO } from '../model/PhysiciansDTO';
import { DocumentDTO } from '../model/DocumentDTO';
import { AbsentDTO } from '../model/AbsentDTO';
import { VaccineDoseDTO } from '../model/VaccineDoseDTO';
import { VaccineDTO } from '../model/VaccineDTO';
import { ScheduleDTO } from '../model/ScheduleDTO';
import { ScheduleDoseDTO } from '../model/ScheduleDoseDTO';
import { ImmunizationDTO } from '../model/ImmunizationDTO';
import { PermissionDTO } from '../model/PermissionDTO';
import { RoleDTO } from '../model/RoleDTO';
import { UserDTO } from '../model/UserDTO';
import { AdminUserDTO } from '../model/AdminUserDTO';
import { ReminderDTO } from '../model/ReminderDTO';
import { ActivityTypeDTO } from '../model/ActivityTypeDTO';
import { NewsletterDTO } from '../model/NewsletterDTO';
import { MessageDTO } from '../model/MessageDTO';
import { EventDTO } from '../model/EventDTO';
import { TemplateComponentDTO } from '../model/TemplateComponentDTO';
import { StaffShiftsDTO } from '../model/StaffShiftsDTO';
import { ProgramDTO } from '../model/ProgramDTO';
import { StudentProgramRoomDTO } from '../model/StudentProgramRoomDTO';
import { LedgerAccountDTO } from '../model/LedgerAccountDTO';
import { TransactionDTO } from '../model/TransactionDTO';
import { BillingItemSettingsDTO } from '../model/BillingItemSettingsDTO';
import { PhysicalTrackerDTO } from '../model/PhysicalTrackerDTO';
import { InvoiceDTO } from '../model/InvoiceDTO';
import { InvoiceDetailsDTO } from '../model/InvoiceDetailsDTO';
import { StaffScheduleDTO } from '../model/StaffScheduleDTO';
import { MasterLookupDTO } from '../model/MasterLookupDTO';
import { SchoolDTO } from '../model/SchoolDTO';
import { RecurringPlansDTO } from '../model/RecurringPlansDTO';
import { environment } from 'src/environments/environment';
import { FamilyDTO } from '../model/FamilyDTO';
import { RoomMoveDTO } from '../model/RoomMoveDTO';
import { FutureEnrollmentDTO } from '../model/FutureEnrollmentDTO';

@Injectable({
    providedIn: 'root',
})
export class OperationService {
    private SERVER_API_URL = environment.serviceUrl;
    constructor(private http: HttpClient) { }

    createRoom(obj: any): Observable<HttpResponse<RoomDTO>> {
        return this.http
            .post<RoomDTO>(this.SERVER_API_URL + '/rooms', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    updateRoom(obj: any): Observable<HttpResponse<RoomDTO>> {
        // const object = this.convertDatesFromClient(obj);
        return this.http
            .put<any>(this.SERVER_API_URL + '/rooms/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    deleteRoom(obj: any): Observable<HttpResponse<RoomDTO>> {
        // const object = this.convertDatesFromClient(obj);
        return this.http
            .delete<any>(this.SERVER_API_URL + '/rooms/' + obj.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }


    createStudent(obj: StudentDTO): Observable<HttpResponse<StudentDTO>> {
        return this.http
            .post<StudentDTO>(this.SERVER_API_URL + '/students', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StudentDTO>) => res));
    }

    createNewMessage(obj: MessageDTO): Observable<HttpResponse<MessageDTO>> {
        return this.http
            .post<MessageDTO>(this.SERVER_API_URL + '/messages', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MessageDTO>) => res));
    }

    createNewMessageAll(obj: MessageDTO,studentIds : any): Observable<HttpResponse<MessageDTO>> {
        const studentIdArray = studentIds.split(',');
        const params = new HttpParams().set('studentsIds', studentIdArray.join(','));
        return this.http
            .post<MessageDTO>(this.SERVER_API_URL + '/messages-list/students', obj, {
                params: params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MessageDTO>) => res));
    }
    createNewMessageAllStaff(obj: MessageDTO,studentIds : any): Observable<HttpResponse<MessageDTO>> {
        const studentIdArray = studentIds.split(',');
        const params = new HttpParams().set('staffIds', studentIdArray.join(','));
        return this.http
            .post<MessageDTO>(this.SERVER_API_URL + '/messages-list/staff', obj, {
                params: params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MessageDTO>) => res));
    }

    createStaffMessage(obj: MessageDTO): Observable<HttpResponse<MessageDTO>> {
        return this.http
            .post<MessageDTO>(this.SERVER_API_URL + '/messages/staff', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MessageDTO>) => res));
    }

    updateStudent(obj: StudentDTO): Observable<HttpResponse<StudentDTO>> {
        return this.http
            .put<StudentDTO>(this.SERVER_API_URL + '/students/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StudentDTO>) => res));
    }

    updateAllStudent(obj: any, studentIds: string): Observable<HttpResponse<any>> {
        const studentIdArray = studentIds.split(',');
        const params = new HttpParams().set('studentIds', studentIdArray.join(','));
        return this.http
            .put<any>(this.SERVER_API_URL + '/students-batch-update', obj, {
                params: params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    updateMessage(obj: StudentDTO): Observable<HttpResponse<MessageDTO>> {
        return this.http
            .put<MessageDTO>(this.SERVER_API_URL + '/messages/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MessageDTO>) => res));
    }

    deleteStudent(objVal: StudentDTO): Observable<HttpResponse<StudentDTO>> {
        return this.http
            .delete<StudentDTO>(
                this.SERVER_API_URL + '/students/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<StudentDTO>) => res));
    }

    // updateStudents(
    //     students: StudentDTO[]
    // ): Observable<HttpResponse<StudentDTO[]>> {
    //     return this.http
    //         .post<StudentDTO[]>(
    //             this.SERVER_API_URL + '/students-list',
    //             students,
    //             {
    //                 observe: 'response',
    //             }
    //         )
    //         .pipe(map((res: HttpResponse<StudentDTO[]>) => res));
    // }
    updateStudents(obj: StudentDTO[], roomID: number): Observable<HttpResponse<StudentDTO>> {
        return this.http
            .post<StudentDTO>(this.SERVER_API_URL + `/students-list/${roomID}`, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StudentDTO>) => res));
    }

    createStaff(obj: StaffDTO): Observable<HttpResponse<StaffDTO>> {
        return this.http
            .post<StaffDTO>(this.SERVER_API_URL + '/staff', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffDTO>) => res));
    }

    updateStaff(obj: StaffDTO): Observable<HttpResponse<StaffDTO>> {
        return this.http
            .put<StaffDTO>(this.SERVER_API_URL + '/staff/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffDTO>) => res));
    }
    createBillItemSetting(obj: BillingItemSettingsDTO): Observable<HttpResponse<BillingItemSettingsDTO>> {
        return this.http
            .post<BillingItemSettingsDTO>(this.SERVER_API_URL + '/billing-item-settings', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<BillingItemSettingsDTO>) => res));
    }

    updateBillItemSetting(obj: BillingItemSettingsDTO): Observable<HttpResponse<BillingItemSettingsDTO>> {
        return this.http
            .put<BillingItemSettingsDTO>(this.SERVER_API_URL + '/billing-item-settings/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<BillingItemSettingsDTO>) => res));
    }
    deleteStaff(objVal: StaffDTO): Observable<HttpResponse<StaffDTO>> {
        return this.http
            .delete<StaffDTO>(this.SERVER_API_URL + '/staff/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffDTO>) => res));
    }
    updateStaffList(staff: StaffDTO[], roomID: number): Observable<HttpResponse<StaffDTO[]>> {
        let params = new HttpParams();
        params = params.set('roomId', roomID.toString());
        return this.http
            .post<StaffDTO[]>(this.SERVER_API_URL + '/staff-list', staff, {
                params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffDTO[]>) => res));
    }

    createGuardian(obj: GuardianDTO): Observable<HttpResponse<GuardianDTO>> {
        return this.http
            .post<GuardianDTO>(this.SERVER_API_URL + '/guardians', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<GuardianDTO>) => res));
    }

    updateGuardian(obj: GuardianDTO): Observable<HttpResponse<GuardianDTO>> {
        return this.http
            .put<GuardianDTO>(
                this.SERVER_API_URL + '/guardians/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<GuardianDTO>) => res));
    }

    deleteGuardian(objVal: StudentDTO): Observable<HttpResponse<StudentDTO>> {
        return this.http
            .delete<StudentDTO>(
                this.SERVER_API_URL + '/guardians/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<StudentDTO>) => res));
    }

    createAuthorizedPickup(
        obj: AuthorizedPickupDTO
    ): Observable<HttpResponse<AuthorizedPickupDTO>> {
        return this.http
            .post<AuthorizedPickupDTO>(
                this.SERVER_API_URL + '/authorized-pickups',
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AuthorizedPickupDTO>) => res));
    }

    updateAuthorizedPickup(
        obj: AuthorizedPickupDTO
    ): Observable<HttpResponse<AuthorizedPickupDTO>> {
        return this.http
            .put<AuthorizedPickupDTO>(
                this.SERVER_API_URL + '/authorized-pickups/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AuthorizedPickupDTO>) => res));
    }
    deleteAuthorizedPickup(
        objVal: AuthorizedPickupDTO
    ): Observable<HttpResponse<AuthorizedPickupDTO>> {
        return this.http
            .delete<AuthorizedPickupDTO>(
                this.SERVER_API_URL + '/authorized-pickups/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AuthorizedPickupDTO>) => res));
    }

    createPhysician(
        obj: PhysiciansDTO
    ): Observable<HttpResponse<PhysiciansDTO>> {
        return this.http
            .post<PhysiciansDTO>(this.SERVER_API_URL + '/physicians', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<PhysiciansDTO>) => res));
    }

    updatePhysician(
        obj: PhysiciansDTO
    ): Observable<HttpResponse<PhysiciansDTO>> {
        return this.http
            .put<PhysiciansDTO>(
                this.SERVER_API_URL + '/physicians/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<PhysiciansDTO>) => res));
    }
    deletePhysician(
        objVal: PhysiciansDTO
    ): Observable<HttpResponse<PhysiciansDTO>> {
        return this.http
            .delete<PhysiciansDTO>(
                this.SERVER_API_URL + '/physicians/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<PhysiciansDTO>) => res));
    }

    createTag(obj: TagDTO): Observable<HttpResponse<TagDTO>> {
        return this.http
            .post<TagDTO>(this.SERVER_API_URL + '/tags', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TagDTO>) => res));
    }

    updateTag(obj: TagDTO): Observable<HttpResponse<TagDTO>> {
        return this.http
            .put<TagDTO>(this.SERVER_API_URL + '/tags/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TagDTO>) => res));
    }

    deleteTag(objVal: TagDTO): Observable<HttpResponse<TagDTO>> {
        return this.http
            .delete<TagDTO>(this.SERVER_API_URL + '/tags/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TagDTO>) => res));
    }

    createAttendance(
        obj: AttendanceDTO
    ): Observable<HttpResponse<AttendanceDTO>> {
        return this.http
            .post<AttendanceDTO>(this.SERVER_API_URL + '/attendances', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AttendanceDTO>) => res));
    }

    createAttendanceAll(obj: AttendanceDTO,studentIds : any): Observable<HttpResponse<AttendanceDTO>> {
        const studentIdArray = studentIds.split(',');
        const params = new HttpParams().set('attendanceIds', studentIdArray.join(','));
        return this.http
            .post<AttendanceDTO>(this.SERVER_API_URL + '/attendances', obj, {
                params: params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AttendanceDTO>) => res));
    }


    updateAttendance(
        obj: AttendanceDTO
    ): Observable<HttpResponse<AttendanceDTO>> {
        return this.http
            .put<AttendanceDTO>(
                this.SERVER_API_URL + '/attendances/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AttendanceDTO>) => res));
    }
    updateAttendanceAll(obj: AttendanceDTO,studentIds : any): Observable<HttpResponse<AttendanceDTO>> {
        const studentIdArray = studentIds.split(',');
        const params = new HttpParams().set('attendanceId', studentIdArray.join(','));
        return this.http
            .put<AttendanceDTO>(
                this.SERVER_API_URL + '/attendances/', obj,
                {
                    params: params,
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AttendanceDTO>) => res));
    }

    deleteAttendance(
        objVal: AttendanceDTO
    ): Observable<HttpResponse<AttendanceDTO>> {
        return this.http
            .delete<AttendanceDTO>(
                this.SERVER_API_URL + '/attendances/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AttendanceDTO>) => res));
    }
    createAddress(obj: AddressDTO): Observable<HttpResponse<AddressDTO>> {
        return this.http
            .post<AddressDTO>(this.SERVER_API_URL + '/addresses', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AddressDTO>) => res));
    }

    updateAddress(obj: AddressDTO): Observable<HttpResponse<AddressDTO>> {
        return this.http
            .put<AddressDTO>(
                this.SERVER_API_URL + '/addresses/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AddressDTO>) => res));
    }

    createDocument(
        file: File,
        document: DocumentDTO
    ): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/documents-upload', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    backupDocument(
        file: File,
        document: DocumentDTO
    ): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/upload/backup-image', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    createLogo(
        file: File,
        document: DocumentDTO
    ): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/documents-upload-logo', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    updateDocument(
        id: number,
        updatedDocument: DocumentDTO
    ): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(updatedDocument));

        return this.http
            .put<any>(`${this.SERVER_API_URL}/documents/${id}`, formData, {
                observe: 'response',
            })

            .pipe(map((res: HttpResponse<any>) => res));
    }
    deleteDocument(
        objVal: DocumentDTO
    ): Observable<HttpResponse<DocumentDTO>> {
        return this.http
            .delete<DocumentDTO>(
                this.SERVER_API_URL + '/documents/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<DocumentDTO>) => res));
    }
    createAbsent(obj: AbsentDTO): Observable<HttpResponse<AbsentDTO>> {
        return this.http
            .post<AbsentDTO>(this.SERVER_API_URL + '/absents', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AbsentDTO>) => res));
    }

    updateAbsent(obj: AbsentDTO): Observable<HttpResponse<AbsentDTO>> {
        return this.http
            .put<AbsentDTO>(this.SERVER_API_URL + '/absents/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AbsentDTO>) => res));
    }

    deleteAbsent(objVal: AbsentDTO): Observable<HttpResponse<AbsentDTO>> {
        return this.http
            .delete<AbsentDTO>(this.SERVER_API_URL + '/absents/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AbsentDTO>) => res));
    }

    createVaccineDoses(
        obj: VaccineDoseDTO
    ): Observable<HttpResponse<VaccineDoseDTO>> {
        return this.http
            .post<VaccineDoseDTO>(this.SERVER_API_URL + '/vaccine-doses', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<VaccineDoseDTO>) => res));
    }

    updateVaccineDoses(
        obj: VaccineDoseDTO
    ): Observable<HttpResponse<VaccineDoseDTO>> {
        return this.http
            .put<VaccineDoseDTO>(
                this.SERVER_API_URL + '/vaccine-doses/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<VaccineDoseDTO>) => res));
    }

    deleteVaccineDoses(
        objVal: VaccineDoseDTO
    ): Observable<HttpResponse<VaccineDoseDTO>> {
        return this.http
            .delete<VaccineDoseDTO>(
                this.SERVER_API_URL + '/vaccine-doses/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<VaccineDoseDTO>) => res));
    }

    createVaccines(obj: VaccineDTO): Observable<HttpResponse<VaccineDTO>> {
        return this.http
            .post<VaccineDTO>(this.SERVER_API_URL + '/vaccines', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<VaccineDTO>) => res));
    }

    updateVaccines(obj: VaccineDTO): Observable<HttpResponse<VaccineDTO>> {
        return this.http
            .put<VaccineDTO>(this.SERVER_API_URL + '/vaccines/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<VaccineDTO>) => res));
    }

    deleteVaccines(objVal: VaccineDTO): Observable<HttpResponse<VaccineDTO>> {
        return this.http
            .delete<VaccineDTO>(
                this.SERVER_API_URL + '/vaccines/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<VaccineDTO>) => res));
    }

    createSchedule(obj: ScheduleDTO): Observable<HttpResponse<ScheduleDTO>> {
        return this.http
            .post<ScheduleDTO>(this.SERVER_API_URL + '/schedules', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ScheduleDTO>) => res));
    }
    saveRepeatSchedule(obj: any): Observable<HttpResponse<any>> {
        return this.http
            .post<any>(this.SERVER_API_URL + '/staff-schedulesList', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    updateSchedule(obj: ScheduleDTO): Observable<HttpResponse<ScheduleDTO>> {
        return this.http
            .put<ScheduleDTO>(
                this.SERVER_API_URL + '/schedules/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ScheduleDTO>) => res));
    }
    updateStaffScheduleRepeat(obj: StaffScheduleDTO, updateType: string): Observable<HttpResponse<StaffScheduleDTO>> {
        const url = `${this.SERVER_API_URL}/staff-schedules/${obj.id}?updateType=${updateType}`;
        return this.http
            .put<StaffScheduleDTO>(url, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffScheduleDTO>) => res));
    }
    
    deleteSchedule(
        objVal: ScheduleDTO
    ): Observable<HttpResponse<ScheduleDTO>> {
        return this.http
            .delete<ScheduleDTO>(
                this.SERVER_API_URL + '/schedules/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ScheduleDTO>) => res));
    }
    updateScheduleDose(
        obj: ScheduleDoseDTO
    ): Observable<HttpResponse<ScheduleDoseDTO>> {
        return this.http
            .put<ScheduleDoseDTO>(
                this.SERVER_API_URL + '/schedule-doses/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ScheduleDoseDTO>) => res));
    }
    updateImmunization(
        obj: ImmunizationDTO
    ): Observable<HttpResponse<ImmunizationDTO>> {
        return this.http
            .put<ImmunizationDTO>(
                this.SERVER_API_URL + '/immunizations/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ImmunizationDTO>) => res));
    }
    createPermission(
        obj: PermissionDTO
    ): Observable<HttpResponse<PermissionDTO>> {
        return this.http
            .post<PermissionDTO>(
                this.SERVER_API_URL + '/techvg-permissions',
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<PermissionDTO>) => res));
    }

    updatePermission(
        obj: PermissionDTO
    ): Observable<HttpResponse<PermissionDTO>> {
        return this.http
            .put<PermissionDTO>(
                this.SERVER_API_URL + '/techvg-permissions/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<PermissionDTO>) => res));
    }
    deletePermission(
        objVal: PermissionDTO
    ): Observable<HttpResponse<PermissionDTO>> {
        return this.http
            .delete<PermissionDTO>(
                this.SERVER_API_URL + '/techvg-permissions/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<PermissionDTO>) => res));
    }

    createRole(obj: any): Observable<HttpResponse<RoleDTO>> {
        return this.http
            .post<RoleDTO>(this.SERVER_API_URL + '/techvg-roles', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RoleDTO>) => res));
    }

    updateRole(obj: any): Observable<HttpResponse<RoleDTO>> {
        return this.http
            .put<RoleDTO>(
                this.SERVER_API_URL + '/techvg-roles/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<RoleDTO>) => res));
    }
    deleteRole(objVal: any): Observable<HttpResponse<RoleDTO>> {
        return this.http
            .delete<RoleDTO>(
                this.SERVER_API_URL + '/techvg-roles/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<RoleDTO>) => res));
    }
    createUser(obj: AdminUserDTO): Observable<HttpResponse<AdminUserDTO>> {
        return this.http
            .post<AdminUserDTO>(this.SERVER_API_URL + '/user/add', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<AdminUserDTO>) => res));
    }

    updateUser(obj: AdminUserDTO): Observable<HttpResponse<AdminUserDTO>> {
        return this.http
            .put<AdminUserDTO>(
                this.SERVER_API_URL + '/user/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AdminUserDTO>) => res));
    }
    deleteUser(objVal: AdminUserDTO): Observable<HttpResponse<AdminUserDTO>> {
        return this.http
            .delete<AdminUserDTO>(
                this.SERVER_API_URL + '/user/delete/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<AdminUserDTO>) => res));
    }
    createReminder(obj: ReminderDTO): Observable<HttpResponse<ReminderDTO>> {
        return this.http
            .post<ReminderDTO>(
                this.SERVER_API_URL + '/reminders/student-profile/all-vaccines',
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ReminderDTO>) => res));
    }

    createActivityType(
        obj: ActivityTypeDTO
    ): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .post<ActivityTypeDTO>(
                this.SERVER_API_URL + '/activity-types',
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }

    updateActivityType(
        obj: ActivityTypeDTO
    ): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .put<ActivityTypeDTO>(
                this.SERVER_API_URL + '/activity-types/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }

    createNewsletter(
        obj: NewsletterDTO
    ): Observable<HttpResponse<NewsletterDTO>> {
        return this.http
            .post<NewsletterDTO>(this.SERVER_API_URL + '/newsletters', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<NewsletterDTO>) => res));
    }

    updateNewsletter(
        obj: NewsletterDTO
    ): Observable<HttpResponse<NewsletterDTO>> {
        return this.http
            .put<NewsletterDTO>(
                this.SERVER_API_URL + '/newsletters/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<NewsletterDTO>) => res));
    }

    deleteNewsletter(
        objVal: NewsletterDTO
    ): Observable<HttpResponse<NewsletterDTO>> {
        return this.http
            .delete<NewsletterDTO>(
                this.SERVER_API_URL + '/newsletters/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<NewsletterDTO>) => res));
    }

    deleteActivityType(objVal: ActivityTypeDTO): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .delete<ActivityTypeDTO>(this.SERVER_API_URL + '/activity-types/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }

    createEvent(obj: EventDTO): Observable<HttpResponse<EventDTO>> {
        return this.http
            .post<EventDTO>(
                this.SERVER_API_URL + '/events',
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<EventDTO>) => res));
    }

    updateEvent(
        obj: EventDTO
    ): Observable<HttpResponse<EventDTO>> {
        return this.http
            .put<EventDTO>(
                this.SERVER_API_URL + '/events/' + obj.id,
                obj,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<EventDTO>) => res));
    }

    deleteEvent(objVal: EventDTO): Observable<HttpResponse<EventDTO>> {
        return this.http
            .delete<EventDTO>(this.SERVER_API_URL + '/events/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<EventDTO>) => res));
    }
    createAttachment(obj: TemplateComponentDTO): Observable<HttpResponse<TemplateComponentDTO>> {
        return this.http
            .post<TemplateComponentDTO>(this.SERVER_API_URL + '/template-components', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TemplateComponentDTO>) => res));
    }

    updateAttachment(obj: TemplateComponentDTO): Observable<HttpResponse<TemplateComponentDTO>> {
        return this.http
            .put<TemplateComponentDTO>(this.SERVER_API_URL + '/template-components/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TemplateComponentDTO>) => res));
    }

    createActivity(obj: ActivityTypeDTO): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .post<ActivityTypeDTO>(this.SERVER_API_URL + '/activities', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }
    updateActivity(obj: ActivityTypeDTO): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .put<ActivityTypeDTO>(this.SERVER_API_URL + '/activities/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }
    deleteActivity(objVal: ActivityTypeDTO): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .delete<ActivityTypeDTO>(this.SERVER_API_URL + '/activities/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }
    createSendActivity(obj: ActivityTypeDTO): Observable<HttpResponse<ActivityTypeDTO>> {
        return this.http
            .post<ActivityTypeDTO>(this.SERVER_API_URL + '/activities/send-to-parents', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ActivityTypeDTO>) => res));
    }
    createStaffShift(obj: StaffShiftsDTO): Observable<HttpResponse<StaffShiftsDTO>> {
        return this.http
            .post<StaffShiftsDTO>(this.SERVER_API_URL + '/staff-shifts/create-time-slots', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffShiftsDTO>) => res));
    }
    // copyStaffShift(obj: any): Observable<HttpResponse<any>> {
    //     return this.http
    //         .post<any>(this.SERVER_API_URL + '/copy-staff-shifts', obj, {
    //             observe: 'response',
    //         })
    //         .pipe(map((res: HttpResponse<any>) => res));
    // }
    copyStaffShift(payload: any): Observable<HttpResponse<any>> {
        const params = new HttpParams({ fromObject: payload });

        // Concatenate the parameters to the URL
        const urlWithParams = this.SERVER_API_URL + '/copy-staff-shifts?' + params.toString();

        return this.http.post<any>(urlWithParams, {}, {
            observe: 'response'
        });
    }
    publishStaffShift(payload: any): Observable<HttpResponse<any>> {
        const params = new HttpParams({ fromObject: payload });

        // Concatenate the parameters to the URL
        const urlWithParams = this.SERVER_API_URL + '/update-shifts?' + params.toString();

        return this.http.post<any>(urlWithParams, {}, {
            observe: 'response'
        });
    }
    updateStaffShift(obj: StaffShiftsDTO): Observable<HttpResponse<StaffShiftsDTO>> {
        return this.http
            .put<StaffShiftsDTO>(this.SERVER_API_URL + '/staff-shifts/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffShiftsDTO>) => res));
    }
    deleteStaffShift(objVal: StaffShiftsDTO): Observable<HttpResponse<StaffShiftsDTO>> {
        return this.http
            .delete<StaffShiftsDTO>(this.SERVER_API_URL + '/staff-shifts/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffShiftsDTO>) => res));
    }
    createProgram(obj: ProgramDTO): Observable<HttpResponse<ProgramDTO>> {
        return this.http
            .post<ProgramDTO>(this.SERVER_API_URL + '/programs', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ProgramDTO>) => res));
    }
    updateProgram(obj: ProgramDTO): Observable<HttpResponse<ProgramDTO>> {
        return this.http
            .put<ProgramDTO>(this.SERVER_API_URL + '/programs/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<ProgramDTO>) => res));
    }

    bulkUploadStaff(file: File): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/staff/upload', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    bulkUploadStudent(file: File): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/students/upload', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    bulkUploadRoom(file: File): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.append('fileMetadata', JSON.stringify(document));
        formData.append('file', file);

        return this.http
            .post<any>(this.SERVER_API_URL + '/rooms/upload', formData, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    // createProgramRoom(obj: ProgramDTO): Observable<HttpResponse<ProgramDTO>> {
    //     return this.http
    //         .post<StudentProgramRoomDTO[]>(this.SERVER_API_URL + '/update/student-program-room', obj, {
    //             observe: 'response',
    //         })
    //         .pipe(map((res: HttpResponse<StudentProgramRoomDTO[]>) => res));
    // }
    createProgramRoom(obj: any): Observable<HttpResponse<any>> {
        return this.http
            .post<any>(this.SERVER_API_URL + '/update/student-program-room', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    createLedgerAccount(obj: LedgerAccountDTO): Observable<HttpResponse<LedgerAccountDTO>> {
        return this.http
            .post<LedgerAccountDTO>(this.SERVER_API_URL + '/ledger-accounts', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<LedgerAccountDTO>) => res));
    }
    updateLedgerAccount(obj: LedgerAccountDTO): Observable<HttpResponse<LedgerAccountDTO>> {
        return this.http
            .put<LedgerAccountDTO>(this.SERVER_API_URL + '/ledger-accounts/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<LedgerAccountDTO>) => res));
    }
    deleteLedgerAccount(objVal: LedgerAccountDTO): Observable<HttpResponse<LedgerAccountDTO>> {
        return this.http
            .delete<LedgerAccountDTO>(this.SERVER_API_URL + '/ledger-accounts/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<LedgerAccountDTO>) => res));
    }

    createSeparateEmailSignInPin(obj: GuardianDTO[], studentId: number): Observable<HttpResponse<GuardianDTO>> {
        return this.http
            .post<GuardianDTO>(this.SERVER_API_URL + `/guardians/sign-in-pin/separate/${studentId}`, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<GuardianDTO>) => res));
    }

    createCommonEmailSignInPin(obj: GuardianDTO[], studentId: number): Observable<HttpResponse<GuardianDTO>> {
        return this.http
            .post<GuardianDTO>(this.SERVER_API_URL + `/guardians/sign-in-pin/common/${studentId}`, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<GuardianDTO>) => res));
    }

    createLedgerTransaction(obj: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .post<TransactionDTO>(this.SERVER_API_URL + '/transactions', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    updateLedgerTransaction(obj: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .put<TransactionDTO>(this.SERVER_API_URL + '/transactions/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    deleteLedgerTransaction(objVal: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .delete<TransactionDTO>(this.SERVER_API_URL + '/transactions/' + objVal.id, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    createPhysicalTracker(objVal: PhysicalTrackerDTO): Observable<HttpResponse<PhysicalTrackerDTO>> {
        return this.http.post<PhysicalTrackerDTO>(this.SERVER_API_URL + '/physical-trackers', objVal, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<PhysicalTrackerDTO>) => res));
    }
    updatePhysicalTracker(obj: PhysicalTrackerDTO): Observable<HttpResponse<PhysicalTrackerDTO>> {
        return this.http
            .put<PhysicalTrackerDTO>(this.SERVER_API_URL + '/physical-trackers/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    deletePhysicalTrqacker(obj: PhysicalTrackerDTO): Observable<HttpResponse<PhysicalTrackerDTO>> {
        return this.http.delete<PhysicalTrackerDTO>(this.SERVER_API_URL + '/physical-trackers/' + obj.id, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<PhysicalTrackerDTO>) => res));
    }

    createInvoice(obj: InvoiceDTO): Observable<HttpResponse<InvoiceDTO>> {
        return this.http
            .post<InvoiceDTO>(this.SERVER_API_URL + '/invoices', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<InvoiceDTO>) => res));
    }
    sendInvoice(obj: InvoiceDTO): Observable<HttpResponse<InvoiceDTO>> {
        return this.http
            .post<InvoiceDTO>(this.SERVER_API_URL + '/invoices/send-invoice', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<InvoiceDTO>) => res));
    }
    updateInvoice(obj: InvoiceDTO): Observable<HttpResponse<InvoiceDTO>> {
        return this.http
            .put<InvoiceDTO>(this.SERVER_API_URL + '/invoices/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<InvoiceDTO>) => res));
    }
    createInvoiceItemDetails(obj: InvoiceDetailsDTO): Observable<HttpResponse<InvoiceDetailsDTO>> {
        return this.http
            .post<InvoiceDetailsDTO>(this.SERVER_API_URL + '/invoice-details', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<InvoiceDetailsDTO>) => res));
    }
    updateInvoiceItemDetails(obj: InvoiceDetailsDTO): Observable<HttpResponse<InvoiceDetailsDTO>> {
        return this.http
            .put<InvoiceDetailsDTO>(this.SERVER_API_URL + '/invoice-details/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<InvoiceDTO>) => res));
    }
    deleteInvoiceItemDetails(obj: InvoiceDetailsDTO): Observable<HttpResponse<InvoiceDetailsDTO>> {
        return this.http.delete<InvoiceDetailsDTO>(this.SERVER_API_URL + '/invoice-details/' + obj.id, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<InvoiceDetailsDTO>) => res));
    }

    regenerateSignInPin(obj: any): Observable<HttpResponse<any>> {
        return this.http
            .put<any>(this.SERVER_API_URL + `/guardians/update-pin-and-send-email/${obj.userId}?userType=${obj.userType}&studentId=${obj.studentId}`, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    createMasterLookups(obj: MasterLookupDTO): Observable<HttpResponse<MasterLookupDTO>> {
        return this.http
            .post<MasterLookupDTO>(this.SERVER_API_URL + '/master-lookups', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MasterLookupDTO>) => res));
    }

    updateMasterLookups(obj: MasterLookupDTO): Observable<HttpResponse<MasterLookupDTO>> {
        return this.http
            .put<MasterLookupDTO>(this.SERVER_API_URL + '/master-lookups/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MasterLookupDTO>) => res));
    }

    createTransaction(obj: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .post<TransactionDTO>(this.SERVER_API_URL + '/transactions', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    createStaffSchedule(obj: StaffScheduleDTO): Observable<HttpResponse<StaffScheduleDTO>> {
        return this.http
            .post<StaffScheduleDTO>(this.SERVER_API_URL + '/staff-schedules', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffScheduleDTO>) => res));
    }
    updateStaffSchedule(obj: StaffScheduleDTO): Observable<HttpResponse<StaffScheduleDTO>> {
        return this.http
            .put<StaffScheduleDTO>(this.SERVER_API_URL + '/staff-schedules/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<StaffScheduleDTO>) => res));
    }
    updateTransaction(obj: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .put<TransactionDTO>(this.SERVER_API_URL + '/transactions/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    updatetransactionAll(obj: TransactionDTO,transactionIds : any): Observable<HttpResponse<TransactionDTO>> {
        const studentIdArray = transactionIds.split(',');
        const params = new HttpParams().set('attendanceId', studentIdArray.join(','));
        return this.http
            .put<TransactionDTO>(
                this.SERVER_API_URL + '/transactions/', obj,
                {
                    params: params,
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }
    deleteTransaction(objVal: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .delete<TransactionDTO>(
                this.SERVER_API_URL + '/transactions/' + objVal.id,
                {
                    observe: 'response',
                }
            )
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }

    cancelTransaction(objVal: TransactionDTO): Observable<HttpResponse<TransactionDTO>> {
        return this.http
            .delete<TransactionDTO>(
                this.SERVER_API_URL + '/transactions/cancel-transaction/' + objVal.id,
                {
                    observe: 'response',
                    body: objVal
                }
            )
            .pipe(map((res: HttpResponse<TransactionDTO>) => res));
    }


    sendEvent(obj: StudentDTO[], eventId: number): Observable<HttpResponse<EventDTO>> {
        return this.http
            .post<StudentDTO>(this.SERVER_API_URL + `/events-send/${eventId}`, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<EventDTO>) => res));
    }
    createSchoolProfile(obj: SchoolDTO): Observable<HttpResponse<SchoolDTO>> {
        return this.http
            .post<SchoolDTO>(this.SERVER_API_URL + '/schools', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<MasterLookupDTO>) => res));
    }

    updateSchoolProfile(obj: SchoolDTO): Observable<HttpResponse<SchoolDTO>> {
        return this.http
            .put<SchoolDTO>(this.SERVER_API_URL + '/schools/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<SchoolDTO>) => res));
    }

    patchSchoolProfile(obj: SchoolDTO): Observable<HttpResponse<SchoolDTO>> {
        return this.http
            .patch<SchoolDTO>(this.SERVER_API_URL + '/schools/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<SchoolDTO>) => res));
    }
    updloadReports(file:File , metaData: any):Observable<HttpResponse<any>> {
        const formData = new FormData();
        // formData.append('file', file, `${metaData.fileName}.${metaData.contentType}`); // 'report.pdf' is the filename you want to use
        formData.append("fileMetadata", JSON.stringify(metaData));
        formData.append("file", file);
        const headers = new HttpHeaders()
            .set('Accept', 'application/json');

        return this.http.post<any>(this.SERVER_API_URL + '/documents/upload-reports', formData, {
            // headers: headers,
            // params: {
            //     fileMetadata: JSON.stringify(metaData)
            // },
            observe: 'response',
        })
        .pipe(map((res: HttpResponse<any>) => res));
    }
    createRecurringPlans(obj: RecurringPlansDTO): Observable<HttpResponse<RecurringPlansDTO>> {
        return this.http
            .post<RecurringPlansDTO>(this.SERVER_API_URL + '/recurring-plans', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RecurringPlansDTO>) => res));
    }

    updateRecurringPlans(obj: RecurringPlansDTO): Observable<HttpResponse<RecurringPlansDTO>> {
        return this.http
            .put<RecurringPlansDTO>(this.SERVER_API_URL + '/recurring-plans/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RecurringPlansDTO>) => res));
    }

    deleteRecurringPlans(obj: PhysicalTrackerDTO): Observable<HttpResponse<RecurringPlansDTO>> {
        return this.http.delete<RecurringPlansDTO>(this.SERVER_API_URL + '/recurring-plans/' + obj.id, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<RecurringPlansDTO>) => res));
    }

    createFamily(obj: FamilyDTO): Observable<HttpResponse<FamilyDTO>> {
        return this.http
            .post<FamilyDTO>(this.SERVER_API_URL + '/families', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<FamilyDTO>) => res));
    }

    updateFamily(obj: FamilyDTO): Observable<HttpResponse<FamilyDTO>> {
        return this.http
            .put<FamilyDTO>(this.SERVER_API_URL + '/families/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<FamilyDTO>) => res));
    }

    createRoomMove(obj: any): Observable<HttpResponse<RoomMoveDTO>> {
        return this.http
            .post<RoomMoveDTO>(this.SERVER_API_URL + '/room-moves', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RoomMoveDTO>) => res));
    }

    updateRoomMove(obj: any): Observable<HttpResponse<RoomMoveDTO>> {
        return this.http
            .put<RoomMoveDTO>(this.SERVER_API_URL + '/room-moves/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RoomMoveDTO>) => res));
    }

    deleteRoomMove(obj: RoomMoveDTO): Observable<HttpResponse<RoomMoveDTO>> {
        return this.http.delete<RoomMoveDTO>(this.SERVER_API_URL + '/room-moves/' + obj.id, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<RoomMoveDTO>) => res));
    }

    createFutureEnrollment(obj: FutureEnrollmentDTO): Observable<HttpResponse<FutureEnrollmentDTO>> {
        return this.http
            .post<FutureEnrollmentDTO>(this.SERVER_API_URL + '/future-enrollments', obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<FutureEnrollmentDTO>) => res));
    }

    updateFutureEnrollment(obj: FutureEnrollmentDTO): Observable<HttpResponse<FutureEnrollmentDTO>> {
        return this.http
            .put<FutureEnrollmentDTO>(this.SERVER_API_URL + '/future-enrollments/' + obj.id, obj, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<FutureEnrollmentDTO>) => res));
    }

    deleteFutureEnrollment(obj: FutureEnrollmentDTO): Observable<HttpResponse<FutureEnrollmentDTO>> {
        return this.http.delete<FutureEnrollmentDTO>(this.SERVER_API_URL + '/future-enrollments/' + obj.id, {
            observe: 'response',
        })
            .pipe(map((res: HttpResponse<FutureEnrollmentDTO>) => res));
    }


    deleteMultiFamily(famId: any): Observable<HttpResponse<FamilyDTO>> {
        return this.http
            .delete<any>(this.SERVER_API_URL + '/families/' + famId, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    updateMultiFamily(family : FamilyDTO): Observable<HttpResponse<FamilyDTO>> {
        return this.http
            .put<any>(this.SERVER_API_URL + '/families/' + family.id,family, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

   
    deleteStaffSchedule(scheduleId: any): Observable<HttpResponse<ScheduleDTO>> {
        return this.http
            .delete<any>(this.SERVER_API_URL + '/staff-schedules/' + scheduleId, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }

    deleteStaffProfileSchedule(request: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('scheduleId', request.scheduleId)
            .set('deleteType', request.deleteType);

        return this.http
            .delete<any>(this.SERVER_API_URL + '/delete-schedules', {
                params: params,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<any>) => res));
    }
    

}
