import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appLowercaseLetter]'
})
export class LowercaseLetterDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input') onInput() {
    const inputValue = this.el.nativeElement.value;
    
    if (this.control && this.control.control) {
      this.control.control.setValue(this.convertToLowercase(inputValue), { emitEvent: false });
    }
  }

  private convertToLowercase(value: string): string {
    return value.toLowerCase();
  }
}
