<p-toolbar [style]="{'box-shadow': '0px 3px 4px rgba(0, 0, 0, 0.1), 0px 2px 36px rgba(0, 0, 0, 0.04)'}">
    <ng-template pTemplate="left">
        <div class="container">
            <div class="row p-0">
                <div class="flex mt-1 block px-3" *ngFor="let student of this.incomingFamilyDetails.childs">
                    <img *ngIf="student.profileimg != undefined; else noImageFound" [src]="student.profileimg"
                        width="43" height="43" alt="profile"
                        class="border-circle border-2 border-primary flex-shrink-0 shadow-4" />
                    <ng-template #noImageFound>
                        <a>
                            <img src="assets/layout/images/male.png" alt="Fallbackimage" width="43" height="43"
                                class="border-circle border-2 border-primary flex-shrink-0 shadow-4" />
                        </a>
                    </ng-template>
                    <div class="ml-2 mt-3">
                        <div class="text-base font-bold text-900 mb-0">
                            <a class="student-name" [routerLink]="['/myschool/student/profile/' +student.id]">{{
                                student.fullName }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-1">
                <div>
                    <span class="text-base font-semibold text-neutral-600">Parents:</span>&nbsp;
                    <ng-container *ngFor="let p of this.incomingFamilyDetails.parents;let last = last;">
                        <span class="text-base font-medium text-neutral-900 mr-">{{p.fullName}}</span>
                        <ng-container *ngIf="!last">,</ng-container>
                        <ng-container *ngIf="last">.</ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="row py-1">
                <div>
                    <span class="text-lg font-semibold text-neutral-400 mr-2">Paid:</span>
                    <span class="text-lg font-semibold text-green-300 mr-2">${{paidAmount}}</span>
                    <span class="text-lg font-semibold text-neutral-400 mr-2"> | </span>

                    <span class="text-lg font-semibold text-neutral-400 mr-2">In Progress:</span>
                    <span class="text-lg font-semibold text-neutral-400 mr-2">${{inProgressAmount}}</span>
                    <span class="text-lg font-semibold text-neutral-400 mr-2"> | </span>

                    <span class="text-lg font-semibold text-neutral-400 mr-2">Un-Paid:</span>
                    <span class="text-lg font-semibold text-red-300 mr-2">${{ this.unPaidAmount != null ? this.unPaidAmount : 0 }}</span>
                </div>

            </div>
        </div>

      

    </ng-template>
</p-toolbar>           
 <p-confirmDialog [closable]="true" key="keyofCancel"></p-confirmDialog>
<div class="col-12 px-0">
    <p-table #dt [value]="familyTransactionList" [lazy]="true" (onLazyLoad)="loadTransacList($event)" dataKey="id"
        [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="pageContext.itemsPerPage"
        [totalRecords]="pageContext.totalItems" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {{
        pageContext.totalItems > 0 ? first + 1 : first
    }} to {{ last }} of {{ pageContext.totalItems }} entries">
        <ng-template pTemplate="header">
            <tr>
                <th>Date </th>
                <th>Type</th>
                <th>Description</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Balance</th>
                <th class="text-center">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-transaction let-rowIndex="rowIndex">
            <tr *ngIf="transaction.dummyInvoice == undefined;else elseRow;">
                <td>
                    <span class="text-base font-semibold">
                        {{transaction.date | momentDate}}
                    </span>
                    <br />
                    <span class="stud-birthday-label">
                        {{transaction.date | date:'shortTime':'UTC'}}
                    </span>
                </td>
                <td class="payment-status">
                    <span class="text-base font-semibold">
                        {{transaction.transactionType}}
                    </span>
                    <br />
                    <span class="stud-birthday-label" *ngIf="transaction.transactionType != 'Credit'">
                        {{transaction.paymentMode}}
                    </span>
                </td>
                <td *ngIf="transaction.paymentMode != 'Cheque';else chequeBlock">
                    <span pTooltip="{{ transaction.description}}" tooltipPosition="bottom"
                        tooltipStyleClass="tooltip-style">{{transaction.description}}</span>
                    &nbsp;
                    <span class="resetIcon mr-4" (click)="op.toggle($event)">
                        <i *ngIf="transaction.note != undefined" class="pi pi-file"
                            style="color: rgb(166, 168, 8)"></i>
                    </span>
                    <p-overlayPanel #op [style]="{'padding':'0rem'}">
                        <ng-template pTemplate="content">
                            <span class="stud-birthday-label">{{transaction.note}}</span>
                        </ng-template>
                    </p-overlayPanel>

                </td>
                <ng-template #chequeBlock>
                    <td>
                        <span pTooltip="Cheque Number : {{transaction.chequeNumber}}" tooltipPosition="bottom"
                            tooltipStyleClass="tooltip-style">Cheque Number: {{transaction.chequeNumber}}</span>
                    </td>
                </ng-template>
                <td class="text-base font-medium text-black">
                    <i *ngIf="transaction.processStatus == 'Success' || transaction.processStatus == 'Sent' || transaction.processStatus == 'Refund'  ;else elseIconBlock"
                        class="pi pi-verified" style="color: green"></i>
                    <ng-template #elseIconBlock>
                        <i *ngIf="transaction.processStatus == 'Cancelled';else iconBlock"
                            class="pi pi-times-circle" style="color: rgb(168, 85, 8)"></i>
                        <ng-template #iconBlock>
                            <i class="pi pi-exclamation-triangle" style="color: rgb(166, 168, 8)"></i>
                        </ng-template>
                    </ng-template>
                    &nbsp;
                    {{ transaction.processStatus === 'Created' ? 'Not Sent' : transaction.processStatus }}
                </td>
                <td class="balance-amount text-base font-semibold text-black">
                    <span *ngIf="transaction.processStatus != 'Refund';else refundBlock">
                        $-{{transaction.amount}}
                    </span>
                    <ng-template #refundBlock>
                        <span>
                            ${{transaction.amount}}
                        </span>
                    </ng-template>

                </td>
                <td class="balance-amount text-base font-semibold text-cyan-300">
                    ${{transaction.localBalanceAmount}}
                </td>

                <td class="actions"
                    *ngIf="transaction.transactionType != 'Refund' && transaction.processStatus != 'Cancelled';else transactionDeleteBlock">
                    <div class="flex align-items-center justify-content-center mb-3"
                        *ngIf="transaction.transactionType == 'Payment';else creditBlock">
                        <div class="flex justify-content-center w-100">
                            <i class="pi pi-ellipsis-v threeDot pointer" (click)="
                                menu2.toggle($event);
                                getmenuItemsPayment(transaction,$event)
                            "></i>
                            <p-menu #menu2 [popup]="true" appendTo="body" [model]="menuItemsPayment"></p-menu>
                        </div>
                    </div>
                    <ng-template #creditBlock>
                        <div class="flex align-items-center justify-content-center mb-3">
                            <div class="flex justify-content-center w-100">
                                <i class="pi pi-ellipsis-v threeDot pointer" (click)="
                                        menu4.toggle($event);
                                        getmenuItemsCredit(transaction,$event)
                                    "></i>
                                <p-menu #menu4 [popup]="true" appendTo="body" [model]="menuItemsCredit"></p-menu>
                            </div>
                        </div>
                    </ng-template>
                </td>
                <ng-template #transactionDeleteBlock>
                    <td class="actions" *ngIf="transaction.processStatus == 'Cancelled';else refundBlock">
                        <div class="flex align-items-center justify-content-center mb-3">
                            <div class="flex justify-content-center w-100">
                                <i class="pi pi-ellipsis-v threeDot pointer" (click)="
                                    menu5.toggle($event);
                                    getmenuItemsDelete(transaction,$event)
                                "></i>
                                <p-menu #menu5 [popup]="true" appendTo="body" [model]="menuItemsDelete"></p-menu>
                            </div>
                        </div>
                    </td>
                    <ng-template #refundBlock>
                        <td class="actions">

                        </td>
                    </ng-template>
                </ng-template>

            </tr>
            <ng-template #elseRow>
                <tr>
                    <td>
                        <span class="text-base font-semibold">
                            {{transaction.dummyInvoice.date | momentDate}}
                        </span>
                        <br />
                        <span class="stud-birthday-label">
                            {{transaction.dummyInvoice.date | date:'shortTime':'UTC'}}
                        </span>
                    </td>
                    <td class="payment-status">
                        <span class="text-base font-semibold">
                            {{transaction.transactionType}}
                        </span>
                        <br />
                        <span class="stud-birthday-label">
                            {{transaction.dummyInvoice.dueDate | momentDate}}
                        </span>
                    </td>
                    <td>
                        <ng-container
                            *ngFor="let dummyInvoiceItem of transaction?.dummyInvoice?.dummyInvoiceItems?.slice(0, 1); let isFirst = first">
                            <span pTooltip="{{ dummyInvoiceItem.description }}" tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-style">{{dummyInvoiceItem.description}}</span>
                        </ng-container>
                        &nbsp;
                        <ng-container *ngIf="transaction?.dummyInvoice?.dummyInvoiceItems?.length >1 ">
                            <p-avatar shape="circle" (mouseenter)="hoverChip = false"
                                (mouseleave)="hoverChip = false"
                                pTooltip="{{ getMultipleTagsTooltip(transaction?.dummyInvoice.dummyInvoiceItems)  }}"
                                tooltipPosition="bottom" tooltipStyleClass="tooltip-style">
                                +{{ transaction?.dummyInvoice.dummyInvoiceItems?.length - 1 }}
                            </p-avatar>
                        </ng-container>
                        &nbsp;
                        <span class="resetIcon mr-4" (click)="opp.toggle($event)">
                            <i *ngIf="transaction.dummyInvoice.note != undefined" class="pi pi-file"
                                style="color: rgb(166, 168, 8)"></i>
                        </span>
                        <p-overlayPanel #opp [style]="{'padding':'0rem'}">
                            <ng-template pTemplate="content">
                                <span class="stud-birthday-label">{{transaction.dummyInvoice.note}}</span>
                            </ng-template>
                        </p-overlayPanel>
                    </td>
                    <td class="text-base font-medium text-black">
                        <i *ngIf="transaction.processStatus == 'Success' || transaction.processStatus == 'Sent' ;else elseIconBlock"
                            class="pi pi-verified" style="color: green"></i>
                        <ng-template #elseIconBlock>
                            <i *ngIf="transaction.processStatus == 'Cancelled';else iconBlock"
                                class="pi pi-times-circle" style="color: rgb(168, 85, 8)"></i>
                            <ng-template #iconBlock>
                                <i class="pi pi-exclamation-triangle" style="color: rgb(166, 168, 8)"></i>
                            </ng-template>
                        </ng-template>
                        &nbsp;
                        {{ transaction.processStatus === 'Created' ? 'Not Sent' : transaction.processStatus }}
                    </td>
                    <td class="balance-amount text-base font-semibold text-black">
                        ${{transaction.dummyInvoice.invoiceAmount}}
                    </td>
                    <td class="balance-amount text-base font-semibold text-cyan-300">
                        ${{transaction.localBalanceAmount}}
                    </td>
                    <td class="actions">
                        <div class="flex align-items-center justify-content-center mb-3"
                            *ngIf="transaction.processStatus != 'Cancelled';else invoiceDeleteBlock">
                            <div class="flex justify-content-center w-100">
                                <i class="pi pi-ellipsis-v threeDot pointer" (click)="
                                    menu3.toggle($event);
                                    getmenuItemsInvoice(transaction.dummyInvoice,$event)
                                "></i>
                                <p-menu #menu3 [popup]="true" appendTo="body" [model]="menuItemsInvoice"></p-menu>
                            </div>
                        </div>
                        <ng-template #invoiceDeleteBlock>
                            <div class="flex align-items-center justify-content-center mb-3">
                                <div class="flex justify-content-center w-100">
                                    <i class="pi pi-ellipsis-v threeDot pointer" (click)="
                                            menu6.toggle($event);
                                            getmenuItemsDelete(transaction.dummyInvoice,$event)
                                        "></i>
                                    <p-menu #menu6 [popup]="true" appendTo="body"
                                        [model]="menuItemsDelete"></p-menu>
                                </div>
                            </div>
                        </ng-template>
                    </td>

                </tr>
            </ng-template>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6" style="
                    text-align: -webkit-center;
                    font-weight: bold;
                ">
                    No records
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>