import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberInput]'
})
export class NumberInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.trim();

    // Remove non-numeric characters except for the decimal point
    const cleanedValue = inputValue.replace(/[^0-9.]/g, '');

    // Update the input value with the cleaned value
    inputElement.value = cleanedValue;
  }
}
