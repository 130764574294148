import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WebStorage } from 'src/app/demo/components/auth/storage/web.storage';
import { ITEMS_PER_PAGE } from 'src/app/demo/constants/pagination.constants';
import { AttendanceDTO } from 'src/app/demo/model/AttendanceDTO';
import { RoomDTO } from 'src/app/demo/model/RoomDTO';
import { StaffDTO } from 'src/app/demo/model/StaffDTO';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { TagDTO } from 'src/app/demo/model/TagDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';
import { Common } from 'src/app/demo/utils/common';

@Component({
    selector: 'app-student-list',
    templateUrl: './student-list.component.html',
    styleUrls: ['./student-list.component.scss'],
})
export class StudentListComponent {
    studentList: StudentDTO[] = [];
    totalRecords: number = 0;
    search: any = {};
    pageContext = {
        page: 0,
        previousPage: 0,
        itemsPerPage: 100,
        totalItems: 0,
        sort: 'id,desc',
    };
    first: number = 0;
    last: number = 0;
    selectedStudents: StudentDTO[] = [];
    incomingStudentList: StudentDTO[] = [];
    roomList: RoomDTO[] = [];
    tagList: TagDTO[] = [];
    selectedTags: any[] = [];
    incomingStudentListDate: any;
    sortField: string = '';
    selectedStudentIds: (number | undefined)[] = [];
    selectAllStudents: boolean | undefined;
    attendanceList!: AttendanceDTO[];
    selectedFullName: any = undefined;
    selectedRoomName: any = undefined;
    selectedTagName: any = undefined;
    incomingStudentId: number;
    incomingRoomId: any;
    userData: any;
    staffObj!: StaffDTO;
    @ViewChild('dataviewHtml') dataView!: DataView;
    constructor(
        private lookupService: LookupService,
        private operationService: OperationService,
        private sanitizer: DomSanitizer,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private datePipe: DatePipe,
        private storage: WebStorage
    ) {
        this.incomingStudentList = config.data.studentList;
        this.incomingStudentListDate =
            this.datePipe.transform(config.data.date, 'yyyy-MM-dd') +
            'T00:00:00Z';
        this.incomingStudentId = config.data.studentId;
        this.incomingRoomId = config.data.roomId;
    }

    ngOnInit() {
        this.userData = this.storage.getUserData();
        this.lookupService
            .queryRooms({ size: ITEMS_PER_PAGE })
            .subscribe((res: HttpResponse<RoomDTO[]>) => {
                let temp: RoomDTO[] = [];
                this.roomList = res.body ? res.body : temp;
            });

        this.lookupService
            .queryTag({ size: ITEMS_PER_PAGE,sort : 'name,asc' })
            .subscribe((res: HttpResponse<TagDTO[]>) => {
                let temp: TagDTO[] = [];
                this.tagList = res.body ? res.body : temp;
            });

        this.queryData();
    }

    // loadStudent(event: any) {
    //     if (event.first > event.rows) {
    //         this.pageContext.page = event.first / event.rows;
    //     } else if (event.first == event.rows) {
    //         this.pageContext.page = event.first / event.rows;
    //     } else {
    //         this.pageContext.page = 0;
    //     }
    //     this.first = event.first;
    //     this.search = {};
    //     this.last = this.pageContext.page * event.rows;
    //     const sortOd = event.sortOrder == 1 ? 'asc' : 'desc';
    //     const sortFld = event.sortField != undefined ? event.sortField : 'id';
    //     this.pageContext.sort = `${sortFld},${sortOd}`;
    //     this.pageContext.itemsPerPage = event.rows;
    //     this.queryData();
    // }

    queryData() {
        this.search['schoolId.equals'] = this.userData.schoolId;
        this.search['status.equals'] = 'A';
        this.search['date.equals'] = this.incomingStudentListDate;
        this.search['studentId.equals'] = this.incomingStudentId;
        this.search['roomId.in'] = this.incomingRoomId;
        this.search.size = 100;
        Common.removeEmptyFields(this.search);
        this.lookupService
            .queryPresentStudent(this.search)
            .subscribe((res: HttpResponse<StudentDTO[]>) => {
                let temp: StudentDTO[] = [];
                if (this.selectedFullName !== undefined) {
                    const partialName = this.selectedFullName.toLowerCase();
                    this.studentList = (res.body?.filter((item) =>
                        item.fullName?.toLowerCase().includes(partialName)
                    ) ?? []) as StudentDTO[];
                } else if (this.selectedRoomName !== undefined) {
                    this.studentList = (res.body?.filter(
                        (item) => item.roomId === this.selectedRoomName
                    ) ?? []) as StudentDTO[];
                } else if (this.selectedTagName !== undefined) {
                    this.studentList = (res.body?.filter((item) =>
                        item.tags?.some((tag) =>
                            this.selectedTagName.includes(tag.id)
                        )
                    ) ?? []) as StudentDTO[];
                } else {
                this.studentList = res.body || temp;
                }

                // this.incomingStudentList =
                //     this.incomingStudentId != undefined ? this.studentList : [];
                if (this.incomingStudentId != undefined) {
                    this.incomingStudentList = this.studentList;
                    this.selectedStudents = this.studentList;
                }
                if (
                    this.incomingStudentList &&
                    this.incomingStudentList.length > 0
                ) {
                    this.studentList.forEach((el: StudentDTO) => {
                        el.selected = !!this.incomingStudentList.find(
                            (item: StudentDTO) => el.id === item.id
                        );
                    });

                    this.selectAllStudents =
                        this.studentList.length ===
                        this.incomingStudentList.length;
                }

                // this.pageContext.totalItems = Number(
                //     res.headers.get('X-Total-Count')
                // );
                this.pageContext.totalItems = this.studentList.length;
                this.selectedStudents = this.incomingStudentList;
                this.last =
                    this.search.page != 0
                        ? this.last + this.studentList.length
                        : this.studentList.length;

                this.studentList.forEach((element: StudentDTO, index) => {
                    this.search.size = ITEMS_PER_PAGE;
                    this.lookupService
                        .queryAllDocuments({
                            'docType.equals': 'PROFILE',
                            'refTable.equals': 'Student',
                            'refTableId.equals': element.id,
                        })
                        .subscribe((req: Blob) => {
                            let reader = new FileReader();
                            reader.addEventListener(
                                'load',
                                () => {
                                    let imageToShow: string | ArrayBuffer;
                                    if (reader.result) {
                                        imageToShow = reader.result;
                                        element.profileimg =
                                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                                '' + imageToShow
                                            );
                                    } else {
                                        return;
                                    }
                                },
                                false
                            );

                            if (req.size != 0) {
                                reader.readAsDataURL(req);
                            } else {
                                element.profileimg = undefined;
                            }
                        });
                });
            });
    }

    getSeverity(status: string) {
        switch (status) {
            case 'A':
                return `success`;
            case 'I':
                return `danger`;
            case 'D':
                return 'warning';
            default:
                return '';
        }
    }

    getStatus(status: string) {
        switch (status) {
            case 'A':
                return 'Active';
            case 'I':
                return 'Inactive';
            case 'D':
                return 'Deleted';
            default:
                return '';
        }
    }

    selectedStudentsMethod() {
        this.ref.close(this.selectedStudents);
    }

    filterRoom(event: any) {
        let filter = event.filter;
        this.search = {};
        this.search.size = this.pageContext.itemsPerPage;
        this.search.page = this.pageContext.page;
        this.search.sort = this.pageContext.sort;
        this.search['name.contains'] = filter;
        Common.removeEmptyFields(this.search);
        this.lookupService
            .queryRooms(this.search)
            .subscribe((res: HttpResponse<RoomDTO[]>) => {
                let temp: RoomDTO[] = [];
                this.roomList = res.body ? res.body : temp;
            });
        this.search = {};
    }

    filterTag(event: any) {
        let filter = event.filter;
        this.search = {};
        this.search.size = this.pageContext.itemsPerPage;
        this.search.page = this.pageContext.page;
        this.search.sort = this.pageContext.sort;
        this.search['name.contains'] = filter;
        Common.removeEmptyFields(this.search);
        this.lookupService
            .queryTag(this.search)
            .subscribe((res: HttpResponse<TagDTO[]>) => {
                let temp: TagDTO[] = [];
                this.tagList = res.body ? res.body : temp;
            });
        this.search = {};
    }

    toggleSelection(student: any) {
        const index = this.selectedStudents.findIndex(
            (selected) => selected.id === student.id
        );
        if (index !== -1) {
            this.selectedStudents.splice(index, 1);
        } else {
            this.selectedStudents.push(student);
        }
        this.selectedStudentIds = this.selectedStudents.map(
            (selected) => selected.id
        );
    }

    isSelected(student: StudentDTO): boolean {
        return this.selectedStudents.includes(student);
    }

    toggleAllSelection() {
        this.studentList.forEach(
            (student) => (student.selected = this.selectAllStudents)
        );

        if (this.selectAllStudents) {
            this.selectedStudents = [...this.studentList];
        } else {
            this.selectedStudents = [];
        }
        this.selectedStudentIds = this.selectedStudents.map(
            (selected) => selected.id
        );
    }

    onSearch() {
        this.selectedFullName = undefined;
        this.selectedRoomName = undefined;
        this.selectedTagName = undefined;
        this.queryData();
    }
}
