import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { WebStorage } from './demo/components/auth/storage/web.storage';
import { Event, NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { LookupService } from './demo/service/lookup.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    urlComplete = {
        mainUrl: '',
        subUrl: '',
        childUrl: '',
    };
    isLoggedIn: boolean = false;
    public userloaded = false;
    constructor(
        private primengConfig: PrimeNGConfig,
        private webStorage: WebStorage,
        private router: Router,
        protected readonly keycloak: KeycloakService,
        private lookupService: LookupService,
        
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const body = document.getElementsByTagName('body')[0];
                const url = event.url.split('/');
                this.urlComplete.mainUrl = url[1];
                this.urlComplete.subUrl = url[2];
                this.urlComplete.childUrl = url[3];
                if (
                    url[1] === '' ||
                    url[1] === 'login' ||
                    url[1] === 'login' ||
                    url[2] === 'register' ||
                    url[2] === 'forgot' ||
                    url[2] === 'otp' ||
                    url[2] === 'lockscreen'
                ) {
                    body.classList.add('account-page');
                } else {
                    body.classList.remove('account-page');
                }
                if (url[2] === 'error404' || url[2] === 'error500') {
                    body.classList.add('error-page');
                } else {
                    body.classList.remove('error-page');
                }
            }
        });
    }

    async ngOnInit() {
        // this.primengConfig.ripple = true;
        // this.isLoggedIn = await this.keycloak.isLoggedIn();
        // if (this.isLoggedIn) {
        //     forkJoin({
        //         sso: this.keycloak.loadUserProfile(),
        //         account: this.lookupService.queryAccount().toPromise(),
        //     }).subscribe(({ sso, account }) => {
        //         // console.log('account ::' +JSON.stringify(account));
        //         this.webStorage.login(sso, account?.body);
        //     });
        // }
        const logValue = localStorage.getItem('logQueryParam');        
        const isLoggedIn = await this.keycloak.isLoggedIn();        
        if (isLoggedIn) {
            const refTable = logValue === 'Staff' || logValue === null ? 'Staff' : 'Guardian';
            this.lookupService.queryAccount({ 'refTable': refTable }).subscribe((account: any) => {
                this.webStorage.login(account.body);
                this.userloaded = true;
            });
        
        }


    }
}
