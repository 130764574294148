<div>
    <!-- <p-dataView
        [value]="studentList"
        selectionMode="multiple"
        filterBy="fullName"
        [sortField]="sortField"
        [sortOrder]="-1"
        layout="grid"
        [rows]="6" [paginator]="true"
        #dataviewHtml
    > -->
    <!-- <p-dataView
    [value]="studentList" [lazy]="true" (onLazyLoad)="loadStudent($event)"
    dataKey="id"  [paginator]="true" [rows]="pageContext.itemsPerPage"
    [totalRecords]="pageContext.totalItems" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true" layout="grid" selectionMode="multiple"
    currentPageReportTemplate="Showing {{
    pageContext.totalItems > 0 ? first + 1 : first }} to {{ last }} of {{ pageContext.totalItems }} entries"
    >
    <ng-template pTemplate="header">
        <div class="row pr-3 pl-3">
            <div class="d-flex flex-wrap align-items-center">

                <p-checkbox
                [binary]="true"
                [(ngModel)]="selectAllStudents"
                (onChange)="toggleAllSelection()"
                inputId="selectAll"
            ></p-checkbox>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input pInputText id="studentUniqueId" class="mr-2 mb-2" type="text"
            [(ngModel)]="selectedFullName" (input)="queryData()" placeholder="Search Student" />
            &nbsp;&nbsp;&nbsp;&nbsp;
        <p-dropdown
            appendTo="body"
            class="custom-dropdown-one mr-2 mb-2"
            optionLabel="name"
            [(ngModel)]="selectedRoomName"
            filterBy="name"
            [options]="roomList"
            optionValue="id"
            (onFilter)="filterRoom($event)"
            (ngModelChange)="queryData()"
            [filter]="true"
            [showClear]="true"
            [placeholder]="'Select Room'"
            #roomNameDropdown
        >
        </p-dropdown>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <p-multiSelect
            appendTo="body"
            class="custom-dropdown-one mr-2 mb-2"
            optionLabel="name"
            [(ngModel)]="selectedTagName"
            filterBy="name"
            [options]="tagList"
            optionValue="id"
            (onFilter)="filterTag($event)"
            (ngModelChange)="queryData()"
            [filter]="true"
            [showClear]="true"
            placeholder="Select Tag"
            #tagNameDropdown
        >
        </p-multiSelect>
        &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div class="col"></div>
            <div class="d-flex flex-wrap justify-content-end">
                <div class="mt-3">
                    <div class="input-with-icon">
                        <span class="resetIcon mr-4" (click)="onSearch()">
                            <i class="pi pi-replay pointer" title="Reset"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
        <ng-template let-student pTemplate="gridItem">
            <div class="col-12 md:col-4 dataView">
                <div class="p-3">
                    <div class="grid formgrid">
                        <div class="col-12 md:col-4">
                            <div class="field-checkbox">
                                <p-checkbox
                                    [binary]="true"
                                    [(ngModel)]="student.selected"
                                    [value]="student"
                                    (onChange)="
                                        toggleSelection(student);
                                        isSelected(student)
                                    "
                                    class="custom-margin">
                                  </p-checkbox>
                                &nbsp;

                                <div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-3 gap-3">
                                    <div class="flex align-items-center">
                                        <img
                                            *ngIf="student.profileimg != undefined; else noImageFound"
                                            [src]="student.profileimg"
                                            width="50"
                                            height="50"
                                            alt="profile"
                                            class="border-circle border-2 border-primary flex-shrink-0 rounded-full"/>
                                        <ng-template #noImageFound>
                                            <a>
                                                <img
                                                    src="assets/layout/images/male.png"
                                                    alt="Fallbackimage"
                                                    width="50"
                                                    height="50"
                                                    class="border-circle border-2 border-primary flex-shrink-0 rounded-full"/>
                                            </a>
                                        </ng-template>
                                        <div class="ml-3">
                                            <span class="text-m font-light"><span class="font-normal">
                                                <b>{{student!.fullName}}</b></span></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView> -->

    <p-dataView [value]="studentList" [rows]="10" filterBy="fullName" [sortField]="sortField" [sortOrder]="-1"
        layout="grid" [paginator]="true" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 20, 30]" emptyMessage="No records" style='text-align: center; font-weight: 600;'>


        <ng-template pTemplate="header">
            <div class="row pr-3 pl-3">
                <div class="d-flex flex-wrap align-items-center">

                    <!-- <p-checkbox [binary]="true" [(ngModel)]="selectAllStudents" (onChange)="toggleAllSelection()"
                        inputId="selectAll"></p-checkbox> -->
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input pInputText id="studentUniqueId" class="mr-2 mb-2" type="text" [(ngModel)]="selectedFullName"
                        (input)="queryData()" placeholder="Search Student" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <!-- <p-dropdown
            appendTo="body"
            class="custom-dropdown-one mr-2 mb-2"
            optionLabel="name"
            [(ngModel)]="selectedRoomName"
            filterBy="name"
            [options]="roomList"
            optionValue="id"
            (onFilter)="filterRoom($event)"
            (ngModelChange)="queryData()"
            [filter]="true"
            [showClear]="true"
            [placeholder]="'Select Room'"
            #roomNameDropdown
        >
        </p-dropdown> -->
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <p-multiSelect appendTo="body" class="custom-dropdown-one mr-2 mb-2" optionLabel="name"
                        [(ngModel)]="selectedTagName" filterBy="name" [options]="tagList" optionValue="id"
                         (ngModelChange)="queryData()" [filter]="true"
                        placeholder="Select Tag" #tagNameDropdown>
                    </p-multiSelect>
                    <!-- &nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="input-with-icon">
                        <span class="resetIcon mr-4" (click)="onSearch()">
                            <i class="pi pi-replay pointer" title="Reset"></i>
                        </span>
                    </div> -->
                </div>
                <!-- <div class="col"></div>
                <div class="d-flex flex-wrap justify-content-end">
                    <div class="mt-3">
                        <div class="input-with-icon">
                            <span class="resetIcon mr-4" (click)="onSearch()">
                                <i class="pi pi-replay pointer" title="Reset"></i>
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="flex align-items-center" style="display: flex; justify-content: space-between;">
                <div class="flex align-items-center">
                    <p-checkbox [binary]="true" [(ngModel)]="selectAllStudents" (onChange)="toggleAllSelection()"
                    inputId="selectAll"></p-checkbox>
                  <label for="selectAllStudent" class="mt-2 ml-2">Select All</label>
                </div>
                <p style="text-align: right;" *ngIf="selectedStudents.length>0">{{selectedStudents.length }} Students Selected</p>
            </div>
        </ng-template>
        <ng-template let-student pTemplate="gridItem">
            <div class="col-12 md:col-4 dataView">
                <div class="p-3">
                    <div class="grid formgrid">
                        <div class="col-12 md:col-4">
                            <div class="field-checkbox">
                                <p-checkbox [binary]="true" [(ngModel)]="student.selected" [value]="student" (onChange)="
                                        toggleSelection(student);
                                        isSelected(student)
                                    " class="custom-margin">
                                </p-checkbox>
                                &nbsp;

                                <div
                                    class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-3 gap-3">
                                    <div class="flex align-items-center">
                                        <img *ngIf="student.profileimg != undefined; else noImageFound"
                                            [src]="student.profileimg" width="50" height="50" alt="profile"
                                            class="border-circle border-2 border-primary flex-shrink-0 rounded-full" />
                                        <ng-template #noImageFound>
                                            <a>
                                                <img src="assets/layout/images/male.png" alt="Fallbackimage" width="50"
                                                    height="50"
                                                    class="border-circle border-2 border-primary flex-shrink-0 rounded-full" />
                                            </a>
                                        </ng-template>
                                        <div class="ml-3">
                                            <span class="text-m font-light"><span class="font-normal">
                                                    <b>{{student!.fullName}}</b></span></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7" style="text-align: -webkit-center; font-weight: bold">
                    No records
                </td>
            </tr>
        </ng-template>
    </p-dataView>

    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-end">
                <p-button label="Save" id="save" (onClick)="selectedStudentsMethod()"
                    styleClass="p-button-success p-button-raised"></p-button>
            </div>
        </div>
    </div>
</div>


<!-- <p-table #dt [value]="studentList" [(selection)]="selectedStudents" [lazy]="true" (onLazyLoad)="loadStudent($event)"
    dataKey="id" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="pageContext.itemsPerPage"
    [totalRecords]="pageContext.totalItems" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {{
    pageContext.totalItems > 0 ? first + 1 : first }} to {{ last }} of {{ pageContext.totalItems }} entries">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
            Students
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="fullName">
                Student Name <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
                Status <p-sortIcon field="status"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-student let-rowIndex="rowIndex">
        <tr>
            <td>
                <p-tableCheckbox [value]="student"></p-tableCheckbox>
            </td>
            <td>
                <img [alt]="student!.fullName" class="inline-block profileImage border-circle border-2 border-primary" [src]="student.profileimg" width="32" *ngIf="student.profileimg != undefined ; else noImageFound" style="vertical-align: middle" />
                <ng-template #noImageFound>
                    <img class="inline-block profileImage border-circle border-2 border-primary" width="32"
                        src="assets/layout/images/userIcon.png" alt="Fallbackimage">
                </ng-template>
                <span class="ml-1 vertical-align-middle">{{ student.fullName }}</span>
            </td>
            <td>
                <span class="p-column-title">Status</span>
                <p-tag [value]="getStatus(student.status)" severity="{{ getSeverity(student.status) }}"
                    class="tag"></p-tag>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6" style="text-align: -webkit-center; font-weight: bold">
                No records
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="border-top-3 border-blue-600"></div>
<div style="margin-top: 5px; text-align: end;">
    <p-button icon="pi pi-check" label="Save" id="save"styleClass="p-button-success p-button-raised" (onClick)="selectedStudentsMethod()"></p-button>
</div> -->
