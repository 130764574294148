import { Directive, Input, ElementRef, SimpleChanges } from "@angular/core";


@Directive({
    selector: '[appAsterisk]',
})
export class AsteriskDirective {
    @Input() appAsterisk!: boolean;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appAsterisk'] && changes['appAsterisk'].currentValue === true) {
      this.addAsterisk();
    } else {
      this.removeAsterisk();
    }
  }

  private addAsterisk() {
    const asterisk = document.createElement('span');
    asterisk.innerHTML = '*';
    asterisk.style.color = 'red'; // You can customize the asterisk appearance here
    asterisk.style.marginLeft = '5px';

    this.el.nativeElement.appendChild(asterisk);
  }

  private removeAsterisk() {
    const asterisk = this.el.nativeElement.querySelector('span');
    if (asterisk) {
      asterisk.remove();
    }
  }
}
