import { Component, Input, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-form-field-validation',
    templateUrl: './form-field-validation.component.html',
    styleUrls: ['./form-field-validation.component.scss'],
})
export class FormFieldValidationComponent {
    @Input() control!: AbstractControl;

    getErrorMessage(errorName: string): string {
        const fieldName = this.getActiveFieldName();
        let minLentghForPhoneNumber =fieldName !='phoneNumber'?this.control.getError('minlength')?.requiredLength : 10;
        const errorMessages: any = {
            required: 'This field is required.',
            minlength: `This field must be at least ${
              minLentghForPhoneNumber
            } ${fieldName!='phoneNumber'?'characters long.':'digit.'} `,
            maxlength: `This field must be less than ${
                this.control.getError('maxlength')?.requiredLength
            } characters long.`,
            pattern: `Please Enter Valid ${fieldName}.`,
        };
        return errorMessages[errorName];
    }

    private getActiveFieldName(): string {
        const formGroup:any = this.control && this.control.parent;
        if (formGroup) {
          for (const field in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(field) && this.control === formGroup.controls[field]) {
              return field;
            }
          }
        }
        return '';
      }
}
