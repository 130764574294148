import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

    transform(value: string | undefined): string {
        if (value) {
            const time = value.split(':');
            let hours = parseInt(time[0], 10);
            const minutes = time[1];
            let period = 'AM';

            if (hours >= 12) {
                period = 'PM';
            }

            if (hours > 12) {
                hours -= 12;
            } else if (hours === 0) {
                hours = 12;
            }

            return `${hours}:${minutes} ${period}`;
        }

        return ''; // Handle undefined values or provide a default value here
    }
}
