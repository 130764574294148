import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newTimeFormat'
})
export class NewTimeFormat implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }

        const [hours, minutes, seconds] = value.split(':');
        const formattedHours = parseInt(hours, 10) > 0 ? `${parseInt(hours, 10)} hour${parseInt(hours, 10) > 1 ? 's' : ''}` : '';
        const formattedMinutes = parseInt(minutes, 10) >= 0 ? `${parseInt(minutes, 10)} minute${parseInt(minutes, 10) > 1 ? 's' : ''}` : '';

        return `${formattedHours} ${formattedMinutes}`.trim();
    }
}
