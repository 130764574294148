import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { BillItemSettingComponent } from '../bill-item-setting/bill-item-setting.component';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { HttpResponse } from '@angular/common/http';
import { BillingItemSettingsDTO } from 'src/app/demo/model/BillingItemSettingsDTO';
import { RoomDTO } from 'src/app/demo/model/RoomDTO';
import { TagDTO } from 'src/app/demo/model/TagDTO';
import { Common } from 'src/app/demo/utils/common';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InvoiceDetailsDTO } from 'src/app/demo/model/InvoiceDetailsDTO';
import { DomSanitizer } from '@angular/platform-browser';
import { LedgerAccountDTO } from 'src/app/demo/model/LedgerAccountDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { OperationService } from 'src/app/demo/service/operation.service';
import { Subscription, empty, forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FamilyDTO } from 'src/app/demo/model/FamilyDTO';
import { LocalOperationService } from 'src/app/demo/service/localOperation.service';
import { Dropdown } from 'primeng/dropdown';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss'],
    providers : [MessageService],
})
export class CreateInvoiceComponent implements OnInit {
    @ViewChild('roomNameDropdown') roomNameDropdown!: Dropdown;
    @ViewChild('tagNameDropdown') tagNameDropdown!: Dropdown;
    invoiceData: DynamicDialogConfig<any>;
    activeIndex: number = 0;
    invoiceForm!: FormGroup;
    invoiceItemForm!: FormGroup;
    dropdownOptions: any[] = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
    ];
    presetItemList: BillingItemSettingsDTO[] = [];
    discountItemList: BillingItemSettingsDTO[] = [];
    search: any = {};
    pageContext = {
        page: 0,
        previousPage: 0,
        itemsPerPage: 100,
        totalItems: 0,
        sort: 'id,desc',
    };
    tagList!: TagDTO[];
    selectedTags: TagDTO[] = [];
    studentList: StudentDTO[] = [];
    multiFamilyList: FamilyDTO[] = [];
    sortField: string = '';
    selectedStudents: any[] = [];
    selectedAllStudents: any[] = [];
    selectedStudentIds: number[] = [];
    last!: number;
    selectAllStudents: boolean = false;
    studentsOfselectedRoom: StudentDTO[] = [];
    filteredStudentsOfRoom: StudentDTO[] = [];
    studentSearchInput: string = '';
    studentSelectedRoom: number | undefined;
    selectedStudentTags: number[] = [];
    selectedRoomId: number | undefined;
    selectedRoomIdForForm: number | undefined;
    roomList!: RoomDTO[];
    selectedStudentList: StudentDTO[] = [];
    multiFamily: FamilyDTO[] = [];
    multiFamilyDataList: any;
    multifamilyChilds!: any;
    // selectedFamily!: FamilyDTO;
    selectedFamily: any = {};
    subsidyItemList: LedgerAccountDTO[] = [];
    optionalDateControl: boolean = false;
    submitButton: boolean = true;
    multifamilyChecked: boolean = false;
    updatedStudentList: StudentDTO[] = [];
    buttonType: string = '';
    readOnlyFamily: boolean = false;
    private familySubscription!: Subscription;
    sortWithAscName = 'name,asc';
    isOneStudentInFamily: boolean = false;
    filterdStudentListWithRoom: StudentDTO[] = [];
    constructor(
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private lookupService: LookupService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private sanitizer: DomSanitizer,
        private operationService: OperationService,
        private datePipe: DatePipe,
        private localOperationService: LocalOperationService
    ) {
        this.invoiceData = this.config;
        if (this.invoiceData.data.familyTransactionCreate == true) {
            this.selectedStudentList = this.invoiceData.data.studentList;
            this.multiFamily = this.invoiceData.data.multiFamilyList;
        }

    }

    ngOnInit() {
        this.invoiceForm = this.formBuilder.group({
            id: undefined,
            studentId: undefined,
            selectedFamily: undefined,
            date: undefined,
            dueDate: [undefined, [Validators.required]],
            startDate: undefined,
            endDate: undefined,
            invoiceAmount: undefined,
            balanceAmount: undefined,
            invoiceStatus: undefined,
            description: undefined,
            note: undefined,
            status: 'A',
            lastModified: undefined,
            lastModifiedBy: undefined,
            schoolId: 1,
            recurringPlanId: undefined,
        });

        this.invoiceForm
            .get('startDate')
            ?.valueChanges.subscribe((startDate) => {
                const endDateControl = this.invoiceForm.get('endDate');
                endDateControl?.clearValidators();
                if (startDate) {
                    endDateControl?.setValidators([Validators.required]);
                    this.optionalDateControl = true;
                } else {
                    this.optionalDateControl = false;
                    endDateControl?.reset();
                }
                endDateControl?.updateValueAndValidity();
            });

        this.invoiceItemForm = this.formBuilder.group({
            dynamicArray: this.formBuilder.array([]),
        });

        if (this.invoiceData.data.createInvoice == true || this.invoiceData.data.familyTransactionCreate == true) {
            this.addMoreFormGroup();
        }
        this.queryBillItemSetting();
        this.querySubsidy();
        this.queryRooms();
        this.queryTags();
        if (this.invoiceData.data.isMultiFamily) {
            this.multiFamilyData();
        } else if (this.invoiceData.data.studentList?.length !== 0) {
            this.studentList = [...this.selectedStudentList];
        } else {
            this.queryStudents();
        }


    }

    get dynamicArray(): FormArray {
        return this.invoiceItemForm.get('dynamicArray') as FormArray;
    }

    onComplete() {
        if (this.invoiceData.data.createInvoice == true) {
            if (this.activeIndex === 0) {
                this.invoiceData.header = 'Select Students ';
            } else if (this.activeIndex === 1) {
                this.invoiceData.header = 'Submit Invoice';
            } else {
                this.invoiceData.header = 'Create Invoice';
            }
            this.activeIndex++;
        }

        if (this.invoiceData.data.familyTransactionCreate == true) {
            // if(this.invoiceData.data.studentList.length != 1){
            //     this.activeIndex = 0;
            // }else{
            //     this.activeIndex = 1;
            // }
            if (this.activeIndex === 0) {
                this.invoiceData.header = 'Select Students ';
                // this.selectedStudentList = this.invoiceData.data.isMultiFamily ? this.multifamilyChilds : this.selectedStudentList;
            } else if (this.activeIndex === 1) {
                this.invoiceData.header = 'Submit Invoice';

            } else {
                this.invoiceData.header = 'Create Invoice';
            }
            this.activeIndex++;
        }

    }

    onBack() {
        if (this.invoiceData.data.createInvoice == true) {
            if (this.activeIndex === 2) {
                this.invoiceData.header = 'Select Students ';
            } else if (this.activeIndex === 1) {
                this.invoiceData.header = 'Create Invoice';
            } else {
                this.invoiceData.header = 'Submit Invoice';
            }
            this.activeIndex--;
        }

        if (this.invoiceData.data.familyTransactionCreate == true) {
            // if(this.invoiceData.data.studentList.length != 1){
            //     this.activeIndex = 2;
            // }else{
            //     this.activeIndex = 1;
            // }
            if (this.activeIndex === 2) {
                this.invoiceData.header = 'Select Students ';
            } else if (this.activeIndex === 1) {
                this.invoiceData.header = 'Create Invoice';
            } else {
                this.invoiceData.header = 'Submit Invoice';
            }
            this.activeIndex--;
        }

    }

    addMoreFormGroup() {
        const newFormGroup = this.formBuilder.group({
            id: undefined,
            type: 'newItem',
            description: [undefined, [Validators.required]],
            amount: [undefined, [Validators.required]],
            deductionAmt: null,
            currencySign: '%',
            percentageAmt: undefined,
            refTable: 'Invoice',
            refTableId: undefined,
            schoolId: undefined,
            status: 'A',
        });
        this.dynamicArray.push(newFormGroup);
    }

    async queryStudents() {
        this.selectAllStudents = false;
        this.search.size = 500;
        this.search.page = this.pageContext.page;
        this.search.sort = 'fullName,asc';

        if (this.selectedRoomId) {
            this.search['name.contains'] = this.selectedRoomId;
        } else {
            delete this.search['name.contains'];
        }
        this.search['fullName.contains'] = this.studentSearchInput ?? undefined;
        this.search['roomId.equals'] = this.studentSelectedRoom;
        this.search['tagId.in'] = this.selectedStudentTags;
        // this.search['hasParentEmail.equals'] = false;
        // this.search['status.notEquals'] = 'I';
        Common.removeEmptyFields(this.search);

        try {
            const res = await this.lookupService
                .queryNotMultiFamilyStudents(this.search)
                .toPromise();
            let temp: StudentDTO[] = [];
            this.studentList = res?.body ? res.body : temp;

            const allSelectedStudents: StudentDTO[] = [];
            const newStudentList: StudentDTO[] = await Promise.all(
                this.studentList.map(async (each) => {
                    let exist = this.selectedStudentIds.find(
                        (id) => id == each.id
                    );
                    if (exist) {
                        each.selected = true;
                        allSelectedStudents.push(each);
                    } else {
                        each.selected = false;
                    }

                    return this.fetchAndProcessProfileImage(each);
                })
            );

            this.studentList = newStudentList;
            this.updatedStudentList = newStudentList;
            this.filterdStudentListWithRoom = newStudentList;
            if(this.studentSelectedRoom || (this.selectedStudentTags != null && this.selectedStudentTags?.length != 0)) {
                this.filterStudents();
            }
            if (this.studentSearchInput) {
                this.filter();
            }

            if (
                allSelectedStudents.length === this.studentList.length &&
                this.studentList.length !== 0
            ) {
                this.selectAllStudents = true;
            } else {
                this.selectAllStudents = false;
            }

        } catch (error) {
            console.error('Error querying students:', error);
        } finally {
            this.search = {};
        }
    }

    getStudentTags(student: StudentDTO) {
        this.lookupService.queryTag({'size': 50, 'studentId.equals': Number(student.id)}).subscribe((res: HttpResponse<TagDTO[]>) => {
            student.tags = res.body ?? [];
        })
    }
    
    async queryMultiFamilyStudents() {
        this.search = {};
        this.search.size = 500;
        this.search.page = this.pageContext.page;
        this.search.sort = 'name,asc';
        Common.removeEmptyFields(this.search);
        try {
            const res = await this.lookupService.queryCreateInvoiceMultifamilyStudents(this.search, false).toPromise();
            let temp: FamilyDTO[] = [];
            const allFamily = res?.body ? res.body : temp;
            this.studentList = [];
            allFamily?.forEach((each: any) => {
              each.childs?.map((student  :StudentDTO ) => {
                student.family = each;
                this.getStudentTags(student);
                this.studentList.push(student);
              });
            });
            

            const allSelectedStudents: StudentDTO[] = [];
            const newStudentList: StudentDTO[] = await Promise.all(
                this.studentList.map(async (each) => {
                    let exist = this.selectedStudentIds.find((id) => id == each.id);
                    if (exist) {
                        each.selected = true;
                        allSelectedStudents.push(each);
                    } else {
                        each.selected = false;
                    }
                    return this.fetchAndProcessProfileImage(each);
                })
            );

            this.studentList = newStudentList;
            this.updatedStudentList = newStudentList;
            this.filterdStudentListWithRoom = newStudentList;
            if(this.studentSelectedRoom || (this.selectedStudentTags != null && this.selectedStudentTags?.length != 0)) {
                this.filterStudents();
            }
            if (this.studentSearchInput) {
                this.filter();
            }

            if (allSelectedStudents.length === this.studentList.length && this.studentList.length !== 0) {
                this.selectAllStudents = true;
            } else {
                this.selectAllStudents = false;
            }

        } catch (error) {
            console.error('Error querying multifamily students:', error);
        } finally {
            this.search = {};
        }
    }

    // Method to handle the toggle change
    onClickMultifmailyToggle(event: any) {
        this.multifamilyChecked = event.checked;
        this.selectAllStudents = false;
        this.selectedStudents = [];
        this.selectedStudentIds = [];
        this.pageContext.page = 0;
        this.dvFirst = 0;
        if (this.multifamilyChecked) {
            this.queryMultiFamilyStudents(); // Call multifamily query method
        } else {
            this.queryStudents(); // Call normal query method
        }
    }

    async fetchAndProcessProfileImage(each: StudentDTO): Promise<StudentDTO> {
        const queryParams = {
            'docType.equals': 'PROFILE',
            'refTable.equals': 'Student',
            'refTableId.equals': each.id,
        };

        try {
            const req: Blob | undefined = await this.lookupService
                .queryAllDocuments(queryParams)
                .toPromise();

            if (req && req.size !== 0) {
                let imageToShow: string | ArrayBuffer | any = await new Promise(
                    (resolve) => {
                        let reader = new FileReader();
                        reader.addEventListener(
                            'load',
                            () => {
                                resolve(reader.result);
                            },
                            false
                        );
                        reader.readAsDataURL(req);
                    }
                );

                each.profileimg = this.sanitizer.bypassSecurityTrustResourceUrl(
                    '' + imageToShow
                );
            } else {
                each.profileimg = undefined;
            }
        } catch (error) {
            console.error('Error fetching profile image for student:', error);
        }

        return each;
    }

    queryRooms() {
        this.search.size = 100; //this.pageContext.itemsPerPage
        this.search.page = this.pageContext.page;
        this.search.sort = this.sortWithAscName;
        Common.removeEmptyFields(this.search);
        this.lookupService
            .queryRooms(this.search)
            .subscribe((res: HttpResponse<RoomDTO[]>) => {
                let temp: RoomDTO[] = [];
                let activeRooms: any = res.body?.filter(
                    (each) => each.status == 'A'
                );
                this.roomList = activeRooms ? activeRooms : temp;
            });
    }

    getRoomName(roomId: any) {                
        return this.roomList?.find((item: RoomDTO) => item?.id === roomId)?.name;
    }

    changePresetItem(index: number, e: any) {
        const selectedPreset = e.value;
        const selectedPresetObj = this.presetItemList.find(
            (item: BillingItemSettingsDTO) =>
                item.name === selectedPreset && item.isPresetItem == true
        );
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if (selectedPreset != undefined) {
            const rate = Number(selectedPresetObj?.rate);
            formGroup.get('amount')?.setValue(rate);
        } else if (selectedPreset == undefined) {
            formGroup.get('amount')?.setValue(null);
        }
    }
    onCurrencyChange(index: number, currency: string, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        const selectedValue = e.target.value;
        if (
            formGroup.value.deductionAmt != null &&
            formGroup.value.deductionAmt != ''
        ) {
            if (selectedValue === '$') {
                const rate = Number(formGroup.value.deductionAmt);
                formGroup.get('amount')?.setValue(rate);
            } else if (selectedValue === '%') {
                this.onPercentageAmtChange(index, e);
            }
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }
    }
    onPercentageAmtChange(index: number, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if ((formGroup.value.deductionAmt != null || formGroup.value.deductionAmt != '') && (formGroup.value.percentageAmt != null || formGroup.value.percentageAmt != '')) {
            var amount = parseFloat((formGroup.value.deductionAmt != null && formGroup.value.deductionAmt != '') ? formGroup.value.deductionAmt : 0);
            var subAmountPercentage = parseFloat((formGroup.value.percentageAmt != null && formGroup.value.percentageAmt != '') ? formGroup.value.percentageAmt : 0);
            var subAmount = subAmountPercentage / 100;
            var result = Number(amount * subAmount);
            formGroup.get('amount')?.setValue(result);
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }
    }
    onDeductionAmtChange(index: number, e: any) {
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        if (formGroup.value.deductionAmt != null || formGroup.value.deductionAmt != '') {
            if (formGroup.value.currencySign === '$') {
                const rate = Number(formGroup.value.deductionAmt);
                formGroup.get('amount')?.setValue(rate);
            } else if (formGroup.value.currencySign === '%') {
                this.onPercentageAmtChange(index, e);
            } else {
                this.messageService.add({
                    severity: 'warn',
                    life: 10000,
                    summary: 'Warning',
                    detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
                });
            }
        } else {
            this.messageService.add({
                severity: 'warn',
                life: 10000,
                summary: 'Warning',
                detail: `While adding invoice item details of ${formGroup.value.type} fields are mandatory`,
            });
        }

    }

    queryTags() {
        this.search.size = 100;
        this.search.page = this.pageContext.page;
        this.search.sort = this.sortWithAscName;
        Common.removeEmptyFields(this.search);
        this.lookupService
            .queryTag(this.search)
            .subscribe((res: HttpResponse<TagDTO[]>) => {
                let temp: TagDTO[] = [];
                this.tagList = res.body ? res.body : temp;
            });
    }

    filterRoom(event: any) {
        let filter = event.filter;
        this.search = {};
        this.search.size = this.pageContext.itemsPerPage;
        this.search.page = this.pageContext.page;
        this.search.sort = this.sortWithAscName;
        this.search['name.contains'] = filter;
        Common.removeEmptyFields(this.search);
        this.queryRooms();
        this.search = {};
    }

    filterTag(event: any) {
        let filter = event.filter;
        this.search = {};
        this.search.size = this.pageContext.itemsPerPage;
        this.search.page = this.pageContext.page;
        this.search.sort = this.sortWithAscName;
        this.search['name.contains'] = filter;
        Common.removeEmptyFields(this.search);
        this.queryTags();
        this.search = {};
    }


    filter() {
        let filtered: StudentDTO[] = [];
        if (this.studentSearchInput.length != 0) {
            for (let i = 0; i < this.filterdStudentListWithRoom.length; i++) {
                let user = this.filterdStudentListWithRoom[i];
                if (
                    user?.fullName &&
                    (user.fullName.toLowerCase().indexOf(this.studentSearchInput.toLowerCase()) === 0 ||
                        user.fullName.toLowerCase().includes(this.studentSearchInput.toLowerCase()))
                ) {
                    filtered.push(user);
                }
            }
        } else {
            filtered = [...this.filterdStudentListWithRoom]
        }
        this.studentList = [...filtered];
        this.checkboxChanged();
        this.dvFirst = 0;
    }

    filterStudents() {
        // Step 1: Start with the original updated student list
        this.studentList = [...this.updatedStudentList];
    
        // Step 2: Filter by selected tags (if any)
        if (this.selectedStudentTags != null && this.selectedStudentTags.length > 0) {
            this.studentList = this.studentList.filter((student: StudentDTO) => {
                return this.selectedStudentTags.every(tagId =>
                    student.tags?.some(tag => tag.id === tagId)
                );
            });
        }
    
        // Step 3: Filter by selected room ID (if any)
        if (this.studentSelectedRoom != null) {
            this.studentList = this.studentList.filter(each => each.roomId == this.studentSelectedRoom);
        }
    
        // Step 4: Update the filtered student list
        this.filterdStudentListWithRoom = this.studentList;
    
        // Reset the paginator to the first page
        this.dvFirst = 0;
    }
    

    Searchstudent() {
        this.search.page = 1;
        if (this.search['roomId.equals'] == null) {
            this.search = {};
        }
        this.queryStudents();
    }
    toggleSelection(student: any) {
        if(this.multifamilyChecked){
            this.selectedStudents.push(student);
        }else{

        const index = this.selectedStudents.findIndex(
            (selected) => selected.id === student.id
        );
        if (index !== -1) {
            this.selectedStudents.splice(index, 1);
        } else {
            this.selectedStudents.push(student);
        }
        
    }
        const tempSelectedIds = this.selectedStudents.map(
            (selected) => selected.id
        );
        this.selectedStudentIds = [...tempSelectedIds];
        // this.selectedStudentList = this.studentList.filter((item: StudentDTO) =>
        //     this.selectedStudentIds.includes(item.id as number)
        // );
        
        this.selectedStudentList = [...this.selectedStudents].sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.checkboxChanged();
    }

    toggleAllSelection() {
        this.studentList.forEach(
            (student) => (student.selected = this.selectAllStudents)
        );

        if (this.selectAllStudents) {
            this.selectedStudents = [...this.studentList];
        } else {
            this.selectedStudents = [];
        }
        this.selectedStudentIds = this.selectedStudents.map(
            (selected) => selected.id
        );

        this.selectedStudentList = this.studentList.filter((item: StudentDTO) =>
            this.selectedStudentIds.includes(item.id as number)
        );
    }

    checkboxChanged() {
        if (this.isAllSelected()) this.selectAllStudents = true;
        else this.selectAllStudents = false;
    }

    isAllSelected(): boolean {
        return this.studentList.every((student) => student.selected);
    }

    editItem(item: any, i: number) { }

    deleteItem(event: Event, item: any, i: number) {
        this.confirmationService.confirm({
            key: 'confirm1',
            target: event.target as EventTarget,
            message: 'Do you want to Delete this Invoice Item?',
            accept: () => this.deleteInvoiceItem(i),
            reject: () => { },
        });
    }

    deleteInvoiceItem(index: number) {
        this.dynamicArray.removeAt(index);
        this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Invoice Item Deleted successfully.`,
            life: 10000,
        });
    }

    onTypeChange(event: any, index: number) {
        const selectedType = event.target.value;
        const formGroup = this.dynamicArray.at(index) as FormGroup;
        // Clear previous values
        formGroup.get('description')?.reset();
        formGroup.get('amount')?.reset();

        // Update controls based on the selected type
        if (selectedType === 'newItem') {
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
            formGroup.get('amount')?.setValue(null);
        } else if (selectedType === 'presetItem') {
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
            formGroup.get('amount')?.setValue(null);
        } else if (selectedType === 'discount') {
            formGroup.get('deductionAmt ')?.enable();
            formGroup.get('currencySign')?.enable();
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
        } else if (selectedType === 'subsidy') {
            formGroup.get('deductionAmt ')?.enable();
            formGroup.get('currencySign')?.enable();
            formGroup.get('description')?.enable();
            formGroup.get('amount')?.enable();
        }
    }

    getTotalAmount() {
        const formGroup = this.dynamicArray as FormArray;
        const itemDetailsArray = formGroup.value;

        const addAmount = itemDetailsArray
            .filter((item: any) =>
                ['newItem', 'presetItem'].includes(item.type)
            )
            .reduce((total: any, item: any) => total + (Number(item.amount) || 0), 0);

        const minusAmount = itemDetailsArray
            .filter((item: any) => ['discount', 'subsidy'].includes(item.type))
            .reduce((total: any, item: any) => total + (Number(item.amount) || 0), 0);

        const totalAmount = addAmount - minusAmount;
        this.invoiceForm.get('invoiceAmount')?.setValue(totalAmount);
        return totalAmount;
    }

    onItemSetting(header: string) {
        let tempData: any = {};
        this.ref = this.dialogService.open(BillItemSettingComponent, {
            data: tempData,
            header: header,
            width: '50%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
        });
        this.ref.onClose.subscribe((res) => {
            this.queryBillItemSetting();
        });
    }

    queryBillItemSetting() {
        this.lookupService
            .queryBillingItemSetting({
                'schoolId.equals': 1,
                'status.equals': 'A',
            })
            .subscribe((res: HttpResponse<BillingItemSettingsDTO[]>) => {
                const presetItemList: BillingItemSettingsDTO[] = [];
                const discountItemList: BillingItemSettingsDTO[] = [];

                (res.body || []).forEach((item) => {
                    if (item.isPresetItem === true) {
                        presetItemList.push(item);
                    } else {
                        discountItemList.push(item);
                    }
                });

                this.presetItemList = presetItemList;
                this.discountItemList = discountItemList;
            });
    }
    querySubsidy() {
        const queryParams = {
            'isSubsidy.equals': true,
            'status.equals': 'A',
        };
        this.lookupService
            .queryLedgerAccount(queryParams)
            .subscribe((res: HttpResponse<LedgerAccountDTO[]>) => {
                let temp: LedgerAccountDTO[] = [];
                this.subsidyItemList = res.body ? res.body : temp;
            });
    }
    onRemoveInvoiveStud(event: Event, stud: any) {
        this.confirmationService.confirm({
            key: 'confirm2',
            target: event.target as EventTarget,
            message: 'Do you want to Delete this Student?',
            accept: () => this.deleteStudents(stud),
            reject: () => { },
        });
    }

    deleteStudents(stud: any) {
        const idToRemoveIndex = this.selectedStudentIds.indexOf(stud.id);
        const selectedStudentIndex = this.selectedStudentList?.findIndex((each) => each.id == stud.id);

        if (idToRemoveIndex !== -1 && selectedStudentIndex !== -1) {
            this.selectAllStudents = false;
            this.selectedStudentIds.splice(idToRemoveIndex, 1);
            this.selectedStudentList?.splice(selectedStudentIndex, 1);
            this.selectedStudents.splice(idToRemoveIndex, 1);
        }
        const index = this.studentList.findIndex(
            (student) => student.id === stud.id
        );
        if (index !== -1) {
            this.studentList[index].selected = false;
        }

        this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Student Removed from the Invoice Successfully.`,
            life: 10000,
        });
    }

    async onSubmit(type: string) {
        this.buttonType = type;
        this.submitButton = false;
        const invoiceItemDetailsObj: InvoiceDetailsDTO[] =
            this.invoiceItemForm.get('dynamicArray')?.value;

        const observables = this.selectedStudentList.map(async (stud) => {
            try {
                let invoiceObj: InvoiceDTO = { ...this.invoiceForm.value };

                invoiceObj.invoiceStatus = type === 'save' ? 'Created' : 'Sent';
                invoiceObj.studentId = stud.id;
                invoiceObj.familyId = this.invoiceForm.get('selectedFamily')?.value ? this.invoiceForm.get('selectedFamily')?.value : undefined;
                invoiceObj.schoolId = stud.schoolId;
                // invoiceObj.
                invoiceObj.balanceAmount =
                    this.invoiceForm.get('invoiceAmount')?.value;
                invoiceObj.dueDate =
                    this.invoiceForm.get('dueDate')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('dueDate')?.value
                        )
                        : undefined;
                invoiceObj.sentDate =
                    invoiceObj.invoiceStatus == 'Sent'
                        ? this.convertToTimeZone(
                            this.datePipe.transform(
                                new Date(),
                                'yyyy-MM-dd'
                            ) + 'T00:00:00Z'
                        )
                        : undefined;
                invoiceObj.date =
                    this.invoiceForm.get('date')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('date')?.value
                        )
                        : this.convertToTimeZone(
                            this.datePipe.transform(
                                new Date(),
                                'yyyy-MM-dd'
                            ) + 'T00:00:00Z'
                        );
                invoiceObj.startDate =
                    this.invoiceForm.get('startDate')?.value != undefined
                        ? this.convertToTimeZone(
                            this.invoiceForm.get('startDate')?.value
                        )
                        : undefined;
                invoiceObj.endDate =
                    this.invoiceForm.get('endDate')?.value != undefined
                        ? this.convertToTimeZone(this.invoiceForm.get('endDate')?.value)
                        : undefined;

                const res: HttpResponse<InvoiceDTO> | undefined =
                    await this.operationService
                        .createInvoice(invoiceObj)
                        .toPromise();

                // if (res && res.body) {
                //     if (res.body.invoiceStatus == 'Sent') {
                //         await this.operationService.sendInvoice(res.body).toPromise();
                //     }
                //     const itemObservables = invoiceItemDetailsObj.map(
                //         async (item, index: number) => {
                //             item.refTableId = res.body?.id;
                //             item.sequence = item.sequence ? item.sequence : index.toString();
                //             const itemRes:
                //                 | HttpResponse<InvoiceDetailsDTO>
                //                 | undefined = await this.operationService
                //                     .createInvoiceItemDetails(item)
                //                     .toPromise();

                //             return itemRes;
                //         }
                //     );

                //     await Promise.all(itemObservables); // Wait for all itemObservables to complete
                //     return true; // Indicate success
                // }
                
                // if (res && res?.body) {
                //     if (res.body?.invoiceStatus == 'Sent') {
                //         await this.operationService.sendInvoice(res.body).toPromise();
                //     }
                //     for (let index = 0; index < invoiceItemDetailsObj?.length; index++) {
                //         const item = invoiceItemDetailsObj[index];
                //         item.refTableId = res.body?.id;
                //         item.sequence = item?.sequence ? item?.sequence : index.toString();
                //         await this.operationService.createInvoiceItemDetails(item).toPromise();
                //     }
                //     return true;
                // }

                if (res && res.body) {
                    if (res.body.invoiceStatus == 'Sent') {
                        await this.operationService.sendInvoice(res.body).toPromise();
                    }
                
                   
                    const refTableId = res.body.id;
                
                    for (let index = 0; index < invoiceItemDetailsObj.length; index++) {
                        const item = { ...invoiceItemDetailsObj[index] }; 
                        item.refTableId = refTableId;
                        item.sequence = item.sequence ? item.sequence : index.toString();
                        item.refTable = 'Invoice';
                        await this.operationService.createInvoiceItemDetails(item).toPromise();
                    }
                
                    return true; 
                }


            } catch (error: any) {
                this.submitButton = true;
                this.buttonType = '';
                this.messageService.add({
                    severity: 'error',
                    life: 10000,
                    summary: error.error?.title || 'Error',
                    detail: error.error?.detail || 'An error occurred',
                });
                return false; // Indicate failure
            }
            return false; // Indicate failure if no success or catch block is reached
        });

        const allComplete = await Promise.all(observables); // Wait for all observables to complete

        const hasSuccess = allComplete.some(result => result === true); // Check if any promise Success

        let message = {};
        if (hasSuccess) {
            this.buttonType = '';
            if (this.invoiceData.data.createInvoice == true) {
                this.readOnlyFamily = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Invoice Created Successfully.`,
                    life: 10000,
                });
            } else {
                this.readOnlyFamily = false;
                message = {
                    severity: 'success',
                    summary: 'Success',
                    detail: `Invoice Created Successfully.`,
                    life: 10000,
                };
            }
        }
        setTimeout(() => {
            if (this.invoiceData.data.createInvoice == true) {
                this.ref.close();
                this.submitButton = true;
            } else {
                this.ref.close(message);
                this.submitButton = true;
            }
        }, 1500);

    }


    cancelInv() {
        this.ref.close();
        this.readOnlyFamily = false;
    }




    convertToTimeZone(originalDateString: string): string {
        const currentDate = new Date();
        const currentHours = currentDate.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDate.getMinutes().toString().padStart(2, '0');
        const currentSeconds = currentDate.getSeconds().toString().padStart(2, '0');

        return (
            this.datePipe.transform(originalDateString, 'yyyy-MM-dd') + `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
        );
    }

    dvFirst: number = 0;
    onPage(event: any) {
        this.dvFirst = event.first; // Update the pagination state
      }

    onSearch(reset: boolean) {
        if (reset) {
            this.studentSearchInput = '';
            this.selectedStudents = [];
            this.selectedStudentIds = [];
            this.selectAllStudents = false;
            this.search = {};
            if (!this.multifamilyChecked) this.tagNameDropdown.filterValue = '';
            this.roomNameDropdown.filterValue = '';
            this.studentSelectedRoom = undefined;
            this.selectedStudentTags = [];
            this.studentList?.map((each) => {
                each.selected = false;
            });
            this.filterRoom(this.roomNameDropdown.filterValue);
            this.queryTags();
            if (this.multifamilyChecked && (this.studentSelectedRoom || this.selectedStudentTags?.length != 0)) {
                this.studentList = [...this.updatedStudentList];
            } else {
                if (this.multifamilyChecked) this.queryMultiFamilyStudents();
                else this.queryStudents();
              }
        }
        this.dvFirst = 0;
        // this.queryStudents();
    }

    multiFamilyData() {
        this.multiFamilyDataList = this.multiFamily;
        if (this.multiFamilyDataList.length > 0) {
            this.selectedFamily = this.multiFamilyDataList[0];
            this.invoiceForm.patchValue({ selectedFamily: this.selectedFamily.id });
            this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': this.selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
                this.multifamilyChilds = res.body ? res.body : [];
                this.studentList = [...this.multifamilyChilds];
                this.studentList.forEach(each => each.selected = true);
                this.selectedStudentIds = this.studentList.map((each: StudentDTO) => Number(each.id));
                this.selectedStudents = [...this.multifamilyChilds];
                this.selectedStudentList = [...this.multifamilyChilds];
                if (this.selectedStudentList.length == 1) {
                    this.selectedStudentList[0].selected = true;
                    this.isOneStudentInFamily = true;
                } else {
                    // this.selectedStudentList[0].selected = false;
                    this.isOneStudentInFamily = false;
                  }
                // this.studentList.forEach(each => each.selected = true);
                // this.selectedStudentIds = this.studentList.map((each: StudentDTO) => Number(each.id));
                // this.selectedStudentList = [...this.multifamilyChilds];
            });
        }
        this.readOnlyFamily = false;
    }

    onFamilyChange(event: any) {
        const selectedFamilyId = event.value;
        const selectedFamily = this.multiFamilyDataList?.find((each: any) => each.id === selectedFamilyId);
        this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': selectedFamily.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
            this.multifamilyChilds = res.body ? res.body : [];
            this.studentList = [...this.multifamilyChilds];
              this.studentList.forEach(each => each.selected = true);
              this.selectedStudentIds = this.studentList.map((each: StudentDTO) => Number(each.id));
              this.selectedStudents = [...this.multifamilyChilds];
              this.selectedStudentList = [...this.multifamilyChilds];
              if (this.selectedStudentList.length == 1) {
                this.selectedStudentList[0].selected = true;
                this.isOneStudentInFamily = true;
              } else {
                // this.selectedStudentList[0].selected = false;
                this.isOneStudentInFamily = false;
              }
        });
    }

    ngAfterContentInit(): void {

        this.familySubscription = this.localOperationService.multiFamilyObj.subscribe((family) => {

            // this.multiFamilyDataList = this.multiFamily; 
            if (family != null && Object.keys(family).length > 0) {
                // this.multiFamilyDataList = family;
                this.invoiceForm.patchValue({ selectedFamily: family?.id });
                this.selectedFamily = family?.id;
                this.readOnlyFamily = true;
                this.getSelectedFamilyChild(family);
                this.onFamilyChange({value: this.selectedFamily});

            } else {
                if (this.multiFamilyDataList?.length > 0) {
                    this.selectedFamily = this.multiFamilyDataList[0];
                    this.invoiceForm.patchValue({ selectedFamily: this.selectedFamily?.id });
                    this.getSelectedFamilyChild(this.selectedFamily);
                    this.readOnlyFamily = false;
                }

            }
        });

    }

    ngOnDestroy(): void {
        if (this.familySubscription) {

            this.localOperationService.multifamilyobj(null);
            this.familySubscription.unsubscribe();
        }
    }




    getSelectedFamilyChild(family: any) {
        if (family?.childIds && family?.childIds.length > 0) {
            this.lookupService.queryStudent({
                size: 30,
                sort: 'fullName,asc',
                page: 0,
                'id.in': family?.childIds
            }).subscribe((res: HttpResponse<StudentDTO[]>) => {
                this.multifamilyChilds = res.body ? res.body : [];
                this.selectedStudentList = this.multifamilyChilds;
            });
        }
    }

}
