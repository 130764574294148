import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToUpperCase]'
})
export class ToUpperCaseDirective {

  constructor(private el: ElementRef,private renderer:Renderer2) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.renderer.setProperty(this.el.nativeElement, 'value', value.toUpperCase());
  }
}
