<div class="layout-footer">
    <div class="footer-start">
        <img *ngIf="this.schooldetails.logoUrl == undefined" src="assets/layout/images/School 2.png" alt="logo" />
        <img *ngIf="this.schooldetails.logoUrl != undefined" src="{{this.schooldetails.logoUrl}}" alt="logo" />
        <span class="app-name">{{ this.schooldetails.name}}</span>
    </div>
    <div class="footer-end">
        <span>Proudly created by Wise Owl Technology LLC | All rights reserved.</span>
    </div>
</div>
