import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoLeadingSpace]'
})
export class NoLeadingSpaceDirective {

  constructor(private control: NgControl) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' && this.control.value?.trim() === '') {
      event.preventDefault();
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const inputValue = this.control.value ?? '';
    const target = event.target as HTMLInputElement; // Casting event target to HTMLInputElement
    if (event.key === ' ' && inputValue.trim() === '' && target.selectionStart === 0) {
      event.preventDefault();
    }
  }
}
