import { Component } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NodeService } from 'src/app/demo/service/node.service';

@Component({
  selector: 'app-milestone-list',
  templateUrl: './milestone-list.component.html',
  styleUrls: ['./milestone-list.component.scss']
})
export class MilestoneListComponent {
    files!: TreeNode[];
    selectedFiles!: TreeNode[];

    constructor(private nodeService: NodeService, public ref: DynamicDialogRef,) {}

    ngOnInit() {
        this.nodeService.getFiles().then((data) => (this.files = data));
    }

    selectedMilestoneMethod(){
        this.ref.close(this.selectedFiles);
    }
}
