<div class="layout-topbar justify-content-between">
    <a class="app-logo" routerLink="/">
        <img alt="app logo" *ngIf="this.schooldetails.logoUrl == undefined"  [style.width.rem]="3.5"
        [style.height.rem]="3.5" src="assets/layout/images/School 2.png" class="shadow-4 border-circle border-2 border-primary flex-shrink-0 enlarge-on-hover">

        <img alt="app logo"  *ngIf="this.schooldetails.logoUrl != undefined"  [style.width.rem]="3.5"
        [style.height.rem]="3.5" src="{{this.schooldetails.logoUrl}}" class="shadow-4 border-circle border-2 border-primary flex-shrink-0 enlarge-on-hover">
        <span class="app-name">{{ this.schooldetails.name}}<br><span style="margin-left: 0px;font-size: xx-small;">SMART CHILDREN | BRIGHT FUTURE</span></span>
    </a>

    <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
        <span></span>
    </button>

    <ul class="topbar-menu">
        <li *ngFor="let item of tabs; let i = index">
            <a [routerLink]="item.routerLink" routerLinkActive="active-route" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
                [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment!"
                [skipLocationChange]="item.skipLocationChange!" [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
                <span>{{item.label}}</span>
            </a>
            <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
        </li>
        <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
           <!-- <h4>Wise Owl Academy</h4> -->
        </li>
    </ul>

    <!-- <div class="topbar-search" [ngClass]="{'topbar-search-active': searchActive}">
        <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
            <i class="pi pi-search"></i>
        </button>

        <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input #searchinput type="text" pInputText placeholder="Search" (blur)="deactivateSearch()" (keydown.escape)="deactivateSearch()"/>
                <i class="pi pi-search"></i>
            </span>
        </div>
    </div> -->

    <div class="topbar-profile">
        <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">

        <span *ngIf="this.userdata.refTable === 'Staff'; else guardianPicture">
            <img
            [src]="picture"
            *ngIf="picture != undefined; else noImageFound"
            class="shadow-4 border-circle border-2 border-primary flex-shrink-0 mr-2 enlarge-on-hover"
            [style.width.rem]="3.5"
            [style.height.rem]="3.5"
            height="10"
            width="10"/>
            <ng-template #noImageFound>
                <img src="assets/layout/images/male.png"
                class="shadow-4 border-circle border-2 border-primary flex-shrink-0 mr-2 enlarge-on-hover"
                [style.width.rem]="3.5"
                [style.height.rem]="3.5"
                height="10"
                width="10"/>
            </ng-template>
        </span>
        <ng-template #guardianPicture>
            <!-- <img *ngIf="this.studentRelation == 'Mother'" src="assets/layout/images/female-user.webp" height="10" width="10"
                class="shadow-4 border-circle border-2 border-primary flex-shrink-0 mr-2 enlarge-on-hover" [style.width.rem]="3.5" [style.height.rem]="3.5" />
            <img *ngIf="this.studentRelation == 'Father'" src="assets/layout/images/male-user.webp" height="10" width="10"
                class="shadow-4 border-circle border-2 border-primary flex-shrink-0 mr-2 enlarge-on-hover" [style.width.rem]="3.5" [style.height.rem]="3.5" /> -->
                <img  src="assets/layout/images/male.png" height="10" width="10"
                class="shadow-4 border-circle border-2 border-primary flex-shrink-0 mr-2 enlarge-on-hover" [style.width.rem]="3.5" [style.height.rem]="3.5" />
        </ng-template>

            <span class="profile-details">
                <span class="profile-name">{{userdata.firstName}}</span>
                <span class="profile-job">{{this.userdata.refTable != 'Staff' ? this.studentRelation : this.userRole}}
                    <span class="profile-job" *ngIf="this.userdata.refTable == 'Guardian' && this.activeStudent">({{this.activeStudent.fullName}})</span>
                </span>
            </span>
            <i class="pi pi-angle-down"></i>
        </button>
        <ul class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-20rem sm:w-18rem mt-2 right-0 top-auto">
            <span *ngIf="isAdditionalAccount || withoutActiveStudent.length" class="text-base font-medium">Switch to</span>
            <!-- <span *ngIf="this.userdata.refTable == 'Guardian'" class="text-base font-medium">Switch to</span> -->
            <li>
                <div *ngIf="isAdditionalAccount">
                    <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer mb-1"
                    (click)="onAccountChange(refTable)">
                    <i class="pi pi-user mr-3"></i>
                    <span>{{refTable}}</span>
                   </a>
                   <div class="border-top-2 border-blue-600"></div>
                </div>

                <div *ngIf="withoutActiveStudent.length" style="max-height: 50vh; overflow-y: auto;">
                    <div *ngFor="let student of withoutActiveStudent">
                        <div>
                            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer mb-1"
                            (click)="studentProfileChange(student)">
                                <!-- <img [src]="student.profileimg" class="pi pi-user mr-3" style="width: 30px; height: 30px; border-radius: 50px;"> -->
                                <div class="flex mt-1 block">
                                    <img
                                        *ngIf="student.profileimg != undefined; else noImageFound"
                                        [src]="student.profileimg"
                                        width="43"
                                        height="43"
                                        alt="profile"
                                        class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                                    />
                                    <ng-template #noImageFound>
                                        <a>
                                            <img
                                                src="assets/layout/images/male.png"
                                                alt="Fallbackimage"
                                                width="43"
                                                height="43"
                                                class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                                            />
                                        </a>
                                    </ng-template>
                                    <div class="ml-2 mt-1">
                                        <div class="text-base font-bold text-900 mb-0 flex flex-column">
                                            <span class="text-primary">{{student.fullName}}</span>
                                            <span class="text-primary cursor-none">{{student.roomName ? student.roomName : 'No Room'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <span>{{student.fullName}}</span> -->
                            </a>
                        </div>
                    <!-- <div class="border-top-2 border-blue-600"></div> -->
                    </div>
                </div>

                <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer mt-1"
                (click)="onConfigButtonClick()" *ngIf="userRole && userRole === 'Admin' || userRole === 'Director'">
                    <i class="pi pi-cog mr-3"></i>
                    <span>Theme Settings</span>
                </a>
                <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer" (click)="Logout()">
                    <i class="pi pi-power-off mr-3"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
    </div>
</div>
