<p-toast></p-toast>
<ng-container *ngIf="activeIndex === 0" style="margin-bottom: 5px">
    <div class="md:mt-2">
        <div class="text-lg line-height-3 mb-1" style="font-weight: bold">
            <h5>STEP {{ activeIndex + 1 }}/{{isOneStudentInFamily ? 2 : 3}}</h5>
            <!-- <ng-template #firstStepBlock>
                <h5>STEP {{ activeIndex + 1 }}/2</h5>
            </ng-template> -->
        </div>

       

        <div class="container">
            <div class="row p-0">
                <div class="flex mt-1 block px-3" *ngFor="let studentDetails of this.selectedStudentList">
                    <img
                        *ngIf="studentDetails.profileimg != undefined; else noImageFound"
                        [src]="studentDetails.profileimg"
                        width="43"
                        height="43"
                        alt="profile"
                        class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                    />
                    <ng-template #noImageFound>
                        <a>
                            <img
                                src="assets/layout/images/male.png"
                                alt="Fallbackimage"
                                width="43"
                                height="43"
                                class="border-circle border-2 border-primary flex-shrink-0 shadow-4"
                            />
                        </a>
                    </ng-template>
                    <div class="ml-2 mt-3">
                        <div class="text-base font-bold text-900 mb-0">
                            <a
                                class="student-name"
                            >{{ studentDetails.fullName }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-12">
            <form [formGroup]="invoiceForm">
                <div class="row">
                    
                        <div class="col-4" *ngIf="this.invoiceData.data.isMultiFamily">
                            <label [appAsterisk]="true">Select Family</label>
                            <p-dropdown 
                            [options]="multiFamilyDataList" 
                            formControlName="selectedFamily"
                            optionLabel="name"
                            optionValue="id"
                            (onChange)="onFamilyChange($event)"
                            placeholder="Select a Family">
                          </p-dropdown>
                          </div>
                        </div>
                       
                             
                
                <div class="row">
                    <div class="col-4">
                        <div class="field col-md-12 col-sm-12 pr-md-2">
                            <label htmlFor="dueDate" [appAsterisk]="true">Invoice Due Date</label>
                            <input
                                type="date"
                                class="form-control"
                                formControlName="dueDate"
                            />
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="field col-md-12 col-sm-12 pr-md-2">
                            <label for="startDate" [appAsterisk]="optionalDateControl">Date of Service (Optional)</label>
                            <div class="d-flex">
                                <input
                                    type="date"
                                    class="form-control"
                                    formControlName="startDate"/>
                                <span class="mx-4">to</span>
                                <input
                                    type="date"
                                    class="form-control"
                                    formControlName="endDate"/>
                            </div>
                        </div>
                    </div>
                </div>
                <li
                    class="flex justify-content-between border-top-2 surface-border py-3"
                ></li>
                <form [formGroup]="invoiceItemForm">
                    <div formArrayName="dynamicArray">
                        <div
                            class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                            <!-- Header Row -->
                            <div
                                class="col-12 flex p-2 surface-100 border-round-top"
                                style="max-height: 48px">
                                <div class="p-2 col-2 text-center md:text-left">
                                    <span class="text-700 block">Type</span>
                                </div>
                                <p-divider
                                    align="center"
                                    layout="vertical"
                                    styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                                <div class="p-2 col-6 text-center md:text-left">
                                    <span class="text-700 block" [appAsterisk]="true">Item Description</span>
                                </div>
                                <p-divider
                                    align="center"
                                    layout="vertical"
                                    styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                                <div class="p-2 col-1 text-center md:text-left">
                                    <span class="text-700 block" [appAsterisk]="true">Amount</span>
                                </div>
                                <p-divider
                                    align="center"
                                    layout="vertical"
                                    styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                                <div class="p-2 col text-center md:text-center">
                                    <span class="text-700 block ml-3">Action</span>
                                </div>
                            </div>

                            <!-- Dynamic Rows -->
                            <div
                                class="col-12"
                                *ngFor="
                                    let invoiceItem of dynamicArray.controls;
                                    let i = index" [formGroupName]="i">
                                <div
                                    class="p-2 my-2 flex flex-row justify-content-between align-items-center">
                                    <!-- Type Dropdown -->
                                    <div
                                        class="col-2 text-center md:text-left px-2">
                                        <select
                                            class="form-control"
                                            formControlName="type"
                                            (change)="onTypeChange($event, i)">
                                            <option value="newItem">New Item</option>
                                            <option value="presetItem">Preset Item</option>
                                            <option value="discount">Discount</option>
                                            <option value="subsidy">Subsidy</option>
                                        </select>
                                    </div>

                                    <!-- Item Description Input -->
                                    <div class="col-6 text-center md:text-left p-1">
                                        <ng-container
                                            [ngSwitch]="
                                                dynamicArray.controls[i].get('type')?.value">
                                            <input
                                                *ngSwitchCase="'newItem'"
                                                type="text"
                                                class="form-control"
                                                placeholder="Add Invoice Description"
                                                appCapitalizeFirstLetter
                                                formControlName="description"/>


                                            <div   *ngSwitchCase="'presetItem'">
                                                <p-dropdown class="dropPreset" [options]="presetItemList" formControlName="description"
                                                appendTo="body"
                                                placeholder="Add Description"  [showClear]="true"
                                                optionLabel="name"
                                                optionValue="name"
                                                (onChange)="changePresetItem(i,$event)"
                                                (onClear)="changePresetItem(i,$event)"
                                                >
                                                    <ng-template pTemplate="item" let-option>
                                                      <div>{{ option.name }}</div>
                                                    </ng-template>
                                                    <ng-template pTemplate="footer">
                                                      <div class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                                                        <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 38px">
                                                          <div class="text-center md:text-left">
                                                            <span class=" text-cyan-300 text-700 block font-semibold hover-pointer" (click)="onItemSetting('Manage Presets')"><i class="pi pi-cog"></i>&nbsp;&nbsp; Manage Presets</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </ng-template>
                                                </p-dropdown>

                                            </div>

                                            <div
                                                *ngSwitchCase="'discount'"
                                                class="form-row">
                                                <div class="col-2">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        [placeholder]="dynamicArray.controls[i].get('currencySign')?.value === '%' ? '0' : '$0'"
                                                        pKeyFilter="money"
                                                        (input)="onDeductionAmtChange(i,$event)"
                                                        formControlName="deductionAmt"/>
                                                </div>
                                                <div class="col-2">
                                                    <select class="form-control" formControlName="currencySign" (change)="onCurrencyChange(i,'discount',$event)">
                                                        <option value="%">%</option>
                                                        <option value="$">$</option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="col-3"
                                                    *ngIf="
                                                        dynamicArray.controls[i].get('currencySign')?.value === '%'">
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <p class="of-label">Of</p></div>
                                                            <div class="col-sm-8">
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="$0"
                                                                pKeyFilter="money"
                                                                formControlName="percentageAmt"
                                                                (input)="onPercentageAmtChange(i,$event)"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div [ngClass]=" dynamicArray.controls[i].get('currencySign')?.value === '%'? 'col-5' : 'col' ">
                                                        <p-dropdown class="dropPreset" [options]="discountItemList"
                                                         placeholder="Add Description" formControlName="description" appendTo="body"
                                                         [showClear]="true" optionValue="name" optionLabel="name"
                                                         >
                                                            <ng-template pTemplate="item" let-option>
                                                              <div>{{ option.name }}</div>
                                                            </ng-template>
                                                            <ng-template pTemplate="footer">
                                                              <div class="surface-card grid grid-nogutter border-round shadow-2 mb-4">
                                                                <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 38px">
                                                                  <div class="text-center md:text-left">
                                                                    <span class=" text-cyan-300 text-700 block font-semibold hover-pointer" (click)="onItemSetting('Manage Discount')"><i class="pi pi-cog"></i>&nbsp;Manage Discount</span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </ng-template>
                                                        </p-dropdown>
                                                </div>
                                            </div>

                                            <div
                                            *ngSwitchCase="'subsidy'"
                                            class="form-row">
                                            <div class="col-2">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    [placeholder]="dynamicArray.controls[i].get('currencySign')?.value === '%' ? '0' : '$0'"
                                                   pKeyFilter="money"
                                                   (input)="onDeductionAmtChange(i,$event)"
                                                    formControlName="deductionAmt"/>
                                            </div>
                                            <div class="col-2">
                                                <select class="form-control" formControlName="currencySign" (change)="onCurrencyChange(i,'discount',$event)">
                                                    <option value="%">%</option>
                                                    <option value="$">$</option>
                                                </select>
                                            </div>
                                            <div
                                                class="col-3"
                                                *ngIf="
                                                    dynamicArray.controls[i].get('currencySign')?.value === '%'">
                                                <div class="row">
                                                    <div class="col-sm-1">
                                                        <p class="of-label">Of</p></div>
                                                        <div class="col-sm-8">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="$0"
                                                           pKeyFilter="money"
                                                            formControlName="percentageAmt" (input)="onPercentageAmtChange(i,$event)"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div [ngClass]=" dynamicArray.controls[i].get('currencySign')?.value === '%'? 'col-5' : 'col' ">
                                                <p-dropdown class="dropPreset" [options]="subsidyItemList"
                                                placeholder="Add Description" formControlName="description" appendTo="body"
                                                optionLabel="name" optionValue="name" >
                                                   <ng-template pTemplate="item" let-option>
                                                     <div>{{ option.name }}</div>
                                                   </ng-template>
                                               </p-dropdown>
                                            </div>
                                        </div>

                                        </ng-container>
                                    </div>

                                    <!-- Amount Input -->
                                    <div
                                        class="col-2 text-center md:text-left pr-2"
                                    >
                                        <ng-container
                                            [ngSwitch]="dynamicArray.controls[i].get('type')?.value">
                                            <input
                                                *ngSwitchCase="'newItem'"
                                                placeholder="$0"
                                                class="form-control amt-input"
                                               pKeyFilter="money"
                                                formControlName="amount"/>
                                            <input
                                                *ngSwitchCase="'presetItem'"
                                                placeholder="$0"
                                                class="form-control amt-input"
                                               pKeyFilter="money"
                                                formControlName="amount"/>
                                            <span *ngSwitchCase="'discount'" class="font-medium text-red-600">$ {{(dynamicArray.controls[i].get("amount")?.value != undefined && dynamicArray.controls[i].get("amount")?.value != 0) ? '-' : ''}}
                                                {{dynamicArray.controls[i].get("amount")?.value ?? 0}}</span>
                                            <span *ngSwitchCase="'subsidy'" class="font-medium text-red-600">$ {{(dynamicArray.controls[i].get("amount")?.value != undefined && dynamicArray.controls[i].get("amount")?.value != 0)? '-' : ''}}
                                                {{dynamicArray.controls[i].get("amount")?.value ?? 0}}</span>
                                        </ng-container>
                                    </div>

                                    <div  class="col-1 text-center md:text-left pr-2">
                                        <p-button
                                            icon="pi pi-trash"
                                            styleClass="p-button-rounded p-button-danger p-button-text"
                                            [disabled]="i === 0"
                                            (onClick)="deleteItem($event,invoiceItem, i)"></p-button>
                                    </div>
                                    <p-confirmPopup key="confirm1"></p-confirmPopup>
                                </div>

                                <li
                                    class="flex justify-content-between border-top-1 surface-border py-1"
                                ></li>
                            </div>

                            <div class="col-12 flex">
                                <div class="col-4">
                                    <span
                                        class="text-2xl font-semibold text-cyan-300"
                                        style="cursor: pointer"
                                        [ngClass]="{ 'disabled': !invoiceItemForm.valid }"
                                        (click)="addMoreFormGroup()">
                                        Add Invoice Item
                                    </span>
                                    <br />
                                    <p class="text-sm">
                                        Preset Charges, Discounts & Subsidy
                                    </p>
                                </div>
                                <div class="col-5"></div>

                                <div class="col-3 text-2xl font-semibold">
                                    <span>Total : $ {{getTotalAmount()}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                    <span class="p-float-label mt-6">
                        <textarea
                            formControlName="note"
                            id="note"
                            [rows]="1"
                            class="full-width-textarea"
                            pInputTextarea></textarea>
                        <label for="float-input">Add optional internal note</label>
                    </span>


            </form>
        </div>
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-end">
                    <p-button
                    label="Cancel"
                    (onClick)="cancelInv()"
                    styleClass="p-button-secondary p-button-raised"
                    ></p-button>
                <div class="mx-2"></div>
                    <p-button
                        label="Continue"
                        [disabled]="!invoiceForm.valid || !invoiceItemForm.valid"
                        (onClick)="onComplete()"
                        class="p-button-secondary"
                    ></p-button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="invoiceData.data.studentList.length != 1; else multiFamilyStudentsSelection">
    <ng-container *ngIf="activeIndex === 1" style="margin-bottom: 20px">
        <div class="md:mt-2">
            <div class="text-lg line-height-3 mb-3" style="font-weight: bold">
                <h5>STEP {{ activeIndex + 1 }}/3</h5>
            </div>
            <div class="my-2">
                <p-dataView
                [value]="studentList"
                selectionMode="multiple"
                [paginator]="true"
                [rows]="9"
                filterBy="title"
                [sortField]="sortField"
                [sortOrder]="-1"
                layout="grid"
                [first]="dvFirst"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[9, 18, 27]"
                (onPage)="onPage($event)"
            >
                <ng-template pTemplate="header">
                    <div class="p-toolbar-group-start flex-wrap align-items-center">
                        <input
                            type="text"
                            pInputText
                            id="studentSearch"
                            class="custom-dropdown-one mb-2 mr-2"
                            placeholder="Search Students"
                            (input)="filter()"
                            [(ngModel)]="studentSearchInput"
                            appCapitalizeFirstLetter
                            appNoLeadingSpace
                            #studentSearch
                        />

                        <p-dropdown
                            class="custom-dropdown-one mr-2 mb-2 py-0"
                            id="studentSearchByRoom"
                            optionLabel="name"
                            [(ngModel)]="studentSelectedRoom"
                            filterBy="name"
                            [options]="roomList"
                            optionValue="id"
                            (onFilter)="filterRoom($event)"
                            [filter]="true"
                            [placeholder]="'Select Classroom'"
                            (ngModelChange)="filterStudents()"
                            #roomNameDropdown
                            >
                        </p-dropdown>

                        <p-multiSelect
                            appendTo="body"
                            id="studentSearchByTag"
                            class="custom-dropdown-one mr-2 mb-2"
                            optionLabel="name"
                            [(ngModel)]="selectedStudentTags"
                            filterBy="name"
                            [options]="tagList"
                            optionValue="id"
                            [filter]="true"
                            [placeholder]="'Select Tags'"
                            (ngModelChange)="filterStudents()"
                            #tagNameDropdown
                        >
                        </p-multiSelect>

                        <div class="justify-content-end">
                            <div class="ml-3 mb-1">
                                <div class="input-with-icon">
                                    <!-- <span class="resetIcon mr-4" (click)="onSearch(true)">
                                        <i class="pi pi-replay pointer" title="Reset"></i>
                                    </span> -->
                                    <button type="button" pButton pRipple (click)="onSearch(true)" label="Clear"
                                    class="p-button-secondary p-button-sm ml-2 mt-1"></button>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="d-flex justify-content-between">
                        <div class="field-checkbox mb-0">
                            <label for="ny" style="font-weight: bold" class="mr-2">Show Multifamily</label>
                            <p-inputSwitch [(ngModel)]="multifamilyChecked" (onChange)="onClickMultifmailyToggle($event)"></p-inputSwitch>
                        </div>                        
                        <div class="align-items-center">
                            <p-checkbox
                                id="selectAll"
                                inputId="selectAll"
                                [binary]="true"
                                [(ngModel)]="selectAllStudents"
                                (onChange)="toggleAllSelection();checkboxChanged()"
                            ></p-checkbox>
                            <label for="selectAll" class="mt-3 ml-2">Select All</label>
                        </div>
                        <div class="mt-2 ml-2">
                            <span *ngIf="selectedStudentIds && selectedStudentIds?.length !== 0">{{ selectedStudentIds.length !== undefined ? selectedStudentIds.length : 0 }} {{selectedStudentIds.length > 1 ? 'Students' : 'Student'}} Selected</span>
                        </div>
                    </div>

                </ng-template>
                <ng-template let-student pTemplate="gridItem">
                    <div class="col-12 md:col-4 dataView">
                        <div class="p-3">
                            <div class="grid formgrid">
                                <div class="col-12 ">
                                    <div class="field-checkbox gap-1">
                                        <p-checkbox
                                            id="studentCheckbox"
                                            inputId="studentCheckbox"
                                            class="custom-margin"
                                            [binary]="true"
                                            [(ngModel)]="student.selected"
                                            (ngModelChange)="toggleSelection(student);"
                                        ></p-checkbox>
                                        &nbsp;
                                        <div
                                            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 gap-3"
                                        >
                                            <div
                                                for="studentCheckbox" class="flex align-items-center"
                                            >
                                                <img
                                                    *ngIf="
                                                        student.profileimg !=
                                                            undefined;
                                                        else noImageFound
                                                    "
                                                    [src]="student.profileimg"
                                                    width="50"
                                                    height="50"
                                                    alt="profile"
                                                    class="border-circle border-2 border-primary flex-shrink-0"
                                                />
                                                <ng-template #noImageFound>
                                                    <a>
                                                        <img
                                                            src="assets/layout/images/male.png"
                                                            alt="Fallbackimage"
                                                            width="50"
                                                            height="50"
                                                            class="border-circle border-2 border-primary flex-shrink-0"
                                                        />
                                                    </a>
                                                </ng-template>
                                                <div class="ml-3">
                                                    <span
                                                        class="text-m font-light"
                                                    >
                                                        <!-- <span class="font-normal"><b>{{student!.firstName}} {{student!.lastName}}</b></span> -->
                                                        <span
                                                            class="font-normal"
                                                            ><b
                                                                >{{
                                                                    student!
                                                                        .fullName
                                                                }}
                                                            </b></span
                                                        >
                                                    </span>
                                                    <br>
                                                    <span
                                                    class="font-normal text-left"><b>{{student?.family?.name}}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="empty">
                    <tr>
                        <td style="text-align: -webkit-center; font-weight: bold">
                                No Records
                        </td>
                    </tr>
                </ng-template>
            </p-dataView>

            </div>
            <div class="row">
                <div class="col">
                    <div class="d-flex justify-content-start">
                        <p-button
                            label="Back"
                            (onClick)="onBack()"
                            styleClass="p-button-secondary p-button-raised"
                        ></p-button>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-end">
                        <p-button
                            label="Continue"
                            [disabled]="selectedStudentIds.length == 0"
                            (onClick)="onComplete()"
                            class="p-button-secondary"
                        ></p-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #multiFamilyStudentsSelection>
    <ng-container *ngIf="activeIndex === 1 && !isOneStudentInFamily" style="margin-bottom: 20px">
        <div class="md:mt-2">
            <div class="text-lg line-height-3 mb-3" style="font-weight: bold">
                <h5>STEP {{ activeIndex + 1 }}/{{isOneStudentInFamily ? 2 : 3}}</h5>
            </div>
            <div class="my-2">
                <p-dataView
                [value]="studentList"
                selectionMode="multiple"
                filterBy="title"
                layout="grid"
            >
                <div class="mt-2 ml-2">
                    <span *ngIf="selectedStudentIds && selectedStudentIds?.length !== 0">{{ selectedStudentIds.length !== undefined ? selectedStudentIds.length : 0 }} {{selectedStudentIds.length > 1 ? 'Students' : 'Student'}} Selected</span>
                </div>
                <ng-template let-student pTemplate="gridItem">
                    <div class="col-12 md:col-4 dataView">
                        <div class="p-3">
                            <div class="grid formgrid">
                                <div class="col-12 md:col-4">
                                    <div class="field-checkbox gap-1">
                                        <p-checkbox
                                            id="studentCheckbox"
                                            inputId="studentCheckbox"
                                            class="custom-margin"
                                            [binary]="true"
                                            [(ngModel)]="student.selected"
                                            (ngModelChange)="toggleSelection(student);"
                                        ></p-checkbox>
                                        &nbsp;
                                        <div
                                            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 gap-3"
                                        >
                                            <div
                                                for="studentCheckbox" class="flex align-items-center"
                                            >
                                                <img
                                                    *ngIf="
                                                        student.profileimg !=
                                                            undefined;
                                                        else noImageFound
                                                    "
                                                    [src]="student.profileimg"
                                                    width="50"
                                                    height="50"
                                                    alt="profile"
                                                    class="border-circle border-2 border-primary flex-shrink-0"
                                                />
                                                <ng-template #noImageFound>
                                                    <a>
                                                        <img
                                                            src="assets/layout/images/male.png"
                                                            alt="Fallbackimage"
                                                            width="50"
                                                            height="50"
                                                            class="border-circle border-2 border-primary flex-shrink-0"
                                                        />
                                                    </a>
                                                </ng-template>
                                                <div class="ml-3">
                                                    <span
                                                        class="text-m font-light"
                                                    >
                                                        <!-- <span class="font-normal"><b>{{student!.firstName}} {{student!.lastName}}</b></span> -->
                                                        <span
                                                            class="font-normal"
                                                            ><b
                                                                >{{
                                                                    student!
                                                                        .fullName
                                                                }}
                                                            </b></span
                                                        >
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="empty">
                    <tr>
                        <td style="text-align: -webkit-center; font-weight: bold">
                                No Records
                        </td>
                    </tr>
                </ng-template>
            </p-dataView>

            </div>
            <div class="row">
                <div class="col">
                    <div class="d-flex justify-content-start">
                        <p-button
                            label="Back"
                            (onClick)="onBack()"
                            styleClass="p-button-secondary p-button-raised"
                        ></p-button>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-end">
                        <p-button
                            label="Continue"
                            [disabled]="selectedStudentIds.length == 0"
                            (onClick)="onComplete()"
                            class="p-button-secondary"
                        ></p-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="activeIndex === (isOneStudentInFamily ? 1 : 2)" style="margin-bottom: 20px">
    <div class="md:mt-2">
        <div class="text-lg line-height-3" style="font-weight: bold">
            <h5 class="mb-2" *ngIf="invoiceData.data.studentList.length != 1;else firstStepBlock">STEP {{ activeIndex + 1 }}/3</h5>
            <ng-template #firstStepBlock>
                <h5 class="mb-2" >STEP {{ activeIndex + 1 }}/{{isOneStudentInFamily ? 2 : 3}}</h5>
            </ng-template>
        </div>
        <span class="ml-4 text-lg font-normal text-gray-500">Invoice Preview</span>

        <div class="container-fluid">
            <div class="row">

              <!-- First Section -->
              <div class="col-md-8">

                <div class="col-custom px-6 py-5" style="flex-basis: 65%;">
                  <div class="row ml-0 mb-1">
                    <img alt="app logo" src="assets/layout/images/wise_owl_logoo.png" class="shadow-4 border-circle flex-shrink-0" style="width: 12% !important;">
                    <div class="mx-3"></div>
                    <span class="app-name mt-2 text-lg font-semibold">Wise Owl Academy<br><span class="font-normal text-base">SMART CHILDREN | BRIGHT FUTURE</span></span>
                  </div>
                  <div class="row mt-2">
                    <!-- Header Section -->
                    <div class="col-12 flex p-2 surface-100 border-round-top" style="max-height: 27px">
                      <div class="pl-2 text-700">
                        Due Date:
                        <span class="text-lg font-medium">
                          {{ invoiceForm.get('dueDate')?.value | momentDate }}
                        </span>
                      </div>
                    </div>
                    <div class="col-12 flex p-2 surface-100 " style="max-height: 27px">
                        <div class="pl-2 text-700" *ngIf=" invoiceForm.get('startDate')?.value != null">
                          Invoice Period :
                          <span class="text-lg font-medium">
                            {{ invoiceForm.get('startDate')?.value | momentDate }} to
                            {{ invoiceForm.get('endDate')?.value  | momentDate }}
                          </span>
                        </div>
                    </div>
                    <div class="col-12 flex p-2 pt-1 surface-100" style="max-height: 48px">
                      <div class="p-2 col-8 text-center md:text-left">
                        <span class="text-700 block">Description</span>
                      </div>
                      <p-divider align="center" layout="vertical" styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                      <div class="p-2 pr-6 col-4 text-center md:text-right">
                        <span class="text-700 block">Amount</span>
                      </div>
                    </div>
                    <div class="col-12 flex justify-content-between border-bottom-1 surface-border py-0 px-2"></div>
                    <!-- Data Rows -->
                    <div *ngFor="let item of dynamicArray.value" class="col-12 flex p-2 surface-100" style="max-height: 48px">
                      <div class="p-2 col-8 text-center md:text-left">
                        <span class="text-lg font-medium block">{{ item.description }}</span>
                      </div>
                      <p-divider align="center" layout="vertical" styleClass="h-full mx-0 lg:mx-3 surface-border"></p-divider>
                      <div class="p-2  pr-6 col-4 text-center md:text-right">
                        <span class="text-lg block">$ {{item.type == 'discount' || item.type == 'subsidy' ? '-' : ''}}{{ item.amount }}</span>
                      </div>
                    </div>
                    <!-- Add border after each row -->
                    <div class="col-12 flex justify-content-between border-bottom-1 surface-border py-0 px-2"></div>
                    <div class="col-12 flex p-2 surface-100 border-round-bottom" style="max-height: 48px; padding-right: 14px !important;">
                      <div class="p-2 col text-center md:text-right">
                        <span class="text-xl">Total Amount</span>
                        <span class="mx-1"></span>
                        <span class="text-xl font-medium">$ {{invoiceForm.get('invoiceAmount')?.value}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Second Section -->
              <div class="col-md-3 pl-0">
                <div class="container-fluid">
                    <div class=" col-custom pl-4 py-2 left-bar" >
                        <div class="text-center md:text-left">
                            <span class="text-lg font-medium" *ngIf="selectedStudentList && selectedStudentList?.length !== 0">{{ selectedStudentList.length !== undefined ? selectedStudentList.length : 0 }} {{selectedStudentList.length > 1 ? 'Students' : 'Student'}} Selected</span>
                        </div>
                        <div class="row flex w-100 justify-content-between p-2 border-round" *ngFor="let student of selectedStudentList">
                          <div  for="studentCheckbox" class="flex align-items-center">
                          <img
                              *ngIf="
                                  student.profileimg !=
                                      undefined;
                                  else noImageFound
                              "
                              [src]="student.profileimg"
                              width="40"
                              height="40"
                              alt="profile"
                              class="border-circle border-2 border-primary flex-shrink-0"
                          />
                          <ng-template #noImageFound>
                              <a>
                                  <img
                                      src="assets/layout/images/male.png"
                                      alt="Fallbackimage"
                                      width="40"
                                      height="40"
                                      class="border-circle border-2 border-primary flex-shrink-0"
                                  />
                              </a>
                          </ng-template>
                          <div class="ml-2">
                            <span class="text-m font-light">
                                <span class="font-normal"><b>{{student!.fullName}}</b></span>
                            </span>
                            <div class="p-1">
                                <span>{{getRoomName(student!.roomId)}}</span>
                            </div>
                        </div>
                          </div>
                          <div class="d-flex ms-auto" *ngIf="!isOneStudentInFamily">
                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text"
                            (onClick)="onRemoveInvoiveStud($event,student)"></p-button>
                          </div>
                          <div class="col-12 flex justify-content-between border-bottom-1 surface-border pb-0 pt-2 px-2"></div>
                        </div>
                        <p-confirmPopup key="confirm2"></p-confirmPopup>
                      </div>
                </div>
              </div>
            </div>
        </div>



        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-start">
                    <p-button
                        label="Back"
                        (onClick)="onBack()"
                        styleClass="p-button-secondary p-button-raised"
                    ></p-button>
                </div>
            </div>
            <div class="col">
                <div class="d-flex justify-content-end">
                    <button pButton
                        [disabled]="selectedStudentList.length == 0 || !submitButton"
                        label="Save"
                        (click)="onSubmit('save')"
                        [loading]="!submitButton && buttonType == 'save'"
                        class="p-button-success p-button-raised"
                    ></button>
                    <div class="mx-2"></div>
                    <button pButton
                        [disabled]="selectedStudentList.length == 0 || !submitButton"
                        label="Save and Send"
                        (click)="onSubmit('send')"
                        [loading]="!submitButton && buttonType == 'send'"
                        class="p-button-success p-button-raised"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
