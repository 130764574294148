import { CommonModule, DatePipe } from '@angular/common';
import { FormFieldValidationComponent } from './components/form-field-validation/form-field-validation.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import {SpeedDialModule} from 'primeng/speeddial';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { AsteriskDirective } from './directives/asterisk.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ColorPickerModule } from 'primeng/colorpicker';
import { EditorModule } from 'primeng/editor';
import { DataViewModule } from 'primeng/dataview';
import { StepsModule } from 'primeng/steps';
import { DragDropModule } from 'primeng/dragdrop';
import { CapitalizeFirstLetterDirective } from './directives/capitalize-first-letter.directive';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChatService } from '../components/apps/chat/service/chat.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ToUpperCaseDirective } from './directives/to-upper-case.directive';
import { AmountsInWords } from './pipes/amount-in-word.pipe';
import { TimeFormatPipe } from './pipes/timeFormat.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { StudentListComponent } from './components/student-list/student-list.component';
import { MilestoneListComponent } from './components/milestone-list/milestone-list.component';
import { TreeModule } from 'primeng/tree';
import { DragDropNewsLetterFormComponent } from './components/drag-drop-news-letter-form/drag-drop-news-letter-form.component';
import { FormatTimePipe } from './pipes/formatTime.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NumberParenthesesHyphenOnlyDirective } from './directives/phone-number.directive';
import { LowercaseLetterDirective } from './directives/lowercase-letter.directive';
import { ChartModule } from 'primeng/chart';
import { AuthorityHideDirective } from './directives/authotiryhide.directive';
import { AuthorityShowDirective } from './directives/authorityshow.directive';
import { ProgressComponent } from './components/progress/progress/progress.component';
import { NewTimeFormat } from './pipes/newTimeFormat.pipe'
import { CardModule } from 'primeng/card';
import { FirstLetterCapitalDirective } from './directives/first-letter-capital.directive';
import { ChangedPhoneNumberPipe } from './pipes/changed-phone-number.pipe';
import { ReceivePaymentComponent } from './components/receive-payment/receive-payment.component';
import { SendInvoiceComponent } from './components/send-invoice/send-invoice.component';
import { ViewTransactionRecipentComponent } from './components/view-transaction-recipent/view-transaction-recipent.component';
import { AddCreditComponent } from './components/add-credit/add-credit.component';
import { EditInvoiceComponent } from './components/edit-invoice/edit-invoice.component';
import { ListboxModule } from 'primeng/listbox';
import { RatioValidationDirective } from './directives/ratio-validation.directive';
import { NoLeadingSpaceDirective } from './directives/no-leading-space.directive';
import { WeekdayPipe } from './pipes/weekDayPipe.pipe';
import { MonthDayPipe } from './pipes/MonthDayPipe.pipe';
import { NumberInputDirective } from './directives/onlyNumber.directive';
import { ScrollbarToBottomDirective } from './directives/scrollbar-to-bottom.directive';
import { ScrollTopModule } from 'primeng/scrolltop';
import { InplaceModule } from 'primeng/inplace';
import { TimeFormatSchedule } from './pipes/TimeFormatSchedule.pipe';
import { DateFormatPipe } from './pipes/date.pipe';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { SortPipe } from './pipes/sortPipe';
import { TimeFormatTimerPipe } from './pipes/time-format-timer.pipe';
import { MultiFamilyTransactionDetailsComponent } from './components/multi-family-transaction-details/multi-family-transaction-details.component';




const COMPONENTS: any = [
    FormFieldValidationComponent,
    StudentListComponent,
    MilestoneListComponent,
    DragDropNewsLetterFormComponent,
    ProgressComponent,
    ReceivePaymentComponent,
    SendInvoiceComponent,
    ViewTransactionRecipentComponent,
    AddCreditComponent,
    EditInvoiceComponent,
    MultiFamilyTransactionDetailsComponent
];

const MODULES: any = [
    TableModule,
    ButtonModule,
    ToastModule,
    ToolbarModule,
    FileUploadModule,
    DialogModule,
    DropdownModule,
    RadioButtonModule,
    SpeedDialModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    KeyFilterModule,
    InputTextModule,
    TagModule,
    TabViewModule,
    TabMenuModule,
    BadgeModule,
    CalendarModule,
    SelectButtonModule,
    ToggleButtonModule,
    RippleModule,
    ScrollTopModule,
    SplitButtonModule,
    AutoCompleteModule,
    ChipsModule,
    InputMaskModule,
    CascadeSelectModule,
    MultiSelectModule,
    InputTextareaModule,
    MenuModule,
    ProgressBarModule,
    ProgressBarModule,
    DividerModule,
    PickListModule,
    AvatarModule,
    AvatarGroupModule,
    ChipModule,
    ConfirmPopupModule,
    CheckboxModule,
    PanelModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    FieldsetModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    ColorPickerModule,
    EditorModule,
    DataViewModule,
    DragDropModule,
    StepsModule,
    TreeModule,
    SkeletonModule,
    FullCalendarModule,
    ChartModule,
    CardModule,
    ListboxModule,
    InplaceModule
];
const DIRECTIVES: any[] = [
    AuthorityHideDirective,
    AuthorityShowDirective,
    AsteriskDirective,
    CapitalizeFirstLetterDirective,
    ToUpperCaseDirective,
    NumberParenthesesHyphenOnlyDirective,
    LowercaseLetterDirective,
    ToUpperCaseDirective,
    LowercaseLetterDirective,
    FirstLetterCapitalDirective,
    RatioValidationDirective,
    NoLeadingSpaceDirective,
    NumberInputDirective,
    ScrollbarToBottomDirective
];

const SERVICES: any[] = [
    MessageService,
    ChatService,
    DialogService,
    ConfirmationService,
    DatePipe
];

const PIPES = [
    AmountsInWords,
    TimeFormatPipe,
    TruncatePipe,
    FormatTimePipe,
    NewTimeFormat,
    ChangedPhoneNumberPipe,
    WeekdayPipe,
    MonthDayPipe,
    TimeFormatSchedule,
    DateFormatPipe,
    MomentDatePipe,
    SortPipe,
    TimeFormatTimerPipe

];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    imports: [CommonModule, ...MODULES],
    exports: [...COMPONENTS, ...MODULES, ...DIRECTIVES, ...PIPES],
    providers: [...SERVICES],
})
export class SharingModule {}
